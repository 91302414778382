import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../assets/api/api";
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  fetchedCategories,
  fetchingCategories,
  updateCategory,
} from "../../redux/categoriesSlice";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import SearchInput from "../../UI/SearchInput";

import { Loading } from "react-loading-ui";
import { Input, message, Popconfirm, Skeleton, Table } from "antd";
import { logout } from "../../redux/loginSlice";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal";
import { MenuOutlined } from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const Categories = () => {
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();
  const { categories, loading } = useSelector((state) => state.categories);
  const [searchStr, setSearchStr] = React.useState("");
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [newCategory, setNewCategory] = React.useState({
    name: modalType === "add" ? "" : selectedCategory.name,
  });
  const [uploading, setUploading] = React.useState(false);
  const [newDataSource, setNewDataSource] = React.useState([]);
  const [categoriesData, setCategoriesData] = React.useState(categories);
  const [per_page, setPer_page] = useState(20)

  const [sortedProducts, setSortedProducts] = React.useState([]);
  const [uploadSortedProducts, setUploadSortedProducts] = React.useState(false);
  const [staticProducts, setStaticProducts] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false)
  const [pageSize, setPageSize] = useState(10)
  // SET INPUT VALUE
  React.useEffect(() => {
    if (modalType === "add") {
      setNewCategory({
        name: "",
      });
    }

    if (modalType === "edit") {
      setNewCategory({
        name: selectedCategory.category_name,
      });
    }
  }, [modalType, selectedCategory]);

  // MODAL WINDOW CONTENT
  const content = () => {
    return (
      <div>
        <form
          onSubmit={ (e) => {
            handleSubmit();
            e.preventDefault();
          } }
        >
          <label className="form-label">
            <span className="form-label__text">Название категории</span>
            <input
              required
              className="form-control"
              type="text"
              placeholder="Название категории"
              value={ newCategory.name }
              onChange={ (e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
            />
          </label>
        </form>
      </div>
    );
  };

  // SUBMIT
  const handleSubmit = () => {
    setUploading(true);
    if (newCategory.name.length > 0) {
      if (modalType === "add") {
        API.post("/api/categories", newCategory)
          .then((res) => {
            dispatch(
              createCategory({
                category_id: res.data.payload.id,
                category_name: res.data.payload.name,
              })
            );
            setModalIsOpen(false);
            setUploading(false);
            setNewCategory({
              name: "",
            });
            message.success("Категория успешно создана");
          })
          .catch((err) => {
            console.log(err);
            setUploading(false);
          });
      }

      if (modalType === "edit") {
        API.patch(`/api/categories/${selectedCategory.category_id}`, {
          name: newCategory.name,
        })
          .then((res) => {
            dispatch(
              updateCategory({
                category_id: selectedCategory.category_id,
                category_name: newCategory.name,
              })
            );
            setModalIsOpen(false);
            setUploading(false);
            setNewCategory({
              name: "",
            });
            setSelectedCategory({});
            message.success("Категория успешно обновлена");
          })
          .catch((err) => {
            console.log(err);
            setUploading(false);
            message.error("Ошибка при обновлении категории");
          });
      }
    } else {
      message.error("Введите название категории");
      setUploading(false);
    }
  };

  // MODAL WINDOW FOOTER
  const footer = () => {
    return (
      <div className="flex gap-x-2">

        <Button
          loading={ uploading }
          onClick={ () => {
            handleSubmit();
          } }
          text={ "Cохранить" }
        />
        <Button
          onClick={ () => {
            setModalIsOpen(false);
          } }
          text={ "Отмена" }
        />
      </div>
    );
  };

  // FETCHING CATEGORIES
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    dispatch(fetchingCategories());
    dispatch(fetchCategories())
      .then((res) => {
        Loading();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(logout());
          navigate("/login", { replace: true });
        }
      });
  }, [refresh]);

  // DELETE CATEGORY
  const handleDelete = (id) => {
    dispatch(fetchCategories());
    API.delete(`/api/categories/${id}`)
      .then((res) => {
        dispatch(deleteCategory(id));
        message.success("Категория успешно удалена");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    setCategoriesData(categories);
  }, [categories]);

  // SEARCH
  React.useEffect(() => {
    if (searchStr.length > 0) {
      const newDataSource = categories.filter((category) => {
        return category.category_name
          .toLowerCase()
          .includes(searchStr.toLowerCase());
      });
      setCategoriesData(newDataSource);
    } else {
      setCategoriesData(categories);
    }
  }, [searchStr]);

  const dataSource = [];

  categoriesData?.forEach((category) => {
    dataSource.push({
      id: category.category_id,
      position: category?.category_position,
      name: category.category_name,
      actions: (
        <div className="flex gap-x-2">
          <Button
            className={ "bg-yellow-500 text-white" }
            onClick={ () => {
              setModalIsOpen(true);
              setModalType("edit");
              setSelectedCategory(category);
            } }
            mode="icon"
            text={ <ion-icon name="create-outline"></ion-icon> }
          />
          <Popconfirm
            title="Вы уверены, что хотите удалить категорию?"
            onConfirm={ () => handleDelete(category.category_id) }
            okText="Да"
            cancelText="Нет"
          >
            <Button
              className={ "bg-yellow-500 text-white" }
              mode="icon"
              text={ <ion-icon name="trash-outline"></ion-icon> }
            />
          </Popconfirm>
        </div>
      ),
    });
  });

  const columns = [
    {
      key: "id",
      title: <b>Сорт</b>,
      dataIndex: "sort",
      width: "5%",
      render: () => <DragHandle />,
    },
    {
      key: "id",
      title: <b>Все категории</b>,
      dataIndex: "name",
      width: "80%",
    },
    {
      key: "actions",
      title: <span className="text-table-gray">Действия</span>,
      dataIndex: "actions",
      width: "20%",
    },
  ];



  // SAVE SORTED PRODUCT
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={ {
        cursor: "grab",
        color: "#999",
        width: "30px",
      } }
      className="cursor-grabbing"
    />
  ));

  const saveSortedProduct = () => {
    setUploadSortedProducts(true);
    API.post("/api/categories/change/position", sortedProducts)
      .then((res) => {
        setUploadSortedProducts(false);
        setSortedProducts([]);
        message.success("Успешно сохранено!");
        setRefresh(!refresh)
      })
      .catch((res) => {
        message.error("Произошла ошибка! Попробуйте еще раз.");
      });
  };

  const SortableItem = SortableElement((props) => (
    <tr className="items-center" { ...props } />
  ));
  const SortableBody = SortableContainer((props) => (
    <tbody className="w-full " { ...props } />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log(oldIndex, newIndex)
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        categoriesData.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      let newPosition = [];
      setCategoriesData(newData);
      newData?.map((item, index) => {
        newPosition?.push({
          category_id: item?.category_id,
          position: index + 1,
        });
      });
      setSortedProducts(newPosition);
    }
  };

  console.log(sortedProducts);

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={ onSortEnd }
      { ...props }
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.position === restProps["data-row-key"]
    );
    return <SortableItem index={ index } { ...restProps } />;
  };

  return (
    <div className="w-full">
      { modalIsOpen && (
        <Modal
          onClose={ () => setModalIsOpen(false) }
          title={
            modalType === "add"
              ? "Добавить категорию"
              : "Редактировать категорию"
          }
          content={ () => content() }
          footer={ () => footer() }
        />
      ) }
      <Heading title={ "Категории" } />
      {/*  SECTION  */ }
      <div
        className={ "px-4 py-9 bg-white rounded-10 mt-6 " }
        style={ { minHeight: "80vh", } }
      >
        {/* SECTION HEADING */ }
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between"
          }
        >
          <div className="flex gap-x-4">


            <label className="flex flex-col items-start justify-start">
              <span className="text-xs font-medium text-txt-color mb-2">Поиск по категориям</span>
              <SearchInput
                placeholder={ "Поиск по категориям" }
                width={ "w-326 sm:w-full" }
                search={ search }
                setSearch={ setSearch }
                debounceTimeOut={ 800 }
                prefix={ <ion-icon name="search-outline"></ion-icon> }
                onChange={ setSearchStr }
              />
            </label>
            <label className="flex flex-col items-start justify-start">
              <span className="text-xs font-medium text-txt-color mb-2">Элементы на странице</span>
              <input
                value={ pageSize }
                placeholder="Элементы на странице"
                onChange={ e => setPageSize(e.target.value) }
                className=" bg-background-color outline-none border-none text-sm placeholder:text-xs pl-2 h-8 rounded-md"
                style={ { width: "150px" } } />
            </label>
          </div>

          <div className="flex gap-x-2">
            { sortedProducts?.length ? (
              <div className={ "gap-4 fixed flex bottom-12 right-12 z-50 bg-white" }>
                <Button
                  text={ "Поддерживать порядок" }
                  width={ "w-320 sm:w-full " }
                  onClick={ saveSortedProduct }
                  loading={ uploadSortedProducts }
                />
                <Button
                  text={ "Отмена" }
                  onClick={ () => {
                    setSortedProducts([]);
                    setCategoriesData(categories);
                  } }
                ></Button>
              </div>
            ) : null }
            <Button
              text={ "Добавить категорию" }
              startedIcon={ "plus" }
              width={ "w-320 sm:w-full" }
              onClick={ () => {
                setModalIsOpen(true);
                setModalType("add");
              } }
            />
          </div>
        </div>
        {/*  SECTION BODY  */ }
        <div className={ "mt-6 w-full" }>
          <Skeleton loading={ loading } active>
            <div className="relative">
              <Table

                rowKey={ "position" }
                dataSource={ dataSource }
                columns={ columns }
                pagination={ {
                  showSizeChanger: true,
                  position: ["bottomCenter"],
                  pageSize: pageSize,
                  onChange: (page, pageSize) => setPageSize(pageSize)
                } }
                components={ {
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                  },
                } }
              />

            </div>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default Categories;
