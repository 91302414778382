import {
	Button,
	Drawer,
	Input,
	InputNumber,
	Select,
	Space,
	Switch,
	Table,
	message,
} from 'antd'
import Heading from '../../../UI/Heading'
import { useState } from 'react'
import { useEffect } from 'react'
import API, { baseURL } from '../../../assets/api/api'
import { useDebounce } from '../../../hooks/useDebounce'
import MultipleDropdown from '../../../UI/MultipleDropdown'
import { Link, Navigate, useParams } from 'react-router-dom'

const CreateCustomPrices = () => {
	const [loading, setLoading] = useState(false)
	const [products, setProducts] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [dataPriceChanged, setDataPriceChanged] = useState([])
	const [employees, setEmployees] = useState([])
	const [employeeSearch, setEmployeeSearch] = useState('')
	const debounceEmployeeSearch = useDebounce(employeeSearch, 400)
	const [loadingGetEmployees, setLoadingGetEmployees] = useState(false)

	const [assistants, setAssistants] = useState([])
	const [loadingGetAssistants, setLoadingGetAssistants] = useState(false)
	const [selectedAssistants, setSelectedAssistants] = useState([])
	const [addDataForBack, setAddDataForBack] = useState({
		name: '',
		active: true,
		employees: [],
	})
	const [creatingPrice, setCreatingPrice] = useState(false)
	const [defaultPrice, setDefaultPrice] = useState({})

	const params = useParams()

	useEffect(() => {
		if (!params.id) {
			return <Navigate to={'custom-prices'} replace={true} />
		}
	}, [params])

	console.log()

	useEffect(() => {
		setLoading(true)
		API.get(`${baseURL}/api/customPrices/${params?.id}`).then(res => {
			const data = []
			setDefaultPrice(res.data.payload.price)
			res.data.payload.products.map(item => {
				data.push({
					product_id: item.id,
					price: item.price,
					point: item.point,
				})
			})
			setProducts(res.data.payload.products)
			setDataPriceChanged(data)
			setLoading(false)

			const defAssistants = []
			const defSupervisors = []

			res.data.payload?.employees?.map(x => {
				if (x.role === 'supervisor') {
					defSupervisors.push(x.id)
				} else {
					defAssistants.push(x.id)
				}
			})

			setAddDataForBack({
				active: res.data.payload.price.active === 1 ? true : false,
				name: res.data.payload.price?.name,
				employees: defSupervisors,
			})

			setSelectedAssistants(defAssistants)
		})
	}, [params.id])

	// get employee (supervisor)
	useEffect(() => {
		setLoadingGetEmployees(true)
		API.get(`${baseURL}/api/employees/with-price?role=supervisor`)
			.then(res => {
				setEmployees(res.data.payload)
			})
			.finally(() => setLoadingGetEmployees(false))
	}, [])

	// get employee (supervisor)
	useEffect(() => {
		setLoadingGetAssistants(true)
		API.get(`${baseURL}/api/employees/with-price?role=assistant`)
			.then(res => {
				setAssistants(res.data.payload)
			})
			.finally(() => setLoadingGetAssistants(false))
	}, [])

	const changePriceAndPoint = (id, type, value) => {
		const newData = dataPriceChanged.map(item => {
			if (item.product_id === id) {
				return {
					...item,
					price: type === 'price' ? (value !== null ? value : 0) : item?.price,
					point: type === 'point' ? (value !== null ? value : 0) : item?.point,
				}
			} else {
				return item
			}
		})
		setDataPriceChanged(newData)
	}

	const resetPriceAndPoint = (id, type) => {
		const newData = dataPriceChanged.map((item, index) => {
			if (item.product_id === id) {
				return {
					...item,
					price: type === 'price' ? products[index]?.price : item?.price,
					point: type === 'point' ? products[index]?.point : item?.point,
				}
			} else {
				return item
			}
		})
		setDataPriceChanged(newData)
	}

	const dataSource = []

	products?.forEach((item, index) => {
		dataSource.push({
			id: item.id,
			name: (
				<div className='font-medium text-sm text-gray-txt-color capitalize'>
					{item?.name}
				</div>
			),
			price: (
				<Space>
					<InputNumber
						onChange={e => {
							changePriceAndPoint(item.id, 'price', e)
						}}
						value={dataPriceChanged[index]?.price}
					/>

					<Button
						type='primary'
						onClick={() => {
							resetPriceAndPoint(item?.id, 'price')
						}}
						disabled={dataPriceChanged[index]?.price == products[index]?.price}
						icon={<ion-icon name='refresh-outline'></ion-icon>}
					></Button>
				</Space>
			),
			point: (
				<Space>
					<InputNumber
						onChange={e => {
							changePriceAndPoint(item.id, 'point', e)
						}}
						value={dataPriceChanged[index]?.point}
					/>
					<Button
						type='primary'
						disabled={dataPriceChanged[index]?.point == products[index]?.point}
						onClick={() => {
							resetPriceAndPoint(item?.id, 'point')
						}}
						icon={<ion-icon name='refresh-outline'></ion-icon>}
					></Button>
				</Space>
			),
		})
	})

	const columns = [
		{
			key: 'id',
			dataIndex: 'id',
			title: '#',
		},
		{
			key: 'id',
			title: <b>Все товары</b>,
			dataIndex: 'name',
			width: '60%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Цена</span>,
			dataIndex: 'price',
			width: '20%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Балл</span>,
			dataIndex: 'point',
			width: '10%',
		},
	]

	const sendDataToCreateNew = e => {
		e.preventDefault()

		const allEmployees = []

		addDataForBack?.employees?.map(item => allEmployees.push(item))
		selectedAssistants?.map(item => allEmployees.push(item))

		const dataForBack = {
			...addDataForBack,
			products: dataPriceChanged,
			employees: allEmployees,
		}
		if (allEmployees?.length) {
			setCreatingPrice(true)
			API.patch(`${baseURL}/api/customPrices/${params?.id}`, dataForBack)
				.then(res => {
					if (res.data.code === 200) {
						message.success('Цена успешно изменено!')
						setShowModal(false)
					}
				})
				.catch(err => {
					if (err?.response?.data?.message === 'Someone has an active price') {
						message.error('Из перечисленных есть активный сотрудник!')
					}
				})
				.finally(() => {
					setCreatingPrice(false)
				})
		} else {
			message.info('Пожалуйста, выберите сотрудника')
		}
	}

	return (
		<div className='w-full'>
			<div className='flex justify-between'>
				<Heading
					title={
						'Изменять цены: ' +
						(defaultPrice?.name !== undefined
							? defaultPrice?.name
							: 'Подождите минуту...')
					}
				/>
				<Link to={'/custom-prices'}>
					<Button className='' type='primary'>
						Назад
					</Button>
				</Link>
			</div>

			{/*  SECTION  */}
			<div className={'px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto'}>
				<div className='w-full flex items-center justify-end'>
					<Button
						onClick={() => setShowModal(!showModal)}
						className='btn-primary'
						type='primary'
					>
						Изменять
					</Button>
				</div>
				<Table
					loading={loading}
					columns={columns}
					dataSource={dataSource}
					pagination={false}
				/>

				<Drawer
					onClose={() => setShowModal(false)}
					title='Изменить индивидуальную цену'
					visible={showModal}
				>
					<form onSubmit={sendDataToCreateNew}>
						<label className='flex flex-col gap-2 mb-4'>
							<span className='text-medium text-gray-500'>
								Введите название
							</span>
							<Input
								value={addDataForBack.name}
								onChange={e =>
									setAddDataForBack({ ...addDataForBack, name: e.target.value })
								}
								placeholder='Введите название'
								required
							/>
						</label>
						<label className='flex flex-col gap-2 mb-4'>
							<span className='text-medium text-gray-500'>
								Выберите доставщика
							</span>
							<Select
								placeholder={'Выберите доставщика'}
								value={addDataForBack?.employees}
								onChange={(e, o) => {
									setAddDataForBack({ ...addDataForBack, employees: e })
								}}
								mode='multiple'
							>
								{(employees || []).map(item => (
									<Select.Option
										title={
											item?.price && item?.price?.id !== defaultPrice?.id
												? `Этому сотруднику уже назначена ${item?.price?.name} цена, и он является активом`
												: item?.name
										}
										disabled={
											item?.price &&
											!addDataForBack?.employees?.find(x => x == item?.id)
										}
										key={item?.id}
										value={item?.id}
									>
										{item?.name}
									</Select.Option>
								))}
							</Select>
						</label>
						<label className='flex flex-col gap-2 mb-4'>
							<span className='text-medium text-gray-500'>
								Выберите ассистента
							</span>
							<Select
								options={(assistants || []).map(item => ({
									label: item?.name,
									value: item?.id,
									disabled:
										item?.price &&
										item?.price?.id !== defaultPrice?.id &&
										item?.price &&
										!selectedAssistants?.find(x => x == item?.id),
									title:
										item?.price && item?.price?.id !== defaultPrice?.id
											? `Этому сотруднику уже назначена ${item?.price?.name} цена, и он является активом`
											: item?.name,
								}))}
								mode='multiple'
								onChange={e => {
									setSelectedAssistants(e)
								}}
								placeholder={'Выберите ассистента'}
								value={selectedAssistants}
							/>
						</label>
						<label className='flex gap-2 mb-4'>
							<span className='text-medium text-gray-500'>Активный</span>
							<span>
								<Switch
									onChange={e =>
										setAddDataForBack({ ...addDataForBack, active: e })
									}
									checked={addDataForBack?.active}
								/>
							</span>
						</label>
						<Button
							loading={creatingPrice}
							htmlType='submit'
							className='w-full'
							type='primary'
						>
							Изменить
						</Button>
					</form>
				</Drawer>
			</div>
		</div>
	)
}
export default CreateCustomPrices
