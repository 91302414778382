import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'

const RouteRequirement = ({ allowedRoles = [] }) => {
	const role = localStorage.getItem('vivian_role')
	const location = useLocation()
	if (allowedRoles?.length === 0) {
		return <Outlet />
	} else {
		return allowedRoles?.find(item => item === role) !== -1 ? (
			<Outlet />
		) : (
			<Navigate to={'/'} state={{ from: location }} replace />
		)
	}
}

RouteRequirement.propTypes = {
	allowedRoles: PropTypes.array,
}

export default RouteRequirement
