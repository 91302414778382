import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

const initialState = {
    isLoggedIn: localStorage.getItem("token") ? true : false,
    userData: JSON.parse(localStorage.getItem("user")) || {},
    token: localStorage.getItem("token") || "",
};


const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        login: (state, action) => {
            message.success(`Привет, ${action.payload.payload.name}`);
            state.isLoggedIn = true;
            state.userData = action.payload;
            localStorage.setItem("vivian_role", action.payload.payload.role)
            localStorage.setItem("token", action.payload.payload.token);
            localStorage.setItem("user", JSON.stringify(action.payload));
            state.token = action.payload.payload.token;
            localStorage.setItem("myBranch", action.payload.payload?.branch?.id)
            window.location.reload();
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.userData = {};
            localStorage.removeItem("token");
            localStorage.removeItem("user");
        }
    }
});


export const { login, logout } = loginSlice.actions;
const loginReducer = loginSlice.reducer;
export default loginReducer;
