import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../assets/api/api";

export const fetchCategories = createAsyncThunk(
    "categories/fetchCategories",
    async () => {
        const response = await API.get("/api/categories")
        return response.data
    }
);

const categoriesSlice = createSlice({
    name: "categories",
    initialState: {
        categories: [],
        loading: false,
    },
    reducers: {
        fetchingCategories: (state, action) => {
            state.loading = true;
        },
        fetchedCategories: (state, action) => {
            state.categories = action.payload;
            state.loading = false;
        },
        fetchingCategoriesError: (state, action) => {
            state.loading = false;
        },
        deleteCategory: (state, action) => {
            state.categories = state.categories.filter(
                (category) => category.category_id !== action.payload
            );
            state.loading = false;
        },
        createCategory: (state, action) => {
            state.categories.unshift(action.payload);
            state.loading = false;
        },
        updateCategory: (state, action) => {
            const category = state.categories.find(
                (category) => category.category_id === action.payload.category_id
            );
            category.category_name = action.payload.category_name;
            state.loading = false;
        },
        filterCategories: (state, action) => {
            state.categories = state.categories.filter(
                (category) => category.category_name.includes(action.payload)
            );
        }

    },
    extraReducers: {
        [fetchCategories.pending]: (state) => {
            state.loading = true;
        },
        [fetchCategories.fulfilled]: (state, action) => {
            state.categories = action.payload.payload;
            state.loading = false;
        }
    }
});


export const { 
    fetchedCategories, 
    fetchingCategories,
    fetchingCategoriesError,
    deleteCategory,
    createCategory,
    updateCategory
} = categoriesSlice.actions;

const categoriesReducer = categoriesSlice.reducer

export default categoriesReducer;