import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../assets/api/api";

export const fetchProducts = createAsyncThunk(
    "products/fetchProducts",
    async () => {
        const response = await API.get("/api/products")
        return response.data
    }
);

const productsSlice = createSlice({
    name: "products",
    initialState: {
        products: [],
        staticProducts: [],
        loading: false,
        combo_id: [],
        comboPageType: 'view',
        selectedData: {},
        oneWorked: false,
        staticSelectedData: {},
        allCombo_id: [],
        staticNoChangeProducts: []

    },
    reducers: {
        fetchingProducts: (state, action) => {
            state.loading = true;
        },
        fetchedProducts: (state, action) => {
            state.products = action.payload;
            state.loading = false;
        },
        fetchingProductsError: (state, action) => {
            state.loading = false;
        },
        newProduct: (state, action) => {
            state.products = state.products.unshift(action.payload);
            state.loading = false;
        },
        updateProduct: (state, action) => {
            const product = state.products.find(
                (product) => product.id === action.payload.id
            )
            const newArray = [...state.products.slice(0, state.products.indexOf(product)), action.payload, ...state.products.slice(state.products.indexOf(product) + 1)];
            state.products = newArray;
        },
        filterForCombo: (state, action) => {
            state.products = state.products.filter(x => x.id !== action.payload.id)
            state.staticProducts = state.products.filter(x => x.id !== action.payload.id)
        },
        deleteCombo: (state, action) => {
            state.products.unshift(action.payload)
            state.staticProducts.unshift(action.payload)
            state.combo_id = state.combo_id.filter(x => x !== action.payload.id)
            state.selectedData.products = state?.selectedData?.products?.filter(x => x.id !== action.payload.id)

        },
        addCombo: (state, action) => {
            state.combo_id.push(action.payload)
        },
        clearCombo: (state, action) => {
            state.combo_id = []
        },
        setComboPageType: (state, action) => {
            state.comboPageType = action.payload
        },
        setSelectedData: (state, action) => {
            state.selectedData = action.payload
            state.staticSelectedData = action.payload
        },
        setWorked: (state, action) => {
            state.oneWorked = true
        },
        breakComboItems: (state, action) => {
            state.allCombo_id.push({ id: action.payload.id, product_ids: state.combo_id, limit: action.payload.limit })
            state.combo_id = []
        },
        deleteBreak: (state, action) => {
            state.allCombo_id = action.payload.newItems
            action.payload.oldItems?.map((item) => {
                item?.product_ids?.map(id => {
                    state.products.unshift(state.staticNoChangeProducts.find(product => product?.id === id))
                    state.staticProducts.unshift(state.staticNoChangeProducts.find(product => product?.id === id))
                })
            })
        },
        clearAllData: (state, action) => {
            state.allCombo_id = []
            state.combo_id = []
        },
        changeBreakLimit: (state, action) => {
            const index = state.allCombo_id.findIndex(item => item.id === action.payload.id)
            state.allCombo_id[index].limit = action.payload.limit
        }
    },
    extraReducers: {
        [fetchProducts.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchProducts.fulfilled]: (state, action) => {
            state.products = action.payload.payload;
            state.staticProducts = action.payload.payload;
            state.staticNoChangeProducts = action.payload.payload;
            state.loading = false;
        }
    }
});


export const {
    fetchedProducts,
    fetchingProducts,
    fetchingProductsError,
    newProduct,
    updateProduct,
    filterForCombo,
    deleteCombo,
    addCombo,
    clearCombo,
    setComboPageType,
    setSelectedData,
    setWorked,
    breakComboItems,
    deleteBreak,
    clearAllData,
    changeBreakLimit
} = productsSlice.actions;

const productsReducer = productsSlice.reducer;

export default productsReducer; 