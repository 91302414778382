import { Button, Form, Input, message } from 'antd'
import React from 'react'
import Heading from '../../../UI/Heading'
import API from '../../../assets/api/api'

function Profile(props) {
	const userData = JSON.parse(localStorage.getItem('user'))?.payload
	const [form] = Form.useForm()

	function submit(e) {
		const { password } = e
		const { role, ...userDatas } = userData

		API.patch(`/api/employees/${userData.id}`, {
			password,
			role,
			branch_id: userData.branch.id,
			...userDatas,
		})
			.then(res => {
				message.success('Пароль успешно сохранен!')
				form.resetFields()
			})
			.catch(() => {
				message.error('Произошла ошибка. Пожалуйста, попробуйте еще раз!')
			})
	}

	return (
		<div className='w-full'>
			<Heading title={'Настройки профиля'} />
			{/*  SECTION  */}
			<div
				className={'px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto'}
				style={{ minHeight: '80vh', maxHeight: '100vh' }}
			>
				<Form
					layout='vertical'
					className={'w-[50%]'}
					onFinish={submit}
					form={form}
				>
					<fieldset>
						<legend className='font-semibold text-2xl text-gray-600'>
							Изменить пароль
						</legend>
						<Form.Item
							name='password'
							label='Новый пароль'
							rules={[
								{
									required: true,
									message: 'Пожалуйста, введите пароль!',
								},
							]}
							hasFeedback
						>
							<Input.Password />
						</Form.Item>
						<Form.Item
							name='confirm'
							label='Подтвердите пароль'
							dependencies={['password']}
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Пожалуйста, подтвердите пароль!',
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve()
										}
										return Promise.reject(
											new Error('Оба пароля должны быть одинаковыми!')
										)
									},
								}),
							]}
						>
							<Input.Password />
						</Form.Item>

						<Button type='primary' htmlType={'submit'}>
							Сохранять
						</Button>
					</fieldset>
				</Form>
			</div>
		</div>
	)
}

export default Profile
