import React from "react";
import API from "../../assets/api/api";
import { Dropdown, Select, TreeSelect } from "antd";
import InputMask from "react-input-mask";
import { useEffect } from "react";

const { TreeNode } = TreeSelect;

const Content = ({
  data,
  setData,
  onSubmit,
  branches,
  warehouseOptions,
  isParent,
}) => {
  // BRANCHES OPTIONS
  const branchesOptions = branches?.map((branch) => {
    if (branch?.childrens) {
      return {
        value: branch?.id,
        label: branch?.name,
        childrens: branch?.childrens?.map((child) => {
          if (child?.childrens) {
            return {
              value: child?.id,
              label: child?.name,
              childrens: child?.childrens?.map((grandChild) => {
                return {
                  value: grandChild?.id,
                  label: grandChild?.name,
                };
              }),
            };
          }

          return {
            value: child?.id,
            label: child?.name,
          };
        }),
      };
    }
    return {
      value: branch?.id,
      label: branch?.name,
    };
  });

 
  // MAIN RENDER
  return (
    <div>
      <form
        onSubmit={(e) => {
          onSubmit();
          e.preventDefault();
        }}
      >
        {/* BRANCHES */}
        {!isParent ? (
          <div className="form-label">
            <span className="form-label__text">Филиал</span>
            <TreeSelect
              value={data?.parent_id}
              defaultValue={data?.parent_id}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              placeholder="Выберите Область"
              className="form-control__multiselect"
              onChange={(e) => {
                setData({ ...data, parent_id: e });
              }}
            >
              {[
                {
                  value: "all",
                  label: "Выберите Область",
                },
                localStorage.getItem('vivian_role') === "ceo" ? {
                  value: 0,
                  label: "Внешняя филиал",
                } : null,
                ...branchesOptions,
              ]?.map((branch) => {
                if (branch?.childrens) {
                  return (
                    <TreeNode
                      value={branch.value}
                      title={branch.label}
                      key={branch.value}
                    >
                      {branch.childrens.map((child) => (
                        <TreeNode
                          value={child.value}
                          title={child.label}
                          key={child.value}
                        />
                      ))}
                    </TreeNode>
                  );
                }
                return (
                  <TreeNode
                    value={branch.value}
                    title={branch.label}
                    key={branch.value}
                    disabled={branch.value === "all"}
                  />
                );
              })}
            </TreeSelect>
          </div>
        ) : null}

        {/* SUPERVISOR */}
        <label className="form-label">
          <span className="form-label__text">Имя филиал</span>
          <input
            required
            className="form-control"
            type="text"
            placeholder="Имя филиал"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </label>

        {/* Warehouse */}

        <label className="form-label">
          <span className="form-label__text">Есть ли завод?</span>
          <Select
            placeholder={"Есть ли завод?"}
            className="form-control__multiselect"
            onChange={(e) => setData({ ...data, warehouse: e })}
            value={data?.warehouse}
          >
            {warehouseOptions?.map((item, index) => (
              <Select.Option value={item.value}>{item.label}</Select.Option>
            ))}
          </Select>
        </label>
      </form>
    </div>
  );
};

export default Content;
