import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import API from '../../assets/api/api'
import Button from '../../UI/Button'
import Heading from '../../UI/Heading'
import SearchInput from '../../UI/SearchInput'
import { message, Popconfirm, Skeleton, Spin, Table, TreeSelect } from 'antd'
import { Loading } from 'react-loading-ui'
import { fetchedProducts } from '../../redux/productsSlice'
import Pagination from '../../UI/Pagination'
import { fetchCategories } from '../../redux/categoriesSlice'
import 'react-medium-image-zoom/dist/styles.css'
import Modal from '../../UI/Modal'
import moment from 'moment'
import { useEffect } from 'react'

const { TreeNode } = TreeSelect
const WarehouseHistory = () => {
	// MUST BE INITIALIZED
	const dispatch = useDispatch()
	const [search, setSearch] = React.useState('')
	const { products } = useSelector(state => state.products)
	const { categories } = useSelector(state => state.categories)
	const [filterByCategory, setFilterByCategory] = React.useState('')
	const [searchStr, setSearchStr] = React.useState('')
	const [dataSource, setDataSource] = React.useState(products)
	const [branches, setBranches] = React.useState([])
	const [selectedBranch, setSelectedBranch] = React.useState(
		localStorage.getItem('myBranch') || ''
	)
	const categoriesOptions = [
		{
			value: '',
			label: 'Все категории',
		},
	]
	const [selectedItemOrders, setSelectedItemOrders] = React.useState([])
	const [selectedItem, setSelectedItem] = React.useState({})
	const [per_page, setPerPage] = useState(0)
	const [last_page, setLastPage] = useState(0)
	const [current_page, setCurrentPage] = useState(1)
	const warehouseTypes = [
		{
			type: 'branch to branch',
			name: 'Филиал в филиал',
		},
		{
			type: 'factory to warehouse',
			name: 'Фабрика на склад',
		},
		{
			type: 'warehouse to supervisor',
			name: 'От склада до поставщика',
		},
		{
			type: 'supervisor to supervisor',
			name: 'Супервайзер к супервайзеру',
		},
		{
			type: 'gift',
			name: 'Подарок',
		},
		{
			type: 'defective',
			name: 'Бракованный',
		},
	]

	const [showOrdersModal, setShowOrdersModal] = React.useState(false)
	const [loading, setloading] = React.useState(false)
	const [selectedOrderId, setSelectedOrderId] = useState('')

	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')

	// fetching branches
	useEffect(() => {
		API.get(
			`/api/branches/${
				localStorage.getItem('vivian_role') !== 'ceo' ? 'children' : ''
			}`
		).then(res => {
			if (localStorage.getItem('vivian_role') === 'ceo') {
				setBranches(res.data.payload)
			} else {
				setBranches([res.data.payload])
			}
		})
	}, [])

	// BRANCHES OPTIONS
	const branchesOptions = branches?.map(branch => {
		if (branch?.childrens) {
			return {
				value: branch?.id,
				label: branch?.name,
				childrens: branch?.childrens?.map(child => {
					if (child?.childrens) {
						return {
							value: child?.id,
							label: child?.name,
							childrens: child?.childrens?.map(grandChild => {
								return {
									value: grandChild?.id,
									label: grandChild?.name,
								}
							}),
						}
					}

					return {
						value: child?.id,
						label: child?.name,
					}
				}),
			}
		}
		return {
			value: branch?.id,
			label: branch?.name,
		}
	})
	// FETCHING PRODUCTS
	React.useEffect(() => {
		setloading(true)
		Loading({ topBar: true, topBarColor: '#377DFF' })
		API.get(
			`/api/warehouses/histories?branch_id=${selectedBranch}&page=${current_page}&from=${from}&to=${to}`
		)
			.then(res => {
				Loading()
				setDataSource(res.data.payload.data)
				dispatch(fetchedProducts(res.data.payload.data))
				setPerPage(res.data.payload.per_page)
				setLastPage(res.data.payload.last_page)
			})
			.finally(() => setloading(false))
		dispatch(fetchCategories())
	}, [selectedBranch, current_page, from, to])

	// FETCHING CATEGORIES
	categories.map(category => {
		categoriesOptions.push({
			value: category.category_id,
			label: category.category_name,
		})
	})

	// FILTER BY CATEGORY
	React.useEffect(() => {
		if (filterByCategory !== '') {
			const filteredProducts = products.filter(
				product => product?.product?.category_id === filterByCategory
			)
			setDataSource(filteredProducts)
		} else {
			setDataSource(products)
		}
	}, [filterByCategory])

	// FILTER BY SEARCH STRING
	React.useEffect(() => {
		if (searchStr !== '') {
			const filteredProducts = products?.filter(product =>
				product?.product?.name.toLowerCase().includes(searchStr.toLowerCase())
			)
			setDataSource(filteredProducts)
		} else {
			setDataSource(products)
		}
	}, [searchStr, selectedBranch])

	// TABLE DATA RENDER
	const tableData = []

	dataSource &&
		dataSource.length &&
		dataSource?.map(product => {
			tableData.push({
				id: product?.basket_id,
				name: warehouseTypes.find(item => item.type === product?.type)?.name,
				from:
					product?.type === 'factory to warehouse'
						? 'Из Фримы'
						: product?.type === 'branch to branch'
						? product?.branch?.name
						: product?.type === 'warehouse to supervisor'
						? product?.branch?.name
						: product?.type === 'supervisor to supervisor'
						? product?.supervisor?.name
						: product?.type === 'gift'
						? product?.branch?.name
						: product?.type === 'defective'
						? product?.branch?.name
						: '',
				to:
					product?.type === 'factory to warehouse'
						? product?.branch?.name
						: product?.type === 'branch to branch'
						? product?.to_branch?.name
						: product?.type === 'warehouse to supervisor'
						? product?.supervisor?.name
						: product?.type === 'supervisor to supervisor'
						? product?.to_supervisor?.name
						: product?.type === 'gift'
						? 'На подарок'
						: product?.type === 'defective'
						? 'Бракованный'
						: '',
				menedjer:
					product?.type !== 'supervisor to supervisor'
						? product?.manager?.name
						: '',
				date:
					product?.ordered_at !== null
						? moment(product?.ordered_at).format('DD/MM/YYYY hh:mm:ss')
						: '--/--/--',
				end_date:
					product?.finished_at !== null
						? moment(product?.finished_at).format('DD/MM/YYYY hh:mm:ss')
						: '--/--/--',
			})
		})

	// TABLE HEADERS
	let columns = [
		{
			key: 'id',
			title: <b>Тип заказа</b>,
			dataIndex: 'name',
			width: '40%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Откуда</span>,
			dataIndex: 'from',
			width: '10%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Куда</span>,
			dataIndex: 'to',
			width: '10%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Менеджер</span>,
			dataIndex: 'menedjer',
			width: '20%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Время заказа</span>,
			dataIndex: 'date',
			width: '10%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Время окончания</span>,
			dataIndex: 'end_date',
			width: '10%',
		},
	]

	// MAIN RENDER
	return (
		<div className='w-full'>
			{showOrdersModal ? (
				<Modal
					title={selectedItem?.date}
					content={() => {
						return (
							<>
								<table className='table'>
									<thead>
										<tr className='bg-background-color'>
											<td className='py-2 bg-grey-100 px-2 font-medium'>
												Наименование товара
											</td>
											<td className='py-2 bg-grey-100 px-2 font-medium'>
												Количество
											</td>
										</tr>
									</thead>
									<tbody>
										{dataSource
											?.find(x => x.basket_id === selectedOrderId)
											?.orders?.map(item => (
												<tr key={item?.order_id}>
													<td className='color-gray-txt-color py-2 border-b border-light-gray text-xs font-semibold px-2'>
														{item?.product_name}
													</td>
													<td className='color-gray-txt-color py-2 border-b border-light-gray text-xs font-semibold px-2'>
														{item?.count?.toLocaleString()} шт
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</>
						)
					}}
					footer={false}
					onClose={() => setShowOrdersModal(false)}
				/>
			) : null}
			<Heading title='История склада' />
			{/*  SECTION  */}
			<div
				className={'px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto'}
				style={{ minHeight: '80vh', maxHeight: '100vh' }}
			>
				{/* SECTION HEADING */}
				<div
					className={
						'gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between'
					}
				>
					<div className='flex flex-wrap gap-4'>
						<TreeSelect
							value={selectedBranch}
							defaultValue={selectedBranch}
							dropdownStyle={{
								maxHeight: 400,
								overflow: 'auto',
							}}
							placeholder='Выберите Область'
							className='form-control__multiselect w-326'
							onChange={e => {
								setSelectedBranch(e)
							}}
						>
							{[
								{
									value: 'all',
									label: 'Выберите Область',
								},
								...branchesOptions,
							]?.map(branch => {
								if (branch?.childrens) {
									return (
										<TreeNode
											value={branch.value}
											title={branch.label}
											key={branch.value}
										>
											{branch.childrens.map(child => (
												<TreeNode
													value={child.value}
													title={child.label}
													key={child.value}
												/>
											))}
										</TreeNode>
									)
								}
								return (
									<TreeNode
										value={branch.value}
										title={branch.label}
										key={branch.value}
										disabled={branch.value === 'all'}
									/>
								)
							})}
						</TreeSelect>
						<SearchInput
							placeholder={'Поиск по названию'}
							width={'w-326 sm:w-full'}
							search={search}
							onChange={setSearchStr}
							setSearch={setSearch}
							debounceTimeOut={800}
							prefix={<ion-icon name='search-outline'></ion-icon>}
						/>
						<input
							className='bg-background-color outline-none border-none text-sm placeholder:text-xs px-2 rounded-md'
							type='date'
							value={from}
							onChange={e => setFrom(e.target.value)}
						/>
						<input
							className='bg-background-color outline-none border-none text-sm placeholder:text-xs px-2 rounded-md'
							type='date'
							value={to}
							onChange={e => setTo(e.target.value)}
						/>
					</div>
				</div>
				{/*  SECTION BODY  */}
				<div className={'mt-6 w-full overflow-x-auto'}>
					{tableData?.length !== 0 ? (
						<Skeleton loading={loading} active>
							<Table
								rowKey={'key'}
								columns={columns}
								dataSource={tableData}
								pagination={false}
								loading={loading}
								rowClassName={(record, index) => {
									return 'cursor-pointer'
								}}
								onRow={(record, rowIndex) => {
									return {
										onClick: event => {
											setSelectedOrderId(record.id)
											setSelectedItem(record)
											setShowOrdersModal(true)
										}, // click row
									}
								}}
							/>
							<br />
							<br />
							<center>
								{last_page && last_page !== 1 ? (
									<Pagination
										pageSize={per_page ? per_page : 40}
										total={last_page * per_page}
										current={current_page}
										onChange={page => setCurrentPage(page)}
									/>
								) : null}
							</center>
						</Skeleton>
					) : (
						<center>
							<h1>Даты не найдены!</h1>
						</center>
					)}
				</div>
			</div>
		</div>
	)
}

export default WarehouseHistory
