import {
	Button,
	Drawer,
	Input,
	InputNumber,
	Select,
	Space,
	Spin,
	Switch,
	Table,
	message,
} from 'antd'
import Heading from '../../../UI/Heading'
import { useState } from 'react'
import { useEffect } from 'react'
import API, { baseURL } from '../../../assets/api/api'
import { useDebounce } from '../../../hooks/useDebounce'
import MultipleDropdown from '../../../UI/MultipleDropdown'
const { Option } = Select
const CreateCustomPrices = () => {
	const [loading, setLoading] = useState(false)
	const [products, setProducts] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [dataPriceChanged, setDataPriceChanged] = useState([])
	const [employees, setEmployees] = useState([])
	const [loadingGetEmployees, setLoadingGetEmployees] = useState(false)

	const [assistants, setAssistants] = useState([])
	const [loadingGetAssistants, setLoadingGetAssistants] = useState(false)
	const [selectedAssistants, setSelectedAssistants] = useState([])
	const [addDataForBack, setAddDataForBack] = useState({
		name: '',
		active: true,
		employees: [],
	})
	const [creatingPrice, setCreatingPrice] = useState(false)
	const [staticDataPrice, setStaticDataPrice] = useState([])
	useEffect(() => {
		setLoading(true)
		API.get(`${baseURL}/api/products`).then(res => {
			let data = []

			res.data.payload?.map(item => {
					data = [...data, {
						product_id: item.id,
						price: item.price,
						point: item.point,
					}]
				})
			setProducts(res.data.payload)
			setDataPriceChanged(data)
			setStaticDataPrice(data)
			setLoading(false)
		})
	}, [])

	// get employee (supervisor)
	useEffect(() => {
		setLoadingGetEmployees(true)
		API.get(`${baseURL}/api/employees/with-price?role=supervisor`)
			.then(res => {
				setEmployees(res.data.payload)
			})
			.finally(() => setLoadingGetEmployees(false))
	}, [])

	// get employee (supervisor)
	useEffect(() => {
		setLoadingGetAssistants(true)
		API.get(`${baseURL}/api/employees/with-price?role=assistant`)
			.then(res => {
				setAssistants(res.data.payload)
			})
			.finally(() => setLoadingGetAssistants(false))
	}, [])

	const changePriceAndPoint = (id, type, value) => {
		const newData = dataPriceChanged.map(item => {
			if (item.product_id === id) {
				return {
					...item,
					price: type === 'price' ? (value !== null ? value : 0) : item?.price,
					point: type === 'point' ? (value !== null ? value : 0) : item?.point,
				}
			} else {
				return item
			}
		})
		setDataPriceChanged(newData)
	}

	console.log(dataPriceChanged)

	const resetPriceAndPoint = (id, type) => {
		const newData = dataPriceChanged.map((item, index) => {
			if (item.product_id === id) {
				return {
					...item,
					price: type === 'price' ? products[index]?.price : item?.price,
					point: type === 'point' ? products[index]?.point : item?.point,
				}
			} else {
				return item
			}
		})
		setDataPriceChanged(newData)
	}

	const dataSource = []

	products?.forEach((item, index) => {
		dataSource.push({
			id: item.id,
			name: (
				<div className='font-medium text-sm text-gray-txt-color capitalize'>
					{item?.name}
				</div>
			),
			price: (
				<Space>
					<InputNumber
						onChange={e => {
							changePriceAndPoint(item.id, 'price', e)
						}}
						value={dataPriceChanged[index]?.price}
					/>

					<Button
						type='primary'
						onClick={() => {
							resetPriceAndPoint(item?.id, 'price')
						}}
						disabled={dataPriceChanged[index]?.price == products[index]?.price}
						icon={<ion-icon name='refresh-outline'></ion-icon>}
					></Button>
				</Space>
			),
			point: (
				<Space>
					<InputNumber
						onChange={e => {
							changePriceAndPoint(item.id, 'point', e)
						}}
						value={dataPriceChanged[index]?.point}
					/>
					<Button
						type='primary'
						disabled={dataPriceChanged[index]?.point == products[index]?.point}
						onClick={() => {
							resetPriceAndPoint(item?.id, 'point')
						}}
						icon={<ion-icon name='refresh-outline'></ion-icon>}
					></Button>
				</Space>
			),
		})
	})

	const columns = [
		{
			key: 'id',
			title: <b>Все товары</b>,
			dataIndex: 'name',
			width: '60%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Цена</span>,
			dataIndex: 'price',
			width: '20%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Балл</span>,
			dataIndex: 'point',
			width: '10%',
		},
	]

	const sendDataToCreateNew = e => {
		e.preventDefault()

		const allEmployees = []

		addDataForBack?.employees?.map(item => allEmployees.push(item))
		selectedAssistants?.map(item => allEmployees.push(item))

		const dataForBack = {
			...addDataForBack,
			products: dataPriceChanged.filter((item, index) => {
				if (
					item?.price !== products[index]?.price ||
					item?.point !== products[index]?.point
				) {
					return item
				}
			}),
			employees: allEmployees,
		}

		if (allEmployees?.length) {
			setCreatingPrice(true)
			API.post(`${baseURL}/api/customPrices`, dataForBack)
				.then(res => {
					if (res.data.code === 200) {
						message.success('Цена успешно создана!')
						setSelectedAssistants([])
						setAddDataForBack({
							name: '',
							active: true,
							employees: [],
						})
						setShowModal(false)
						setDataPriceChanged(staticDataPrice)
					}
				})
				.catch(err => {
					if (err.response.status === 400) {
						message.error('Есть цены с этим названием!')
					}
				})
				.finally(() => {
					setCreatingPrice(false)
				})
		} else {
			message.info('Пожалуйста, выберите сотрудника')
		}
	}

	return (
		<div className='w-full'>
			<Heading title={'Создать новые цены'} />

			{/*  SECTION  */}
			<div className={'px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto'}>
				<div className='w-full flex items-center justify-end'>
					<Button
						onClick={() => setShowModal(!showModal)}
						className='btn-primary'
						type='primary'
						disabled={
							dataPriceChanged.filter((item, index) => {
								if (
									item?.price !== products[index]?.price ||
									item?.point !== products[index]?.point
								) {
									return item
								}
							})?.length === 0
						}
					>
						Создать
					</Button>
				</div>
				<Table
					loading={loading}
					columns={columns}
					dataSource={dataSource}
					pagination={false}
				/>

				<Drawer
					onClose={() => setShowModal(false)}
					title='Создать индивидуальную цену'
					visible={showModal}
				>
					<form onSubmit={sendDataToCreateNew}>
						<label className='flex flex-col gap-2 mb-4'>
							<span className='text-medium text-gray-500'>
								Введите название
							</span>
							<Input
								value={addDataForBack.name}
								onChange={e =>
									setAddDataForBack({ ...addDataForBack, name: e.target.value })
								}
								placeholder='Введите название'
								required
							/>
						</label>
						<label className='flex flex-col gap-2 mb-4'>
							<span className='text-medium text-gray-500'>
								Выберите доставщика
							</span>
							<Select
								placeholder={'Выберите доставщика'}
								value={addDataForBack?.employees}
								onChange={(e, o) => {
									setAddDataForBack({ ...addDataForBack, employees: e })
								}}
								mode='multiple'
							>
								{(employees || []).map(item => (
									<Option
										title={
											item?.price
												? `Этому сотруднику уже назначена ${item?.price?.name} цена, и он является активом`
												: item?.name
										}
										disabled={item?.price}
										key={item?.id}
										value={item?.id}
									>
										<div className='w-full flex flex-col justify-between'>
											<b>{item?.name}</b>

											{item?.price ? <span>{item?.price?.name}</span> : null}
										</div>
									</Option>
								))}
							</Select>
						</label>
						<label className='flex flex-col gap-2 mb-4'>
							<span className='text-medium text-gray-500'>
								Выберите ассистента
							</span>
							<Select
								options={(assistants || []).map(item => ({
									label: item?.name,
									value: item?.id,
									disabled: item?.price,
									title: item?.price
										? `Этому сотруднику уже назначена ${item?.price?.name} цена, и он является активом`
										: item?.name,
								}))}
								mode='multiple'
								onChange={e => {
									setSelectedAssistants(e)
								}}
								placeholder={'Выберите ассистента'}
								value={selectedAssistants}
							/>
						</label>
						<label className='flex gap-2 mb-4'>
							<span className='text-medium text-gray-500'>Активный</span>
							<span>
								<Switch
									onChange={e =>
										setAddDataForBack({ ...addDataForBack, active: e })
									}
									checked={addDataForBack?.active}
								/>
							</span>
						</label>
						<Button
							loading={creatingPrice}
							htmlType='submit'
							className='w-full'
							type='primary'
						>
							Создать
						</Button>
					</form>
				</Drawer>
			</div>
		</div>
	)
}
export default CreateCustomPrices
