import React, { useEffect, useState } from "react";
import Heading from "../../UI/Heading";
import TotalCard from "../../UI/TotalCard";
import Chart from "../../components/Chart/Chart";
import DailyEarning from "../../components/DailyEarning/DailyEarning";

import { statisticData, dailyItems } from "../../assets/Data/AnyDatas";
import Actives from "../../components/Actives/Actives";
import Passives from "../../components/Passives/Passives";
import API from "../../assets/api/api";

const Home = () => {
  const [moneyData, setMoneyData] = useState()
  const [refresh , setRefresh] = useState(false)
  const [loading, setloading] = useState()
  useEffect(() => {
    setloading(true)
    API.get('/api/statistics/money')
    .then((res) => {
      setMoneyData(res.data.payload)
    })
    .finally(() => setloading(false))
  }, [refresh])

  return (
    <div className={"w-full"}>
      {/*   HEADING   */}
      <Heading
        title={"Главное меню"}
        paragraph={"Привет лидер. Добро пожаловать в панель администратора!"}
      />
      {/*   STATISTIC CARDS  */}
      <TotalCard 
        moneyData={moneyData} 
        data={statisticData} 
        setRefresh={() => setRefresh(!refresh)}
        refresh={!refresh}
        loading={loading}
        />
      {/*   STATISTIC INFO GRID   */}
      <div className={"flex lg:flex-row flex-col gap-x-4 mt-4 w-full"}>
        <div className={" sm:w-full md:w-full lg:w-70%"}>
          <div className={"bg-white mb-4 rounded-10 py-4 px-5 "}>
            <Chart />
          </div>
          <div
            className={
              "col-span-2 col-start-1 col-end-3 flex gap-x-4 sm:flex-col sm:gap-y-4  row-span-1 "
            }
          >
            <div className={"sm:w-full bg-white w-full px-7 py-4  rounded-10 "}>
              <Actives />
            </div>
            <div
              className={"sm:w-full bg-white w-full px-7 py-4  rounded-10  "}
            >
              <Passives />
            </div>
          </div>
        </div>
        <div className="md:w-50 lg:mt-0 mt-4 lg:w-30% col-span-1 col-start-3 bg-white row-span-3 rounded-10 p-4">
          <DailyEarning data={dailyItems} />
        </div>
      </div>
    </div>
  );
};

export default Home;
