import axios from 'axios'
// export const baseURL = 'https://modern.vivian-legend.uz'
// export const baseURL = "https://vivian-real.salawat.me"
// export let baseURL = window.location.origin
export let baseURL = window.location.origin

if (process.env.NODE_ENV === 'production') {
	baseURL = window.location.origin
} else {
	baseURL = 'https://vivian.uz'
}

const API = axios.create({
	baseURL: baseURL,
	headers: {
		Authorization: 'Bearer ' + localStorage.getItem('token'),
		'X-app-version': 'web|1.5',
	},
})
export default API
