import React from "react";
import { useSelector } from "react-redux";
import Combo from "./Combo";
import ComboView from "./ComboView";
const Combinations = () => {
  const { comboPageType } = useSelector((state) => state.products);
  return (
    <>
      { comboPageType === "view" ? (
        <ComboView />
      ) : comboPageType === "create" ? (
        <Combo />
      ) : (
        <Combo type={ "update" } />
      ) }
    </>
  );
};

export default Combinations;
