import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../assets/api/api";
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  fetchedCategories,
  fetchingCategories,
  updateCategory,
} from "../../redux/categoriesSlice";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";

import { Loading } from "react-loading-ui";
import { DatePicker, InputNumber, message, Pagination, Popconfirm, Select, Skeleton, Table } from "antd";
import Modal from "../../UI/Modal";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import _debounce from "lodash/debounce";
import { downloadXLS } from "./downloadXLS";

const GiftRequest = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [uploading, setUploading] = React.useState(false);
  const [categoriesData, setCategoriesData] = React.useState(categories);
  const [refresh, setRefresh] = useState(false);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState("");
  const [addGift, setAddGift] = useState(false)
  const [gifts, setGifts] = useState([])
  const [selectedGift, setSelectedGift] = useState('')
  const [updatedGift, setSelectedUpdatedGift] = useState('')
  const [given, setGiven] = useState(0)
  const [last_page, setLastPage] = useState(1)
  const [per_page, setPerPage] = useState(40)
  const [currentPage, setCurrentPage] = useState(1)
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [requestStart, setRequestStart] = useState()
  const [refreshRequestStart, setRefreshRequestStart] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [downloading, setDownloading] = useState(false)
  // fetching employee and gifts
  useEffect(() => {
    API.get("/api/employees?role=assistant").then((res) => {
      setAssistants(res.data.payload);
    });

    API.get(`/api/gifts`)
      .then((res) => {
        setGifts(res?.data?.payload)
      })
  }, []);

  // fetching request disabled or enabled
  useEffect(() => {
    setRequesting(true)
    API.get("/api/gifts/receivingGift")
      .then((res) => {
        setRequestStart(res?.data?.payload?.receiving_gift)
      })
      .finally(() => setRequesting(false))
  }, [refreshRequestStart])

  // MODAL WINDOW CONTENT
  const content = () => {
    if (modalType === "gifts") {
      return (
        <>
          {
            !addGift ? (
              <table className="table w-full">
                <thead>
                  <tr className="bg-background-color">
                    <td className="py-2 bg-grey-100 px-2 font-medium">
                      Наименование товара
                    </td>
                    <td className="py-2 bg-grey-100 px-2 font-medium">Балл</td>
                    <td className="py-2 bg-grey-100 px-2 font-medium">Удалить</td>
                  </tr>
                </thead>
                <tbody>
                  { selectedCategory?.gifts?.map((item) => {
                    return Array.from(Array(item.x).keys()).map(() => (<tr key={ item?.id }>
                      <td className="color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                        { item?.gift_name }
                      </td>
                      <td className="color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                        { (item?.point / item?.x)?.toLocaleString() }
                      </td>

                      <td align="center" className="py-2 flex gap-x-2">
                        <Button
                          mode={ 'icon' }
                          text={ <ion-icon name="create-outline"></ion-icon> }
                          onClick={ () => {
                            setAddGift(true)
                            setSelectedGift(item)
                          } }
                        />
                        <Popconfirm
                          title="Вы уверены, что хотите удалить этот товар?"
                          onConfirm={ () => deleteGift(item?.id) }
                          okText="Да"
                          cancelText="Нет"
                        >
                          <Button
                            mode={ "icon" }
                            text={ <ion-icon name="trash-outline"></ion-icon> }
                          />
                        </Popconfirm>
                      </td>
                    </tr>)
                    )
                  }) }
                </tbody>
              </table>
            ) : (
              <div>
                <Button text={ "Отмена" } onClick={ () => setAddGift(false) } />
                <br />
                <table className="table w-full">
                  <thead>
                    <tr className="bg-background-color">
                      <td className="py-2 bg-grey-100 px-2 font-medium">
                        Наименование товара
                      </td>
                      <td className="py-2 bg-grey-100 px-2 font-medium">Балл</td>
                      <td className="py-2 bg-grey-100 px-2 font-medium">Добавить</td>
                    </tr>
                  </thead>
                  <tbody>
                    { gifts?.find(gift => {
                      if (gift?.point === selectedGift?.point) {
                        return gift
                      }
                    })?.gifts?.filter(g => g.id !== selectedGift?.gift_id)?.map((item) => (
                      <tr key={ item?.id }>
                        <td className="color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                          { item?.name }
                        </td>
                        <td className="color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                          { item?.point }
                        </td>

                        <td align="center">
                          {
                            updatedGift !== "" ? (
                              updatedGift !== item?.id ? (
                                <Button
                                  text={ <ion-icon name="checkbox-outline"></ion-icon> }
                                  mode="icon"
                                  onClick={ () => {
                                    setSelectedUpdatedGift(item?.id)
                                  } }
                                  disabled={ true }
                                />
                              ) : (
                                <Button
                                  text={ <ion-icon name="close-circle-outline"></ion-icon> }
                                  mode="icon"
                                  onClick={ () => {
                                    setSelectedUpdatedGift('')
                                  } }
                                  disabled={ updatedGift && updatedGift !== item?.id }
                                />
                              )) : (
                              <Button
                                text={ <ion-icon name="checkbox-outline"></ion-icon> }
                                mode="icon"
                                onClick={ () => {
                                  setSelectedUpdatedGift(item?.id)
                                } }
                              />
                            )
                          }
                        </td>
                      </tr>
                    )) }
                  </tbody>
                </table>
              </div>
            )
          }
        </>
      );
    }
  };

  const deboundeFnc = useCallback(
    _debounce((value) => {
      setPerPage(value);
    }, 500),
    []
  );

  const onChangeFunc = (e) => {
    deboundeFnc(e);
  };


  useEffect(() => {
    setSelectedUpdatedGift("")
    setAddGift(false)
  }, [modalIsOpen])

  // SUBMIT
  const handleSubmit = () => {
    if (selectedCategory?.id && selectedGift?.id && updatedGift && selectedGift?.x) {
      API.patch(`/api/gifts/request`, {
        request_id: selectedCategory?.id,
        item_id: selectedGift?.id,
        gift_id: updatedGift,
        x: selectedGift?.x
      })
        .then((res) => {
          setModalIsOpen(false)
          setRefresh(!refresh)
          message.success("Завершено успешно")
        })
        .catch((err) => {
          message.error("Произошла ошибка! Попробуйте еще раз")
          setModalIsOpen(false)
          setSelectedCategory({})
          setSelectedGift("")
          setSelectedUpdatedGift("")
        })
        .finally(() => {
          setModalIsOpen(false)
          setSelectedCategory({})
          setSelectedGift("")
          setSelectedUpdatedGift("")
        })
    } else {
      message.error("Введите данные полностью")
    }
  };

  // MODAL WINDOW FOOTER
  const footer = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          loading={ uploading }
          onClick={ () => {
            handleSubmit();
          } }
          text={ "Cохранить" }
          disabled={ updatedGift === "" }
        />
        <Button
          onClick={ () => {
            setModalIsOpen(false);
          } }
          text={ "Отмена" }
        />
      </div>
    );
  };
  // FETCHING CATEGORIES
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    setLoading(true);
    API.get(`/api/gifts/requests?employee_id=${selectedAssistant}&given=${given}&per_page=${per_page}&page=${currentPage}&from=${from}&to=${to}`)
      .then((res) => {
        setLastPage(res.data.payload.last_page)
        setPerPage(res.data.payload.per_page)
        setCategoriesData(res.data.payload.data);
        setCategories(res.data.paylaod.data);
      })
      .catch((err) => {
        console.info(err);
      })
      .finally(() => {
        setLoading(false);
        Loading();
      });
  }, [refresh, selectedAssistant, given, per_page, currentPage, from, to]);
  // DELETE GIFT REQ
  const handleDelete = (id) => {
    API.delete(`/api/gifts/request/${id}`)
      .then((res) => {
        message.success("Данные удалены!");
        setRefresh(!refresh);
      })
      .catch(() => {
        message.error("Произошла ошибка. Пожалуйста, попробуйте еще раз!");
      });
  };

  // DELETE GIFT
  const deleteGift = (item_id) => {
    API.delete(`/api/gifts/request/${selectedCategory.id}/${item_id}`)
      .then((res) => {
        message.success("Данные удалены!");
        setRefresh(!refresh);
        setModalIsOpen(false)
      })
      .catch(() => {
        message.error("Произошла ошибка. Пожалуйста, попробуйте еще раз!");
      })
  }

  // give request
  const handleGiveRequest = (id) => {
    API.post(`api/gifts/requests/given/${id}`)
      .then((res) => {
        setRefresh(!refresh)
        message.success("Завершено успешно!")
      })
      .catch((err) => {
        message.error("Произошла ошибка. Пожалуйста, попробуйте еще раз.")
      })
  }

  // change receiving gift
  const changeRecGift = () => {
    setRequesting(true)
    API.post("/api/gifts/receivingGift")
      .then((res) => setRefreshRequestStart(!refreshRequestStart))
  }


  const dataSource = [];


  categoriesData?.forEach((item) => {
    dataSource.push({
      id: item?.id,
      name: (
        <div className="flex flex-col ">
          <b>{ item?.user?.name }</b>
          <span>{ item?.user?.phone }</span>
        </div>
      ),
      employee: (
        <div className="flex flex-col ">
          <span>{ item?.employee?.name }</span>
          <span>{ item?.employee?.phone }</span>
        </div>
      ),
      assistant: (
        <div className="flex flex-col ">
          <span>{ item?.assistant?.name }</span>
          <span>{ item?.assistant?.phone }</span>
        </div>
      ),
      date: (item.created_at),
      actions: (
        <div className="flex gap-x-2">
          { item?.gifts?.length !== 0 ? (
            <Button
              className={ "bg-yellow-500 text-white" }
              onClick={ () => {
                setModalIsOpen(true);
                setModalType("gifts");
                setSelectedCategory(item);
                console.info(item.gifts);
              } }
              mode="icon"
              text={ <ion-icon name="list-outline"></ion-icon> }
            />
          ) : null }
          <Popconfirm
            title="Вы уверены, что хотите удалить?"
            onConfirm={ () => handleDelete(item.id) }
            okText="Да"
            cancelText="Нет"
          >
            <Button
              className={ "bg-yellow-500 text-white" }
              mode="icon"
              text={ <ion-icon name="trash-outline"></ion-icon> }
            />
          </Popconfirm>
          {
            given === 0 && (
              <Popconfirm
                title="Вы хотите выполнить запрос?"
                onConfirm={ () => handleGiveRequest(item?.id) }
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  className={ "bg-yellow-500 text-white" }
                  mode="icon"
                  text={ <ion-icon name="checkbox-outline"></ion-icon> }
                />
              </Popconfirm>

            )
          }
        </div>
      ),
    });
  });

  const columns = [
    {
      key: "id",
      title: <b>Клиент</b>,
      dataIndex: "name",
      width: "20%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Сотрудник</span>,
      dataIndex: "employee",
      width: "20%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Ассистент</span>,
      dataIndex: "assistant",
      width: "20%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Дата</span>,
      dataIndex: "date",
      width: "20%",
    },

    {
      key: "actions",
      title: <span className="text-table-gray">Подарки</span>,
      dataIndex: "actions",
      width: "10%",
    },
  ];

  return (
    <div className="w-full">
      { modalIsOpen && (
        <Modal
          onClose={ () => setModalIsOpen(false) }
          title={ modalType === "add" ? "Добавить категорию" : "Подарки" }
          content={ () => content() }
          footer={ () => footer() }
        />
      ) }
      <Heading title={ "Запросы на подарки" } />
      {/*  SECTION  */ }
      <div
        className={ "px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto" }
        style={ { minHeight: "80vh", maxHeight: "100vh" } }
      >
        {/* SECTION HEADING */ }
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full  sm:flex-col"
          }
        >
          <Select
            className="form-control__multiselect w-326 sm:w-full"
            onChange={ (e) => {
              setSelectedAssistant(e);
            } }
            value={ selectedAssistant }
          >
            { [
              {
                name: "Все сотрудники",
                id: "",
              },
              ...assistants,
            ]?.map((item, index) => (
              <Select.Option value={ item?.id } key={ index }>
                { item?.name }
              </Select.Option>
            )) }
          </Select>
          <Select
            className="form-control__multiselect w-326 sm:w-full"
            onChange={ (e) => {
              setGiven(e);
            } }
            value={ given }
          >
            <Select.Option value={ 1 }>
              Завершенный
            </Select.Option>
            <Select.Option value={ 0 }>
              Не завершен
            </Select.Option>
          </Select>

          <input
            type={ "date" }
            placeholder="От времени"
            className="bg-background-color px-2 py-1 rounded-md sm:w-full"
            value={ from }
            onChange={ e => {
              setFrom(e.target.value)
            } }
          />
          <input
            type={ "date" }
            placeholder="До времени"
            className="bg-background-color px-2 py-1 rounded-md sm:w-full"
            value={ to }
            onChange={ e => {
              setTo(e.target.value)
            } }
          />

          <Button
            status={ requestStart ? "primary" : "danger" }
            loading={ requesting }
            text={ requestStart ? "Запросы принимаются" : "Запрос приостановлен." }
            about={ requestStart ? "Нажмите, чтобы прекратить получать запросы" : "Нажмите, чтобы начать принимать запросы" }
            onClick={ changeRecGift }
          />
          <Button
            loading={ downloading }
            text={ "Экспорт в Excel" }
            onClick={ e => {
              downloadXLS({
                setDownloading: setDownloading,
                assistant_id: selectedAssistant,
                completed: given,
                from: from,
                to: to
              })
            } }
          />
        </div>
        {/*  SECTION BODY  */ }
        <div className={ "mt-6 w-full" }>
          <Skeleton loading={ loading } active>
            <center>
              <div className="flex gap-x-4 justify-center">
                <Pagination
                  pageSize={ per_page ? per_page : 40 }
                  total={ last_page * per_page }
                  current={ currentPage }
                  onChange={ (page, x) => {
                    setCurrentPage(page)
                    setPerPage(x)
                  } }
                />
                <InputNumber
                  onChange={ e => onChangeFunc(e) }
                  value={ per_page }
                />
              </div>
            </center>
            <br /><br />
            <Table
              rowKey={ (record) => record.id }
              dataSource={ dataSource }
              columns={ columns }
              pagination={ false }
            />
            <br /><br />
            <center>
              <div className="flex gap-x-4 justify-center">
                <Pagination
                  pageSize={ per_page ? per_page : 40 }
                  total={ last_page * per_page }
                  current={ currentPage }
                  onChange={ (page, x) => {
                    setCurrentPage(page)
                    setPerPage(x)
                  } }
                />
                <InputNumber
                  onChange={ e => onChangeFunc(e) }
                  value={ per_page }
                />
              </div>
            </center>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default GiftRequest;




