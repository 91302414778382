import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import API from '../../assets/api/api'
import {
  fetchingClients,
  fetchedClients,
  fetchingClientsError,
  deleteClient,
} from '../../redux/clientsSlice'
import Button from '../../UI/Button'
import Heading from '../../UI/Heading'
import SearchInput from '../../UI/SearchInput'

import { Loading } from 'react-loading-ui'
import {
  Checkbox,
  InputNumber,
  message,
  Pagination,
  Popconfirm,
  Select,
  Skeleton,
  Spin,
  Table,
  TreeSelect,
} from 'antd'
import { logout } from '../../redux/loginSlice'
import { useNavigate } from 'react-router-dom'
import Modal from '../../UI/Modal'
import Dropdown from '../../UI/Dropdown'
import _debounce from 'lodash/debounce'
import moment from 'moment'
import { useState } from 'react'
import { downloadXLS } from './downloadXSL'

const { TreeNode } = TreeSelect

const OrderHistory = () => {
  const [search, setSearch] = React.useState('')
  const dispatch = useDispatch()
  const { clients, loading } = useSelector((state) => state.clients)
  const [searchStr, setSearchStr] = React.useState('')
  const navigate = useNavigate()
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [modalType, setModalType] = React.useState('')
  const [selectedCategory, setSelectedCategory] = React.useState({})
  const [newClient, setNewClient] = React.useState({
    name: modalType === 'add' ? '' : selectedCategory.name,
  })
  const [uploading, setUploading] = React.useState(false)
  const [newArr, setNewDataSource] = React.useState([])
  const [historiesData, setHistoriesData] = React.useState(clients)
  const [employees, setEmployees] = React.useState([])

  // FILTER STATES
  const [filter, setFilter] = React.useState({
    name: '',
    phone: '',
    branch_id: '',
    assistant_id: '',
    status_id: '',
    point: '',
    supervisor_id: '',
    point_from: '',
    point_to: '',
    search,
  })

  const [branch_id, setBranch_id] = React.useState(
    localStorage.getItem('myBranch') || ''
  )
  const [supervisor_id, setSupervisor_id] = React.useState('')
  const [assistant_id, setAssistant_id] = React.useState('')
  // BRANCHES
  const [branches, setBranches] = React.useState([
    {
      value: '',
      label: 'Все филиалы',
    },
  ])

  // ASSISTANTS
  const [assistants, setAssistants] = React.useState([
    {
      value: '',
      label: 'Все ассистенты',
    },
  ])

  // SUPERVISORS
  const [supervisors, setSupervisors] = React.useState([
    {
      value: '',
      label: 'Все Руководитель',
    },
  ])

  // SELECTED ITEMS
  const [selectedItems, setSelectedItems] = React.useState([])
  const [point_type, setPoint_type] = React.useState('')

  const [min_point, setMin_point] = React.useState('')
  const [max_point, setMax_point] = React.useState('')
  const [min_point1, setMin_point1] = React.useState('')
  const [max_point1, setMax_point1] = React.useState('')

  const [selectedBasket, setSelectedBasket] = React.useState({})
  const [basketShow, setBasketShow] = React.useState(false)
  // FETCHING EMPLOYEES
  React.useEffect(() => {}, [])

  const pointTypes = [
    {
      label: 'Все баллы',
      value: '',
    },
    {
      label: 'Текущий бал',
      value: 'point',
    },
    {
      label: 'Всего баллов',
      value: 'total_point',
    },
  ]

  const [refresh, setRefresh] = React.useState(false)
  const [from, setFrom] = React.useState(
    moment(new Date()).format('YYYY-MM-DD')
  )
  const [to, setTo] = React.useState(moment(new Date()).format('YYYY-MM-DD'))
  const [ordersData, setOrdersData] = useState({})
  const [per_page, setPerPage] = useState(0)
  const [last_page, setLastPage] = useState(0)
  const [current_page, setCurrentPage] = useState(1)
  const [paidDebt, setPaidDebt] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [onlyCombo, setOnlyCombo] = useState(false)
  const [merge, setMerge] = useState(false)
  const [debtPaymentHistories, setDebtPaymentHistories] = useState([])
  const [showDebtPaymentHistories, setShowDebtPaymentHistories] =
    useState(false)
  const [loadingDebtPaymentHistories, setLoadingDebtPaymentHistories] =
    useState(false)
  // FETCHING BRANCHES
  React.useEffect(() => {
    API.get(
      `/api/branches/${
        localStorage.getItem('vivian_role') !== 'ceo' ? 'children' : ''
      }`
    ).then((res) => {
      if (localStorage.getItem('vivian_role') === 'ceo') {
        setBranches(res.data.payload)
      } else {
        setBranches([res.data.payload])
      }
    })
  }, [])

  // FETCHING EMPLOYEE
  React.useEffect(() => {
    API.get(`/api/employees?role=supervisor&branch_id=${branch_id}`).then(
      (res) => {
        setSupervisors(res.data.payload)
      }
    )
    API.get(`/api/employees?role=assistant&branch_id=${branch_id}`).then(
      (res) => {
        setAssistants(res.data.payload)
      }
    )
  }, [branch_id])

  // MODAL WINDOW CONTENT
  const content = () => {
    return (
      <div>
        {selectedBasket?.orders?.length ? (
          <table
            className="table"
            style={{ minWidth: '700px', oveflowX: 'auto' }}
          >
            <thead className="w-full py-1 px-1">
              <th className="w-full py-1 px-1 text-left font-bold bg-background-color">
                Продукты
              </th>
              <th className="w-full py-1 px-1 text-left font-bold bg-background-color">
                Количество
              </th>
              <th className="w-full py-1 px-1 text-left font-bold bg-background-color">
                Балл
              </th>
              <th className=" py-1 px-8 text-left font-bold bg-background-color">
                Цена{' '}
              </th>
              {localStorage?.getItem('vivian_role') === 'ceo' ? (
                <th className="w-full py-1 px-1 text-left font-bold bg-background-color">
                  Удалить
                </th>
              ) : null}
            </thead>
            <tbody>
              {selectedBasket?.orders?.map((item, index) => (
                <tr key={index} className=" w-full">
                  <td className=" color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                    {item?.product_name}
                  </td>
                  <td className=" color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                    {item?.count}
                  </td>
                  <td className=" color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                    {item?.point}
                  </td>
                  <td className=" color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                    <span width="200" className="flex">
                      {item?.price?.toLocaleString() + ' UZS'}
                    </span>
                  </td>
                  <td>
                    {localStorage?.getItem('vivian_role') === 'ceo' ? (
                      <Popconfirm
                        title="Выберите уверены, что хотите удалить этот корзинка?"
                        onConfirm={() => deleteOrder('return', item?.order_id)}
                        onCancel={() => deleteOrder('defect', item?.order_id)}
                        okText="Возвращение"
                        cancelText="Бракованный"
                      >
                        <Button
                          mode={'icon'}
                          text={<ion-icon name="trash-outline"></ion-icon>}
                        />
                      </Popconfirm>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <center className="w-750 sm:w-full text-center">
            Пустая корзина
          </center>
        )}
      </div>
    )
  }

  // debt payment content
  const debtContent = () => {
    return (
      <div>
        {loadingDebtPaymentHistories ? (
          <Skeleton active />
        ) : (
          <table className="w-full">
            <thead className="w-full bg-background-color rounded-md p-2">
              <tr>
                <td className="p-2 border-r border-table-border">Получатель</td>
                <td className="p-2 border-r border-table-border">Наличные</td>
                <td className="p-2 border-r border-table-border">
                  Пластиковая карта
                </td>
                <td className="p-2 border-r border-table-border">Ваучер</td>
                <td className="p-2 border-r border-table-border">Время</td>
              </tr>
            </thead>

            <tbody>
              {debtPaymentHistories?.map((item) => (
                <tr>
                  <td className="p-2 border-b border-table-border text-gray-txt-color">
                    <b>{item?.employee?.name}</b> <br />
                    <span>{item?.employee?.phone}</span>
                  </td>
                  <td className="p-2 border-b border-table-border">
                    {item?.cash?.toLocaleString()} uzs
                  </td>
                  <td className="p-2 border-b border-table-border">
                    {item?.card?.toLocaleString()} uzs
                  </td>
                  <td className="p-2 border-b border-table-border">
                    {item?.voucher?.toLocaleString()} uzs
                  </td>
                  <td className="p-2 border-b border-table-border">
                    {item?.created_at}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  // MODAL WINDOW FOOTER
  const footer = () => {}

  // BRANCHES OPTIONS
  const branchesOptions = branches.map((branch) => {
    if (branch.childrens) {
      return {
        value: branch.id,
        label: branch.name,
        childrens: branch.childrens.map((child) => {
          if (child.childrens) {
            return {
              value: child.id,
              label: child.name,
              childrens: child.childrens.map((grandChild) => {
                return {
                  value: grandChild.id,
                  label: grandChild.name,
                }
              }),
            }
          }

          return {
            value: child.id,
            label: child.name,
          }
        }),
      }
    }
    return {
      value: branch.id,
      label: branch.name,
    }
  })

  const showDebtPaymentHistory = (id) => {
    setLoadingDebtPaymentHistories(true)
    setShowDebtPaymentHistories(true)
    API.get(`/api/payment/histories/${id}`)
      .then((res) => {
        setDebtPaymentHistories(res.data.payload)
      })
      .finally(() => {
        setLoadingDebtPaymentHistories(false)
      })
  }

  // FETCHING CATEGORIES
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: '#377DFF' })
    API.get(
      `/api/orders/histories${
        paidDebt ? '/debt' : ''
      }?branch_id=${branch_id}&supervisor_id=${supervisor_id}&assistant_id=${assistant_id}&from=${from}&to=${to}&page=${
        current_page || 1
      }&per_page=${per_page}`
    )
      .then((res) => {
        setHistoriesData(res.data.payload.data?.baskets)
        setOrdersData(res.data.payload.data)
        setPerPage(res.data.payload.per_page)
        setLastPage(res.data.payload.last_page)
      })
      .catch((err) => {
        console.log(err)
        if (err.response.status === 401) {
          dispatch(logout())
          navigate('/login', { replace: true })
        }
      })
      .finally(() => Loading())
  }, [
    branch_id,
    searchStr,
    supervisor_id,
    filter?.status_id,
    assistant_id,
    point_type,
    min_point,
    max_point,
    refresh,
    from,
    to,
    current_page,
    paidDebt,
    per_page,
  ])

  // DELETE order
  const deleteOrder = (type, id) => {
    API.post(`/api/orders/return/${selectedBasket?.id}/${id}`, {
      type: type,
    })
      .then((res) => {
        setRefresh(!refresh)
        message.success('Продукта успешно Вернулся')
        setBasketShow(false)
      })
      .catch((err) => {
        console.log(err)
        message.error('Ошибка при Вернулся!')
      })
  }
  // DELETE BASKET
  const deleteBasket = (type, id) => {
    API.post(`/api/orders/return/${id}`, {
      type: type,
    })
      .then((res) => {
        setRefresh(!refresh)
        message.success('Корзинка успешно Вернулся')
        setBasketShow(false)
      })
      .catch((err) => {
        console.log(err)
        message.error('Ошибка при Вернулся!')
      })
  }

  const dataSource = []

  historiesData?.length &&
    historiesData?.forEach((item, index) => {
      dataSource.push({
        id: item?.id,
        name: (
          <div className="flex gap-x-4 items-center">
            <svg
              width="32"
              height="24"
              viewBox="0 0 32 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.19977 23.3061C6.04283 23.3061 5.05706 22.8988 4.24248 22.0842C3.4279 21.2696 3.02061 20.2838 3.02061 19.1269H0.683105V2.7998C0.683105 2.23314 0.895606 1.7373 1.32061 1.3123C1.74561 0.887304 2.24144 0.674805 2.80811 0.674805H23.3144V6.58939H27.0331L31.8498 12.9998V19.1269H29.3352C29.3352 20.2838 28.9279 21.2696 28.1133 22.0842C27.2987 22.8988 26.313 23.3061 25.156 23.3061C23.9991 23.3061 23.0133 22.8988 22.1987 22.0842C21.3841 21.2696 20.9769 20.2838 20.9769 19.1269H11.3789C11.3789 20.2838 10.9716 21.2696 10.1571 22.0842C9.34248 22.8988 8.35672 23.3061 7.19977 23.3061ZM7.19977 21.1811C7.76644 21.1811 8.25047 20.9804 8.65186 20.579C9.05324 20.1776 9.25394 19.6936 9.25394 19.1269C9.25394 18.5602 9.05324 18.0762 8.65186 17.6748C8.25047 17.2734 7.76644 17.0727 7.19977 17.0727C6.63311 17.0727 6.14908 17.2734 5.74769 17.6748C5.3463 18.0762 5.14561 18.5602 5.14561 19.1269C5.14561 19.6936 5.3463 20.1776 5.74769 20.579C6.14908 20.9804 6.63311 21.1811 7.19977 21.1811ZM2.80811 17.0019H3.58727C3.98866 16.3644 4.4963 15.8567 5.11019 15.479C5.72408 15.1012 6.4088 14.9123 7.16436 14.9123C7.91991 14.9123 8.61054 15.1071 9.23623 15.4967C9.86192 15.8863 10.3755 16.388 10.7769 17.0019H21.1894V2.7998H2.80811V17.0019ZM25.156 21.1811C25.7227 21.1811 26.2067 20.9804 26.6081 20.579C27.0095 20.1776 27.2102 19.6936 27.2102 19.1269C27.2102 18.5602 27.0095 18.0762 26.6081 17.6748C26.2067 17.2734 25.7227 17.0727 25.156 17.0727C24.5894 17.0727 24.1053 17.2734 23.7039 17.6748C23.3025 18.0762 23.1019 18.5602 23.1019 19.1269C23.1019 19.6936 23.3025 20.1776 23.7039 20.579C24.1053 20.9804 24.5894 21.1811 25.156 21.1811ZM23.3144 13.9561H29.9019L25.9706 8.71439H23.3144V13.9561Z"
                fill="#C4C4C4"
              />
            </svg>
            <div>
              <h2 className="text-md text-gray-txt-color">
                {item?.user?.name}
              </h2>
              <span className="text-xs text-gray-txt-color block">
                {item?.user?.phone !== null &&
                  item?.user?.phone.toLocaleString()}
              </span>
              <span className="text-xs text-gray-txt-color block">
                {item?.user?.address !== null && item?.user?.address}
              </span>
            </div>
          </div>
        ),
        balance: (
          <>
            <span className="text-md text-gray-txt-color block">
              {item?.total_sum !== null
                ? item?.total_sum?.toLocaleString() + ' UZS'
                : 0}
            </span>
            <span className="text-xs text-gray-txt-color flex items-center gap-x-2">
              Карта: {item?.card !== null ? item?.card?.toLocaleString() : '0'}{' '}
              UZS
            </span>
            <span className="text-xs text-gray-txt-color flex items-center gap-x-2">
              Наличные:{' '}
              {item?.cash !== null ? item?.cash?.toLocaleString() : '0'} UZS
            </span>
          </>
        ),
        debt: (
          <>
            <span className="text-md text-gray-txt-color block">
              {item?.debt?.debt !== null
                ? item?.debt?.debt?.toLocaleString() + ' UZS'
                : 0}
            </span>
            <span
              onClick={() => {
                if (
                  item?.debt?.paid_debt !== 0 &&
                  item?.debt?.paid_debt !== null
                ) {
                  showDebtPaymentHistory(item?.id)
                  setSelectedBasket(item)
                }
              }}
              className={`
                text-xs 
                ${
                  item?.debt?.paid_debt !== 0 && item?.debt?.paid_debt !== null
                    ? 'text-primary-color cursor-pointer'
                    : null
                }
                 flex 
                items-center 
                gap-x-2
                opacity-70
                hover:opacity-100
              `}
            >
              Всего выплачено:{' '}
              {item?.debt?.paid_debt !== null
                ? item?.debt?.paid_debt?.toLocaleString()
                : '0'}{' '}
              UZS
            </span>
            <span className="text-xs text-gray-txt-color flex items-center gap-x-2">
              Карта:{' '}
              {item?.debt?.paid_debt_card !== null
                ? item?.debt?.paid_debt_card?.toLocaleString()
                : '0'}{' '}
              UZS
            </span>
            <span className="text-xs text-gray-txt-color flex items-center gap-x-2">
              Наличные:{' '}
              {item?.debt?.paid_debt_cash !== null
                ? item?.debt?.paid_debt_cash?.toLocaleString()
                : '0'}{' '}
              UZS
            </span>
          </>
        ),
        supervisor: (
          <>
            <span className="text-md text-gray-txt-color block">
              {item?.supervisor?.name !== null
                ? item?.supervisor?.name
                : 'Нет Cупервизорa'}
            </span>
          </>
        ),
        assistant: (
          <>
            <span className="text-md text-gray-txt-color block">
              {item?.assistant !== null
                ? item?.assistant?.name
                : 'Нет Ассистента'}
            </span>
          </>
        ),
        date: (
          <>
            <span className="text-md text-gray-txt-color block">
              Заказал: {moment(item?.ordered_at).format('DD/MM/YYYY')}
            </span>
            <span className="text-md text-gray-txt-color block">
              Доставленный: {moment(item?.delivered_at).format('DD/MM/YYYY')}
            </span>
          </>
        ),
        point: item?.total_point + ' Балл',
        action: (
          <div className="flex gap-x-2">
            {localStorage.getItem('vivian_role') === 'ceo' ||
            localStorage.getItem('vivian_role') === 'super-admin' ? (
              <Popconfirm
                title="Вы уверены, что хотите удалить этот корзинка?"
                onConfirm={() => deleteBasket('return', item?.id)}
                onCancel={() => deleteBasket('defect', item?.id)}
                okText="Возвращение"
                cancelText="Бракованный"
              >
                <Button
                  mode={'icon'}
                  text={<ion-icon name="trash-outline"></ion-icon>}
                />
              </Popconfirm>
            ) : null}
            <Button
              mode={'icon'}
              text={<ion-icon name="list-outline"></ion-icon>}
              onClick={() => {
                setSelectedBasket(item)
                setBasketShow(true)
              }}
            />
          </div>
        ),
      })
    })

  let columns = [
    {
      key: 'id',
      title: <b>Клиент данных</b>,
      dataIndex: 'name',
      width: '30%',
    },
    {
      key: 'balance',
      title: <span className="text-table-gray">Цена</span>,
      dataIndex: 'balance',
    },
    {
      key: 'debt',
      title: <span className="text-table-gray">Долг</span>,
      dataIndex: 'debt',
      width: '170px',
    },
    {
      key: 'supervisor',
      title: <span className="text-table-gray">Супервайзер</span>,
      dataIndex: 'supervisor',
    },
    {
      key: 'assistant',
      title: <span className="text-table-gray">Ассистент</span>,
      dataIndex: 'assistant',
    },
    {
      key: 'point',
      title: <span className="text-table-gray">Балл</span>,
      dataIndex: 'point',
    },
    {
      key: 'date',
      title: <span className="text-table-gray">Информация</span>,
      dataIndex: 'date',
    },
    {
      key: 'action',
      title: <span className="text-table-gray">Действие</span>,
      dataIndex: 'action',
    },
  ]

  const deboundeFnc = useCallback(
    _debounce((value) => {
      setPerPage(value)
    }, 500),
    []
  )

  const onChangeFunc = (e) => {
    deboundeFnc(e)
  }

  return (
    <div className="w-full">
      {showDebtPaymentHistories ? (
        <Modal
          size="large"
          onClose={() => setShowDebtPaymentHistories(false)}
          title={selectedBasket?.user?.name}
          content={() => debtContent()}
          footer={() => {}}
        />
      ) : null}

      {basketShow && (
        <Modal
          size="large"
          onClose={() => setBasketShow(false)}
          title={selectedBasket?.user?.name}
          content={() => content()}
          footer={() => footer()}
        />
      )}
      <Heading title={'История заказов'} />
      {/*  SECTION  */}
      <div
        className={'px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto'}
        style={{ minHeight: '80vh', maxHeight: '100vh' }}
      >
        {/* SECTION HEADING */}
        <div
          className={
            'gap-4 inline-flex items-stretch  mb-8 w-full justify-between sm:flex-col '
          }
        >
          {/* LEFT DETAILS */}
          <div className="flex-col gap-x-4 sm:flex-col sm:w-full gap-y-6">
            <div className="w-50 flex flex-col gap-y-6 sm:w-full">
              {/*  BRANCH  */}
              <div className="inline-flex flex-col sm:w-full">
                <span className="mb-2 text-xs">Филиал</span>
                {/* FILTER BY BRANCHES */}
                <TreeSelect
                  value={branch_id}
                  defaultValue={branch_id}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                  }}
                  placeholder="Все филиал"
                  className="form-control__multiselect w-326 sm:w-full"
                  onChange={(e) => {
                    setAssistant_id('')
                    setSupervisor_id('')
                    setBranch_id(e)
                  }}
                >
                  {[
                    {
                      value: '',
                      label: 'Все филиал',
                    },
                    ...branchesOptions,
                  ].map((branch) => {
                    if (branch.childrens) {
                      return (
                        <TreeNode
                          value={branch?.value}
                          title={branch.label}
                          key={branch.value}
                        >
                          {branch?.childrens?.map((child) => (
                            <TreeNode
                              value={child.value}
                              title={child.label}
                              key={child.value}
                            />
                          ))}
                        </TreeNode>
                      )
                    }
                    return (
                      <TreeNode
                        value={branch.value}
                        title={branch.label}
                        key={branch.value}
                      />
                    )
                  })}
                </TreeSelect>
              </div>

              {/*  SUPERVISOR  */}
              <div className="inline-flex flex-col">
                <span className="mb-2 text-xs">Руководитель</span>
                <Select
                  className="form-control__multiselect w-326 sm:w-full"
                  value={supervisor_id}
                  onChange={(value) => {
                    setSupervisor_id(value)
                    setAssistant_id('')
                    setBranch_id('')
                    setCurrentPage('')
                    if (value !== '') {
                      setCurrentPage('')
                    } else {
                      setCurrentPage(1)
                    }
                  }}
                  placeholder={'Все руководитель'}
                  defaultValue={supervisor_id}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {[
                    {
                      id: '',
                      name: 'Все руководитель',
                    },
                    ...supervisors,
                  ]?.map((supervisor, index) => (
                    <Select.Option value={supervisor?.id} key={index}>
                      {supervisor?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <br />
            <div className="w-50 flex flex-col gap-y-6">
              {/*  NAME  */}
              <div className="flex flex-col ">
                <span className="mb-2 text-xs">Время от времени</span>
                <div className="flex gap-x-2">
                  <input
                    type="date"
                    onChange={(e) => {
                      setFrom(e.target.value)
                    }}
                    className="form-control w-50%"
                    placeholder="Время от"
                    value={from}
                  />
                  <input
                    type="date"
                    onChange={(e) => {
                      setTo(e.target.value)
                    }}
                    className="form-control w-50%"
                    placeholder="Время до"
                    value={to}
                  />
                </div>
              </div>

              {/*  ASSISTANT  */}
              <div className="inline-flex flex-col">
                <span className="mb-2 text-xs">Помощник</span>
                <Select
                  className="form-control__multiselect w-326 sm:w-full"
                  value={assistant_id}
                  onChange={(value) => {
                    setAssistant_id(value)
                    setSupervisor_id('')
                    setBranch_id('')
                    if (value !== '') {
                      setCurrentPage('')
                    } else {
                      setCurrentPage(1)
                    }
                  }}
                  placeholder={'Выберите помощника'}
                  defaultValue={assistant_id}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {[
                    {
                      id: '',
                      name: 'Все помощники',
                    },
                    ...assistants,
                  ]?.map((supervisor, index) => (
                    <Select.Option value={supervisor?.id} key={index}>
                      {supervisor?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="inline-flex flex-col">
                <label className="p-2 rounded-5 bg-background-color">
                  <Checkbox
                    value={paidDebt}
                    onChange={(e) => setPaidDebt(e.target.checked)}
                  >
                    Только оплаченные долги.
                  </Checkbox>
                </label>
                <>
                  {paidDebt ? null : (
                    <>
                      <label className="p-2 rounded-5 bg-background-color">
                        <Checkbox
                          onChange={(e) => setOnlyCombo(e.target.checked)}
                          checked={onlyCombo}
                        >
                          Экспортировать только комбо.
                        </Checkbox>
                      </label>

                      <>
                        {!onlyCombo ? (
                          <label className="p-2 rounded-5 bg-background-color">
                            <Checkbox
                              onChange={(e) => setMerge(e.target.checked)}
                              checked={merge}
                            >
                              Объедините одинаковые корзины.
                            </Checkbox>
                          </label>
                        ) : null}
                      </>
                    </>
                  )}
                </>
              </div>
              <div className="">
                <span className="inline">
                  <Button
                    disabled={paidDebt}
                    text={'Экспорт в Excel'}
                    loading={downloading}
                    onClick={() =>
                      downloadXLS({
                        setDownloading: setDownloading,
                        from: from,
                        to: to,
                        combo: onlyCombo,
                        merge: merge,
                        assistant_id,
                        branch_id,
                        supervisor_id,
                      })
                    }
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="flex gap-x-4 sm:flex-col sm:w-full flex-wrap">
            {/* RIGHT DETAILS */}
            <div className="flex-2  flex flex-col gap-y-3 sm:w-full lg:w-44 md:w-32">
              <span className="sm:text-table-dark sm:mt-2 sm:border-table-dark sm:text-center text-gray-txt-color  px-2 py-1 bg-background-color rounded-md cursor-pointer border-b border-1 border-table-border">
                Общая задолженность
              </span>
              {/*  BALL  */}
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7664 4.08333H12.5164C12.6711 4.08333 12.8195 4.14479 12.9289 4.25419C13.0383 4.36358 13.0998 4.51196 13.0998 4.66667V11.6667C13.0998 11.8214 13.0383 11.9697 12.9289 12.0791C12.8195 12.1885 12.6711 12.25 12.5164 12.25H2.01644C1.86173 12.25 1.71336 12.1885 1.60396 12.0791C1.49456 11.9697 1.43311 11.8214 1.43311 11.6667V2.33333C1.43311 2.17862 1.49456 2.03025 1.60396 1.92085C1.71336 1.81146 1.86173 1.75 2.01644 1.75H10.7664V4.08333ZM2.59977 5.25V11.0833H11.9331V5.25H2.59977ZM2.59977 2.91667V4.08333H9.59977V2.91667H2.59977ZM9.01644 7.58333H10.7664V8.75H9.01644V7.58333Z"
                      fill="#313946"
                    />
                  </svg>
                  {ordersData?.debt?.debt?.toLocaleString()} UZS
                </div>
              </div>
            </div>
            {/* RIGHT DETAILS */}
            <div className="flex-2  flex flex-col gap-y-3 sm:w-full lg:w-44 md:w-32">
              <span className="sm:text-table-dark sm:mt-2 sm:border-table-dark sm:text-center text-gray-txt-color  px-2 py-1 bg-background-color rounded-md cursor-pointer border-b border-1 border-table-border">
                Оплаченный долги
              </span>
              {/*  BALL  */}
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.74984 1.75H12.2498C12.4045 1.75 12.5529 1.81146 12.6623 1.92085C12.7717 2.03025 12.8332 2.17862 12.8332 2.33333V11.6667C12.8332 11.8214 12.7717 11.9697 12.6623 12.0791C12.5529 12.1885 12.4045 12.25 12.2498 12.25H1.74984C1.59513 12.25 1.44675 12.1885 1.33736 12.0791C1.22796 11.9697 1.1665 11.8214 1.1665 11.6667V2.33333C1.1665 2.17862 1.22796 2.03025 1.33736 1.92085C1.44675 1.81146 1.59513 1.75 1.74984 1.75ZM11.6665 6.41667H2.33317V11.0833H11.6665V6.41667ZM11.6665 5.25V2.91667H2.33317V5.25H11.6665ZM8.1665 8.75H10.4998V9.91667H8.1665V8.75Z"
                      fill="#313946"
                    />
                  </svg>
                  {ordersData?.debt?.paid_debt_card?.toLocaleString()} UZS
                </div>
              </div>
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.91667 9.33317H11.0833V2.33317H5.25V3.49984H9.91667V9.33317ZM9.91667 10.4998V12.2498C9.91667 12.5718 9.65417 12.8332 9.32925 12.8332H2.33742C2.26049 12.8336 2.18423 12.8189 2.11302 12.7898C2.04181 12.7607 1.97705 12.7178 1.92247 12.6636C1.86788 12.6094 1.82455 12.5449 1.79495 12.4739C1.76535 12.4029 1.75008 12.3268 1.75 12.2498L1.75175 4.08317C1.75175 3.76117 2.01425 3.49984 2.33917 3.49984H4.08333V1.74984C4.08333 1.59513 4.14479 1.44675 4.25419 1.33736C4.36358 1.22796 4.51196 1.1665 4.66667 1.1665H11.6667C11.8214 1.1665 11.9697 1.22796 12.0791 1.33736C12.1885 1.44675 12.25 1.59513 12.25 1.74984V9.9165C12.25 10.0712 12.1885 10.2196 12.0791 10.329C11.9697 10.4384 11.8214 10.4998 11.6667 10.4998H9.91667ZM2.91842 4.6665L2.91667 11.6665H8.75V4.6665H2.91842ZM4.08333 9.33317H6.70833C6.78569 9.33317 6.85987 9.30244 6.91457 9.24774C6.96927 9.19305 7 9.11886 7 9.0415C7 8.96415 6.96927 8.88996 6.91457 8.83526C6.85987 8.78057 6.78569 8.74984 6.70833 8.74984H4.95833C4.57156 8.74984 4.20063 8.59619 3.92714 8.3227C3.65365 8.04921 3.5 7.67828 3.5 7.2915C3.5 6.90473 3.65365 6.5338 3.92714 6.26031C4.20063 5.98682 4.57156 5.83317 4.95833 5.83317H5.25V5.24984H6.41667V5.83317H7.58333V6.99984H4.95833C4.88098 6.99984 4.80679 7.03057 4.75209 7.08526C4.6974 7.13996 4.66667 7.21415 4.66667 7.2915C4.66667 7.36886 4.6974 7.44305 4.75209 7.49774C4.80679 7.55244 4.88098 7.58317 4.95833 7.58317H6.70833C7.09511 7.58317 7.46604 7.73682 7.73953 8.01031C8.01302 8.2838 8.16667 8.65473 8.16667 9.0415C8.16667 9.42828 8.01302 9.79921 7.73953 10.0727C7.46604 10.3462 7.09511 10.4998 6.70833 10.4998H6.41667V11.0832H5.25V10.4998H4.08333V9.33317Z"
                      fill="#313946"
                    />
                  </svg>
                  {ordersData?.debt?.paid_debt_cash?.toLocaleString()} UZS
                </div>
              </div>
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7664 4.08333H12.5164C12.6711 4.08333 12.8195 4.14479 12.9289 4.25419C13.0383 4.36358 13.0998 4.51196 13.0998 4.66667V11.6667C13.0998 11.8214 13.0383 11.9697 12.9289 12.0791C12.8195 12.1885 12.6711 12.25 12.5164 12.25H2.01644C1.86173 12.25 1.71336 12.1885 1.60396 12.0791C1.49456 11.9697 1.43311 11.8214 1.43311 11.6667V2.33333C1.43311 2.17862 1.49456 2.03025 1.60396 1.92085C1.71336 1.81146 1.86173 1.75 2.01644 1.75H10.7664V4.08333ZM2.59977 5.25V11.0833H11.9331V5.25H2.59977ZM2.59977 2.91667V4.08333H9.59977V2.91667H2.59977ZM9.01644 7.58333H10.7664V8.75H9.01644V7.58333Z"
                      fill="#313946"
                    />
                  </svg>
                  {ordersData?.debt?.paid_debt?.toLocaleString()} UZS
                </div>
              </div>
            </div>
            {/* RIGHT DETAILS */}
            <div className="flex-2 flex flex-col gap-y-3  lg:w-44 md:w-32 sm:w-full">
              <span className="sm:text-table-dark sm:mt-2 sm:border-table-dark sm:text-center text-gray-txt-color px-2 py-1 bg-background-color rounded-md cursor-pointer border-b border-1 border-table-border">
                Касса
              </span>
              {/*  BALL  */}
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.74984 1.75H12.2498C12.4045 1.75 12.5529 1.81146 12.6623 1.92085C12.7717 2.03025 12.8332 2.17862 12.8332 2.33333V11.6667C12.8332 11.8214 12.7717 11.9697 12.6623 12.0791C12.5529 12.1885 12.4045 12.25 12.2498 12.25H1.74984C1.59513 12.25 1.44675 12.1885 1.33736 12.0791C1.22796 11.9697 1.1665 11.8214 1.1665 11.6667V2.33333C1.1665 2.17862 1.22796 2.03025 1.33736 1.92085C1.44675 1.81146 1.59513 1.75 1.74984 1.75ZM11.6665 6.41667H2.33317V11.0833H11.6665V6.41667ZM11.6665 5.25V2.91667H2.33317V5.25H11.6665ZM8.1665 8.75H10.4998V9.91667H8.1665V8.75Z"
                      fill="#313946"
                    />
                  </svg>
                  {ordersData?.card?.toLocaleString()} UZS
                </div>
              </div>
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.91667 9.33317H11.0833V2.33317H5.25V3.49984H9.91667V9.33317ZM9.91667 10.4998V12.2498C9.91667 12.5718 9.65417 12.8332 9.32925 12.8332H2.33742C2.26049 12.8336 2.18423 12.8189 2.11302 12.7898C2.04181 12.7607 1.97705 12.7178 1.92247 12.6636C1.86788 12.6094 1.82455 12.5449 1.79495 12.4739C1.76535 12.4029 1.75008 12.3268 1.75 12.2498L1.75175 4.08317C1.75175 3.76117 2.01425 3.49984 2.33917 3.49984H4.08333V1.74984C4.08333 1.59513 4.14479 1.44675 4.25419 1.33736C4.36358 1.22796 4.51196 1.1665 4.66667 1.1665H11.6667C11.8214 1.1665 11.9697 1.22796 12.0791 1.33736C12.1885 1.44675 12.25 1.59513 12.25 1.74984V9.9165C12.25 10.0712 12.1885 10.2196 12.0791 10.329C11.9697 10.4384 11.8214 10.4998 11.6667 10.4998H9.91667ZM2.91842 4.6665L2.91667 11.6665H8.75V4.6665H2.91842ZM4.08333 9.33317H6.70833C6.78569 9.33317 6.85987 9.30244 6.91457 9.24774C6.96927 9.19305 7 9.11886 7 9.0415C7 8.96415 6.96927 8.88996 6.91457 8.83526C6.85987 8.78057 6.78569 8.74984 6.70833 8.74984H4.95833C4.57156 8.74984 4.20063 8.59619 3.92714 8.3227C3.65365 8.04921 3.5 7.67828 3.5 7.2915C3.5 6.90473 3.65365 6.5338 3.92714 6.26031C4.20063 5.98682 4.57156 5.83317 4.95833 5.83317H5.25V5.24984H6.41667V5.83317H7.58333V6.99984H4.95833C4.88098 6.99984 4.80679 7.03057 4.75209 7.08526C4.6974 7.13996 4.66667 7.21415 4.66667 7.2915C4.66667 7.36886 4.6974 7.44305 4.75209 7.49774C4.80679 7.55244 4.88098 7.58317 4.95833 7.58317H6.70833C7.09511 7.58317 7.46604 7.73682 7.73953 8.01031C8.01302 8.2838 8.16667 8.65473 8.16667 9.0415C8.16667 9.42828 8.01302 9.79921 7.73953 10.0727C7.46604 10.3462 7.09511 10.4998 6.70833 10.4998H6.41667V11.0832H5.25V10.4998H4.08333V9.33317Z"
                      fill="#313946"
                    />
                  </svg>
                  {ordersData?.cash?.toLocaleString()} UZS
                </div>
              </div>
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7664 4.08333H12.5164C12.6711 4.08333 12.8195 4.14479 12.9289 4.25419C13.0383 4.36358 13.0998 4.51196 13.0998 4.66667V11.6667C13.0998 11.8214 13.0383 11.9697 12.9289 12.0791C12.8195 12.1885 12.6711 12.25 12.5164 12.25H2.01644C1.86173 12.25 1.71336 12.1885 1.60396 12.0791C1.49456 11.9697 1.43311 11.8214 1.43311 11.6667V2.33333C1.43311 2.17862 1.49456 2.03025 1.60396 1.92085C1.71336 1.81146 1.86173 1.75 2.01644 1.75H10.7664V4.08333ZM2.59977 5.25V11.0833H11.9331V5.25H2.59977ZM2.59977 2.91667V4.08333H9.59977V2.91667H2.59977ZM9.01644 7.58333H10.7664V8.75H9.01644V7.58333Z"
                      fill="#313946"
                    />
                  </svg>
                  {(ordersData?.card + ordersData?.cash)?.toLocaleString()} UZS
                </div>
              </div>
            </div>
            {/* RIGHT DETAILS */}
            <div className="flex-2 flex flex-col gap-y-3  lg:w-44 md:w-32 sm:w-full">
              <span className="sm:text-table-dark sm:mt-2 sm:border-table-dark sm:text-center text-gray-txt-color  px-2 py-1 bg-background-color rounded-md cursor-pointer border-b border-1 border-table-border">
                Всего
              </span>
              {/*  BALL  */}
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.74984 1.75H12.2498C12.4045 1.75 12.5529 1.81146 12.6623 1.92085C12.7717 2.03025 12.8332 2.17862 12.8332 2.33333V11.6667C12.8332 11.8214 12.7717 11.9697 12.6623 12.0791C12.5529 12.1885 12.4045 12.25 12.2498 12.25H1.74984C1.59513 12.25 1.44675 12.1885 1.33736 12.0791C1.22796 11.9697 1.1665 11.8214 1.1665 11.6667V2.33333C1.1665 2.17862 1.22796 2.03025 1.33736 1.92085C1.44675 1.81146 1.59513 1.75 1.74984 1.75ZM11.6665 6.41667H2.33317V11.0833H11.6665V6.41667ZM11.6665 5.25V2.91667H2.33317V5.25H11.6665ZM8.1665 8.75H10.4998V9.91667H8.1665V8.75Z"
                      fill="#313946"
                    />
                  </svg>
                  {(
                    ordersData?.card + ordersData?.debt?.paid_debt_card
                  )?.toLocaleString()}{' '}
                  UZS
                </div>
              </div>
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.91667 9.33317H11.0833V2.33317H5.25V3.49984H9.91667V9.33317ZM9.91667 10.4998V12.2498C9.91667 12.5718 9.65417 12.8332 9.32925 12.8332H2.33742C2.26049 12.8336 2.18423 12.8189 2.11302 12.7898C2.04181 12.7607 1.97705 12.7178 1.92247 12.6636C1.86788 12.6094 1.82455 12.5449 1.79495 12.4739C1.76535 12.4029 1.75008 12.3268 1.75 12.2498L1.75175 4.08317C1.75175 3.76117 2.01425 3.49984 2.33917 3.49984H4.08333V1.74984C4.08333 1.59513 4.14479 1.44675 4.25419 1.33736C4.36358 1.22796 4.51196 1.1665 4.66667 1.1665H11.6667C11.8214 1.1665 11.9697 1.22796 12.0791 1.33736C12.1885 1.44675 12.25 1.59513 12.25 1.74984V9.9165C12.25 10.0712 12.1885 10.2196 12.0791 10.329C11.9697 10.4384 11.8214 10.4998 11.6667 10.4998H9.91667ZM2.91842 4.6665L2.91667 11.6665H8.75V4.6665H2.91842ZM4.08333 9.33317H6.70833C6.78569 9.33317 6.85987 9.30244 6.91457 9.24774C6.96927 9.19305 7 9.11886 7 9.0415C7 8.96415 6.96927 8.88996 6.91457 8.83526C6.85987 8.78057 6.78569 8.74984 6.70833 8.74984H4.95833C4.57156 8.74984 4.20063 8.59619 3.92714 8.3227C3.65365 8.04921 3.5 7.67828 3.5 7.2915C3.5 6.90473 3.65365 6.5338 3.92714 6.26031C4.20063 5.98682 4.57156 5.83317 4.95833 5.83317H5.25V5.24984H6.41667V5.83317H7.58333V6.99984H4.95833C4.88098 6.99984 4.80679 7.03057 4.75209 7.08526C4.6974 7.13996 4.66667 7.21415 4.66667 7.2915C4.66667 7.36886 4.6974 7.44305 4.75209 7.49774C4.80679 7.55244 4.88098 7.58317 4.95833 7.58317H6.70833C7.09511 7.58317 7.46604 7.73682 7.73953 8.01031C8.01302 8.2838 8.16667 8.65473 8.16667 9.0415C8.16667 9.42828 8.01302 9.79921 7.73953 10.0727C7.46604 10.3462 7.09511 10.4998 6.70833 10.4998H6.41667V11.0832H5.25V10.4998H4.08333V9.33317Z"
                      fill="#313946"
                    />
                  </svg>
                  {(
                    ordersData?.cash + ordersData?.debt?.paid_debt_cash
                  )?.toLocaleString()}{' '}
                  UZS
                </div>
              </div>
              <div className="inline-flex flex-col sm:w-full">
                <div className="bg-background-color flex sm:w-full gap-x-2 py-2 px-4 items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7664 4.08333H12.5164C12.6711 4.08333 12.8195 4.14479 12.9289 4.25419C13.0383 4.36358 13.0998 4.51196 13.0998 4.66667V11.6667C13.0998 11.8214 13.0383 11.9697 12.9289 12.0791C12.8195 12.1885 12.6711 12.25 12.5164 12.25H2.01644C1.86173 12.25 1.71336 12.1885 1.60396 12.0791C1.49456 11.9697 1.43311 11.8214 1.43311 11.6667V2.33333C1.43311 2.17862 1.49456 2.03025 1.60396 1.92085C1.71336 1.81146 1.86173 1.75 2.01644 1.75H10.7664V4.08333ZM2.59977 5.25V11.0833H11.9331V5.25H2.59977ZM2.59977 2.91667V4.08333H9.59977V2.91667H2.59977ZM9.01644 7.58333H10.7664V8.75H9.01644V7.58333Z"
                      fill="#313946"
                    />
                  </svg>
                  {(
                    ordersData?.card +
                    ordersData?.cash +
                    ordersData?.debt?.paid_debt
                  )?.toLocaleString()}{' '}
                  UZS
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  SECTION BODY  */}
        <div className={'mt-6 w-full'}>
          <div style={{ maxWidth: '100%' }} className="sm:overflow-x-auto">
            {!loading ? (
              <>
                <center>
                  {last_page !== 1 ? (
                    <div className="flex gap-x-4 justify-center">
                      <Pagination
                        pageSize={per_page ? per_page : 40}
                        total={last_page * per_page}
                        current={current_page}
                        onChange={(page, x) => {
                          setCurrentPage(page)
                          setPerPage(x)
                        }}
                      />
                      <InputNumber
                        onChange={(e) => onChangeFunc(e)}
                        value={per_page}
                      />
                    </div>
                  ) : null}
                </center>
                <br />
                <br />
                <Table
                  rowKey={(record) => record.id}
                  dataSource={dataSource}
                  columns={columns}
                  size={'small'}
                  pagination={false}
                />
                <br />
                <br />
                <center>
                  {last_page !== 1 ? (
                    <div className="flex gap-x-4 justify-center">
                      <Pagination
                        pageSize={per_page ? per_page : 40}
                        total={last_page * per_page}
                        current={current_page}
                        onChange={(page, x) => {
                          setCurrentPage(page)
                          setPerPage(x)
                        }}
                      />
                      <InputNumber
                        onChange={(e) => onChangeFunc(e)}
                        value={per_page}
                      />
                    </div>
                  ) : null}
                </center>
              </>
            ) : (
              <div className={'w-full flex items-center justify-center'}>
                <h3 className="text-gray-txt-color">
                  Ожидайте загрузки данных...
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderHistory
