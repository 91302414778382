import React, { Fragment } from 'react'

import { sidebarMenus } from '../../assets/Data/sidebarMenus'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import SidebarMenuContainer from './SidebarMenuContainer'
const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
	const activeClass = 'bg-primary-color text-white'
	const path = useLocation()
	const currentPath = path.pathname
	const [submenuIsOpen, setSubmenuIsOpen] = React.useState(false)

	const logoutFunc = () => {
		localStorage.removeItem('token')
		window.location.reload()
	}

	return (
		<>
			{/* responsive toggle btn */}
			<div
				onClick={() => setSidebarOpen(!sidebarOpen)}
				className={
					' z-10 hidden md:flex fixed left-0 sm:flex  h-10 items-center justify-center bg-darkish text-white text-xl rounded-tr-5 rounded-br-5 top-52 border-2 border-background-color'
				}
			>
				<ion-icon name='chevron-forward-outline'></ion-icon>
			</div>
			<div
				className={`sidebar px-[0] sm:w-full shadow flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll overflow-x-hidden lg:overflow-y-auto no-scrollbar md:sidebar-expanded:!w-72  w-72				
				shrink-0 bg-white py-3 transition-all duration-200 ease-in-out ${
					sidebarOpen ? 'translate-x-0' : '-translate-x-100'
				}`}
			>
				<SidebarMenuContainer />

				<br />
				{/* LOGOUT BTN */}
				<li
					className={'list-none mb-2 w-full cursor-pointer'}
					onClick={() => {
						logoutFunc()
					}}
				>
					<div
						className={`w-full flex items-center gap-4 px-7 py-3 text-sm rounded-lg  transition duration-500 ease-in-out text-gray-txt-color hover:text-primary-color`}
					>
						<i>
							<ion-icon
								className='font-medium'
								name='log-out-outline'
							></ion-icon>
						</i>
						<span>Выход</span>
					</div>
				</li>
			</div>
		</>
	)
}

export default Sidebar
