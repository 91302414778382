import { Input, Spin, message } from 'antd'
import React from 'react'

const MultipleDropdown = ({
	loading,
	onSearch,
	selectedItems,
	data,
	onChange,
	placeholder,
	beforeCustomPrice,
	value,
	classKey,
}) => {
	// window.addEventListener('click', e => {
	// 	console.log(e.target.className)

	// 	if (
	// 		!e.target.className?.includes('custom-price-input') ||
	// 		!e.target.className?.includes(classKey)
	// 	) {
	// 		('')
	// 	}
	// })
	return (
		<Spin spinning={loading} className={`custom-price-input ${classKey}`}>
			<label>
				<Input
					type='text'
					value={value}
					onChange={e => {
						onSearch(e.target.value)
					}}
					className={`custom-price-input w-full ${classKey}`}
					placeholder={placeholder}
				/>
				{/* dropdown */}
				{selectedItems?.length !== 0 || data?.length !== 0 ? (
					<div className='py-2 max-h-[200px] overflow-y-auto p-1'>
						<div className='flex pb-2 gap-2 flex-wrap'>
							{selectedItems?.map(item => {
								return (
									<div
										onClick={e => {
											onChange([
												...selectedItems.filter(x => x?.id !== item?.id),
											])
										}}
										className={`custom-price-input text-xs gap-x-2 px-3 py-2 cursor-pointer shadow-sm flex justify-between items-center hover:opacity-100 bg-indigo-100 text-gray-600 hover:bg-indigo-200 hover:text-gray-700 rounded-md shadow-md ${classKey}`}
									>
										{item.name}
										<ion-icon className='text-gray-400' name='close'></ion-icon>
									</div>
								)
							})}
						</div>
						<div>
							{data
								?.filter(item => {
									if (!selectedItems?.find(x => x?.id === item?.id)) return item
								})
								?.map(item => {
									return (
										<div
											onClick={() => {
												if (!item?.price) {
													onChange([...selectedItems, item])
												} else if (item?.price && item?.price?.active !== 1) {
													onChange([...selectedItems, item])
												} else {
													message.info(
														`Этому сотруднику уже назначена ${item?.price?.name} цена, и он является активом`
													)
												}
											}}
											className='custom-price-input px-4 py-2 cursor-pointer shadow-md bg-indigo-50 font-medium text-gray-500 hover:text-primary-color '
										>
											{item?.name}
										</div>
									)
								})}
						</div>
					</div>
				) : null}
			</label>
		</Spin>
	)
}

export default MultipleDropdown
