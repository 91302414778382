import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="h-screen flex items-center w-full justify-center ">
      <Result
        status="404"
        title="404"
        subTitle="Страница не найдена"
        extra={
          <Button type="primary">
            <Link to="/">На главную</Link>
          </Button>
        }
      />
    </div>
  );
};

export default PageNotFound;
