import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import API from '../../assets/api/api'
import Button from '../../UI/Button'
import Heading from '../../UI/Heading'
import SearchInput from '../../UI/SearchInput'
import { message, Popconfirm, Skeleton, Spin, Table, TreeSelect } from 'antd'
import { Loading } from 'react-loading-ui'
import Dropdown from '../../UI/Dropdown'
import {
	deleteProduct,
	fetchedProducts,
	fetchingProducts,
	fetchProducts,
	filterByCategoryFunc,
	filterByTermFunc,
	newProduct,
	updateProduct,
} from '../../redux/productsSlice'
import Pagination from '../../UI/Pagination'
import {
	fetchCategories,
	fetchedCategories,
	fetchingCategories,
} from '../../redux/categoriesSlice'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Modal from '../../UI/Modal'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useEffect } from 'react'

const { TreeNode } = TreeSelect
const Products = () => {
	// MUST BE INITIALIZED
	const dispatch = useDispatch()
	const [search, setSearch] = React.useState('')
	const { products, loading } = useSelector(state => state.products)
	const { categories } = useSelector(state => state.categories)
	const [filterByCategory, setFilterByCategory] = React.useState('')
	const [searchStr, setSearchStr] = React.useState('')
	const [dataSource, setDataSource] = React.useState(products)
	const [branches, setBranches] = React.useState([])
	const [selectedBranch, setSelectedBranch] = React.useState(
		localStorage.getItem('myBranch') || ''
	)
	const categoriesOptions = [
		{
			value: '',
			label: 'Все категории',
		},
	]

	// fetching branches
	useEffect(() => {
		API.get(
			`/api/branches/${
				localStorage.getItem('vivian_role') !== 'ceo' ? 'children' : ''
			}`
		).then(res => {
			if (localStorage.getItem('vivian_role') === 'ceo') {
				setBranches(res.data.payload)
			} else {
				setBranches([res.data.payload])
			}
		})
	}, [])

	// BRANCHES OPTIONS
	const branchesOptions = branches?.map(branch => {
		if (branch?.childrens) {
			return {
				value: branch?.id,
				label: branch?.name,
				childrens: branch?.childrens?.map(child => {
					if (child?.childrens) {
						return {
							value: child?.id,
							label: child?.name,
							childrens: child?.childrens?.map(grandChild => {
								return {
									value: grandChild?.id,
									label: grandChild?.name,
								}
							}),
						}
					}

					return {
						value: child?.id,
						label: child?.name,
					}
				}),
			}
		}
		return {
			value: branch?.id,
			label: branch?.name,
		}
	})

	// FETCHING PRODUCTS
	React.useEffect(() => {
		Loading({ topBar: true, topBarColor: '#377DFF' })
		API.get(`/api/warehouses?branch_id=${selectedBranch || ''}`).then(res => {
			Loading()
			setDataSource(res.data.payload)
			dispatch(fetchedProducts(res.data.payload))
			console.log(res)
		})
		dispatch(fetchCategories())
	}, [selectedBranch])

	// FETCHING CATEGORIES
	categories.map(category => {
		categoriesOptions.push({
			value: category.category_id,
			label: category.category_name,
		})
	})

	// FILTER BY CATEGORY
	React.useEffect(() => {
		if (filterByCategory !== '') {
			const filteredProducts = products.filter(
				product => product?.product?.category_id === filterByCategory
			)
			setDataSource(filteredProducts)
		} else {
			setDataSource(products)
		}
	}, [filterByCategory])

	// FILTER BY SEARCH STRING
	React.useEffect(() => {
		if (searchStr !== '') {
			const filteredProducts = products?.filter(product =>
				product?.product?.name.toLowerCase().includes(searchStr.toLowerCase())
			)
			setDataSource(filteredProducts)
		} else {
			setDataSource(products)
		}
	}, [searchStr, selectedBranch])

	// TABLE DATA RENDER
	const tableData = []

	dataSource &&
		dataSource?.map(product => {
			tableData.push({
				id: product?.id,
				name: (
					<div className='flex gap-x-6 items-center'>
						{window.innerWidth > 500 ? (
							<Zoom>
								<img
									src={
										product?.product?.image !== null
											? product?.product?.image
											: 'https://news.ykt.ru/images/no-image.jpg'
									}
									width='50'
									height={50}
									className='rounded-5 shadow object-contain'
									alt=''
									style={{
										maxWidth: '50px',
										maxHeight: '50px',
									}}
								/>
							</Zoom>
						) : null}
						<div className='flex flex-col'>
							<div className='font-medium text-sm text-gray-txt-color capitalize'>
								{product?.product?.name}
							</div>
							<div className='text-xs text-gray-txt-color font-light capitalize'>
								{product?.product?.brand}
							</div>
						</div>
					</div>
				),
				price:
					product?.product?.price !== null
						? product?.product?.price.toLocaleString() + ' UZS'
						: '',
				count: product?.count?.toLocaleString(),
				date:
					product?.date !== null
						? moment(product?.date).format('DD-MM-YYYY')
						: '--/--/--',
			})
		})

	// TABLE HEADERS
	let columns = [
		{
			key: 'id',
			title: <b>Все товары</b>,
			dataIndex: 'name',
			width: '40%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Количество</span>,
			dataIndex: 'count',
			width: '20%',
		},
		{
			key: 'id',
			title: <span className='text-table-gray'>Время заказа</span>,
			dataIndex: 'date',
			width: '20%',
		},
	]

	// RESPOSIVE TABLE HEADERS
	if (window.innerWidth < 768) {
		columns = [
			{
				...columns[0],
				width: '80%',
			},
			{
				...columns[1],
				width: '20%',
			},
		]
	}

	// getTotalSum
	const getTotalSum = () => {
		let totalSumArr = []
		dataSource?.map((item, index) => {
			if (item?.count !== 0 && item?.product?.price !== 0) {
				totalSumArr.push(item?.count * item?.product?.price)
			}
		})
		return totalSumArr?.reduce((prev, current) => {
			return prev + current
		}, 0)
	}

	// MAIN RENDER
	return (
		<div className='w-full'>
			<Heading title='Все товары' />
			{/*  SECTION  */}
			<div
				className={'px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto'}
				style={{ minHeight: '80vh', maxHeight: '100vh' }}
			>
				{/* SECTION HEADING */}
				<div
					className={
						'gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between'
					}
				>
					<div className='flex flex-wrap gap-4'>
						<TreeSelect
							value={selectedBranch}
							defaultValue={selectedBranch}
							dropdownStyle={{
								maxHeight: 400,
								overflow: 'auto',
							}}
							placeholder='Выберите Область'
							className='form-control__multiselect w-326 sm:w-full'
							onChange={e => {
								setSelectedBranch(e)
							}}
						>
							{[
								{
									value: 'all',
									label: 'Выберите Область',
								},
								...branchesOptions,
							]?.map(branch => {
								if (branch?.childrens) {
									return (
										<TreeNode
											value={branch.value}
											title={branch.label}
											key={branch.value}
										>
											{branch.childrens.map(child => (
												<TreeNode
													value={child.value}
													title={child.label}
													key={child.value}
												/>
											))}
										</TreeNode>
									)
								}
								return (
									<TreeNode
										value={branch.value}
										title={branch.label}
										key={branch.value}
										disabled={branch.value === 'all'}
									/>
								)
							})}
						</TreeSelect>
						<SearchInput
							placeholder={'Поиск по названию'}
							width={'w-326 sm:w-full'}
							search={search}
							onChange={setSearchStr}
							setSearch={setSearch}
							debounceTimeOut={800}
							prefix={<ion-icon name='search-outline'></ion-icon>}
						/>
					</div>
					<div className='flex flex-wrap gap-4 sm:w-full'>
						<div className='px-4 py-2 rounded-md sm:w-full bg-background-color color-gray-txt-color'>
							<b>Общая стоимость:</b> {getTotalSum()?.toLocaleString()} UZS
						</div>
					</div>
				</div>
				{/*  SECTION BODY  */}
				<div className={'mt-6'}>
					<Skeleton loading={loading} active>
						<Table
							rowKey={'key'}
							columns={columns}
							dataSource={tableData}
							loading={loading}
							pagination={false}
						/>
					</Skeleton>
				</div>
			</div>
		</div>
	)
}

export default Products
