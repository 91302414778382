import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../assets/api/api";
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  fetchedCategories,
  fetchingCategories,
  updateCategory,
} from "../../redux/categoriesSlice";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import SearchInput from "../../UI/SearchInput";

import { Loading } from "react-loading-ui";
import { InputNumber, message, Pagination, Popconfirm, Select, Skeleton, Table } from "antd";
import { logout } from "../../redux/loginSlice";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal";
import { useState } from "react";

import _debounce from "lodash/debounce";
const Coupons = () => {
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = React.useState("");
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [uploading, setUploading] = React.useState(false);
  const [newDataSource, setNewDataSource] = React.useState([]);
  const [categoriesData, setCategoriesData] = React.useState(categories);
  const [refresh, setRefresh] = useState(false);
  const [per_page, setPerPage] = useState(0);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [prefixes, setPrefixes] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  // DELETE ITEM
  const deleteItem = (id) => {
    API.delete(`/api/coupons/${id}`)
      .then(() => {
        setRefresh(!refresh);
        message.success("Выполнено успешно!");
      })
      .catch(() => {
        message.error("Произошла ошибка! Попробуйте еще раз!");
      })
      .finally(() => {
        setModalIsOpen(false);
      });
  };

  // MODAL WINDOW CONTENT
  const content = () => {
    return (
      <table className="table w-full">
        <thead>
          <tr className="bg-background-color">
            <td className="py-2 bg-grey-100 px-2 font-medium">Искусствен?</td>
            <td className="py-2 bg-grey-100 px-2 font-medium">Промо-код</td>
            <td className="py-2 bg-grey-100 px-2 font-medium">Дата</td>
            <td className="py-2 bg-grey-100 px-2 font-medium">Удалить</td>
          </tr>
        </thead>
        <tbody>
          { selectedCategory?.coupons?.map((item) => (
            <tr key={ item?.id }>

              <td className="color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                { item?.is_artificial ? (
                  <span className="text-xl w-full flex pl-6 text-green-clr">
                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                  </span>
                ) : (
                  <span className="text-xl w-full flex pl-6 text-red-clr">
                    <ion-icon name="close-circle-outline"></ion-icon>
                  </span>

                ) }
              </td>
              <td className="color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                { item?.coupon }
              </td>
              <td className="color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2">
                { item?.created_at }
              </td>
              <td align="center" className="py-2">
                <Popconfirm
                  title="Вы уверены, что хотите удалить этот товар?"
                  onConfirm={ () => deleteItem(item?.id) }
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button
                    mode={ "icon" }
                    text={ <ion-icon name="trash-outline"></ion-icon> }
                  />
                </Popconfirm>
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    );
  };

  // SUBMIT
  const handleSubmit = () => { };

  // MODAL WINDOW FOOTER
  const footer = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          loading={ uploading }
          onClick={ () => {
            handleSubmit();
          } }
          text={ "Cохранить" }
        />
        <Button
          onClick={ () => {
            setModalIsOpen(false);
          } }
          text={ "Отмена" }
        />
      </div>
    );
  };

  // FETCHING CATEGORIES
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    setLoading(true);
    API.get(`/api/coupons?page=${current_page}&per_page=${per_page}`)
      .then((res) => {
        setCategoriesData(res.data.payload.data);
        setCategories(res.data.payload.data);
        setPerPage(res.data.payload.per_page);
        setLastPage(res.data.payload.last_page);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(logout());
          navigate("/login", { replace: true });
        }
      })
      .finally(() => {
        Loading();
        setLoading(false);
      });
  }, [refresh, current_page, selectedYear, per_page]);

  // FETCHING PREFIXES
  React.useEffect(() => {
    API.get("/api/coupons/prefixes").then((res) => {
      setPrefixes(res.data.payload);
    });
  }, []);

  // DELETE CATEGORY
  const handleDelete = (id) => {
    API.delete(`/api/gifts/${id}`)
      .then((res) => {
        message.success("подарок успешно удалена");
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    setCategoriesData(categories);
  }, [categories]);

  // SEARCH
  React.useEffect(() => {
    if (searchStr.length > 0) {
      const newDataSource = categories.filter((category) => {
        return category.name.toLowerCase().includes(searchStr.toLowerCase());
      });
      setCategoriesData(newDataSource);
    } else {
      setCategoriesData(categories);
    }
  }, [searchStr]);

  const dataSource = [];

  categoriesData?.forEach((category) => {
    dataSource.push({
      id: category.id,
      name: (
        <div className="flex flex-col text-gray-txt-color">
          <p className="font-medium -mb-1">{ category?.user?.name }</p>
          <span>{ category?.user?.phone?.toLocaleString() }</span>
        </div>
      ),
      branch: category?.branch?.name,
      actions: (
        <div className="flex gap-x-2">
          <Button
            className={ "bg-yellow-500 text-white" }
            mode="icon"
            text={ <ion-icon name="list-outline"></ion-icon> }
            onClick={ () => {
              setModalIsOpen(true);
              setSelectedCategory(category);
            } }
          />
        </div>
      ),
    });
  });

  const columns = [
    {
      key: "id",
      title: <b>Клиент</b>,
      dataIndex: "name",
      width: "80%",
    },
    {
      key: "id",
      title: <b>Филиал</b>,
      dataIndex: "branch",
      width: "20%",
    },
    {
      key: "actions",
      title: <span className="text-table-gray">Действия</span>,
      dataIndex: "actions",
      width: "20%",
    },
  ];

  const deboundeFnc = useCallback(
    _debounce((value) => {
      setPerPage(value);
    }, 500),
    []
  );

  const onChangeFunc = (e) => {
    deboundeFnc(e);
  };



  return (
    <div className="w-full">
      { modalIsOpen && (
        <Modal
          onClose={ () => setModalIsOpen(false) }
          title={
            modalType === "add" ? "Добавить подарок" : "Редактировать подарок"
          }
          content={ () => content() }
          footer={ () => footer() }
        />
      ) }
      <Heading title={ "Купоны" } />
      {/*  SECTION  */ }
      <div
        className={ "px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto" }
        style={ { minHeight: "80vh", maxHeight: "100vh" } }
      >
        {/* SECTION HEADING */ }
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between"
          }
        >
          <Select
            onChange={ (e) => {
              setSelectedYear(e);
            } }
            className="form-control__multiselect sm:w-full w-326"
            value={ selectedYear }
          >
            <Select.Option value={ "" }>Все</Select.Option>
            { prefixes?.map((item) => (
              <Select.Option key={ item.id } value={ item.id }>
                { item.prefix_coupon }
              </Select.Option>
            )) }
          </Select>
          <div></div>
        </div>
        {/*  SECTION BODY  */ }
        <div className={ "mt-6 w-full" }>
          <Skeleton loading={ loading } active>
            <center>
              { last_page !== 1 ? (
                <div className="flex gap-x-4 justify-center">
                  <Pagination
                    pageSize={ per_page ? per_page : 40 }
                    total={ last_page * per_page }
                    current={ current_page }
                    onChange={ (page, x) => {
                      setCurrentPage(page)
                      setPerPage(x)
                    } }
                  />
                  <InputNumber
                    onChange={ e => onChangeFunc(e) }
                    value={ per_page }
                  />
                </div>
              ) : null }
            </center>
            <br /><br />
            <Table
              rowKey={ (record) => record.id }
              dataSource={ dataSource }
              columns={ columns }
              pagination={ false }
            />
            <br />
            <br />
            <center>
              { last_page !== 1 ? (
                <div className="flex gap-x-4 justify-center">
                  <Pagination
                    pageSize={ per_page ? per_page : 40 }
                    total={ last_page * per_page }
                    current={ current_page }
                    onChange={ (page, x) => {
                      setCurrentPage(page)
                      setPerPage(x)
                    } }
                  />
                  <InputNumber
                    onChange={ e => onChangeFunc(e) }
                    value={ per_page }
                  />
                </div>
              ) : null }
            </center>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default Coupons;
