export const newSidebarMenus = [
	{
		title: 'ГЛАВНОЕ',
		label: 'Главное меню',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='grid-outline'
			></ion-icon>
		),
		path: '/',
		roles: [],
	},

	{
		label: 'Товары',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='cart-outline'
			></ion-icon>
		),
		path: '/products',
		subitems: [
			{
				label: 'Категории',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='layers-outline'
					></ion-icon>
				),
				path: '/categories',
				roles: ['ceo'],
			},
			{
				label: 'Продукты',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='cart-outline'
					></ion-icon>
				),
				path: '/products',
				roles: ['ceo'],
			},

			{
				label: 'Склад',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='server-outline'
					></ion-icon>
				),
				path: '/warehouse',
				roles: ['ceo', 'super-admin', 'admin'],
			},
			{
				label: 'Комбинация',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='extension-puzzle-outline'
					></ion-icon>
				),
				path: '/combo',
				roles: ['ceo','super-admin'],
			},
		],
	},
	{
		label: 'Переменные цены',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='pricetags-outline'
			></ion-icon>
		),
		path: '/products',
		subitems: [
			{
				label: 'Текущие цены',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='cash-outline'
					></ion-icon>
				),
				path: '/custom-prices',
			},
			{
				label: 'Создать новые цены',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='options-outline'
					></ion-icon>
				),
				path: '/custom-prices/create',
			},
		],
		suffix: 'новый',
		disabled: false,
	},
	{
		label: 'Клиенты',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='people-outline'
			></ion-icon>
		),
		path: '/clients',
		roles: ['ceo', 'super-admin', 'admin'],
	},

	{
		label: 'Филиал',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='business-outline'
			></ion-icon>
		),
		path: '/filials',
		roles: ['ceo', 'super-admin'],
	},
	{
		label: 'Долги',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='receipt-outline'
			></ion-icon>
		),
		path: '/debt',
		roles: ['ceo', 'super-admin', 'admin'],
	},

	{
		label: 'Сотрудники',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='walk-outline'
			></ion-icon>
		),
		path: '/clients',
		roles: ['ceo', 'super-admin'],
		subitems: [
			{
				label: 'Менеджер склада',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='git-pull-request-outline'
					></ion-icon>
				),
				path: '/warehouse-manager',
				roles: ['ceo'],
			},
			{
				label: 'Генеральный заведующий складом',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='git-network-outline'
					></ion-icon>
				),
				path: '/super-warehouse-manager',
				roles: ['ceo'],
			},
			{
				label: 'Aдмин',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='shield-outline'
					></ion-icon>
				),
				path: '/admins',
				roles: ['ceo'],
			},
			{
				label: 'Ассистент',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='id-card-outline'
					></ion-icon>
				),
				path: '/assistants',
				roles: ['ceo', 'super-admin'],
			},
			{
				label: 'Доставщик',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='rocket-outline'
					></ion-icon>
				),
				path: '/supervisors',
				roles: ['ceo', 'super-admin'],
			},
			{
				label: 'Супер админ',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='shield-checkmark-outline'
					></ion-icon>
				),
				path: '/super-admins',
				roles: ['ceo'],
			},
		],
	},

	{
		label: 'Подарки и купоны',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='ribbon-outline'
			></ion-icon>
		),
		path: '/coupons/actions',
		roles: ['ceo', 'super-admin'],
		subitems: [
			{
				label: 'Подарок',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='gift-outline'
					></ion-icon>
				),
				path: '/gift',
				roles: ['ceo', 'super-admin'],
			},
			{
				label: 'Запросы на подарки',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='star-outline'
					></ion-icon>
				),
				path: '/giftReq',
				roles: ['ceo', 'super-admin'],
			},
			{
				label: 'Купоны',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='ticket-outline'
					></ion-icon>
				),
				path: '/coupons',
				roles: ['ceo', 'super-admin'],
			},
			{
				label: 'Действия с купонами',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='accessibility-outline'
					></ion-icon>
				),
				path: '/coupons/actions',
				roles: ['ceo', 'super-admin'],
			},
		],
	},
	{
		label: 'Истории',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='time-outline'
			></ion-icon>
		),
		path: '/coupons/actions',
		roles: ['ceo', 'super-admin', 'admin'],
		subitems: [
			{
				label: 'История склада',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='reader-outline'
					></ion-icon>
				),
				path: '/warehouse/history',
				roles: ['ceo', 'super-admin', 'admin'],
			},
			{
				label: 'История заказов',
				icon: color => (
					<ion-icon
						style={{ color: color, fontSize: '22px' }}
						name='file-tray-full-outline'
					></ion-icon>
				),
				path: '/orders__history',
				roles: ['ceo', 'super-admin', 'admin'],
			},
		],
	},
	{
		label: 'Статистика',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name='trending-up-outline'
			></ion-icon>
		),
		path: '/statistics',
		roles: ['ceo', 'super-admin', 'admin'],
	},{
		label: 'Настройки',
		icon: color => (
			<ion-icon
				style={{ color: color, fontSize: '22px' }}
				name="settings-outline"
			></ion-icon>
		),
		path: '/settings',
		division: true,
		roles: ['ceo'],
		subitems: [{
			label: 'Профиль',
			icon: color => (
			<ion-icon style={{ color: color, fontSize: '22px' }} name="person-circle-outline"></ion-icon>
			),
			path: '/profile',
			roles: ['ceo', 'super-admin', 'admin'],
		}]
	}
]
