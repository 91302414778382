import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import rootReducer from './reducer/rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import 'antd/dist/antd.css'
import { createRoot } from 'react-dom/client'
import { ProSidebarProvider } from 'react-pro-sidebar'

console.error = () => {}
console.warn = () => {}
// console.log = () => {}
// console.info = () => { }

const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV !== 'production',
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<Provider store={store}>
		<ProSidebarProvider>
			<BrowserRouter>
				<Routes>
					<Route path='/*' element={<App />} />
				</Routes>
			</BrowserRouter>
		</ProSidebarProvider>
	</Provider>
)
