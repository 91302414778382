import API from '../../assets/api/api'
export const downloadXLS = ({
  setDownloading,
  from,
  to,
  combo,
  merge,
  assistant_id,
  supervisor_id,
  branch_id,
}) => {
  setDownloading(true)
  API.get(
    `/api/orders/export?from=${from}&to=${to}&merge=${
      merge === true ? 1 : 0
    }&is_combo=${
      combo === true ? 1 : 0
    }&assistant_id=${assistant_id}&supervisor_id=${supervisor_id}&branch_id=${branch_id}`,
    {
      headers: {
        'Content-Type': 'blob',
      },
      responseType: 'arraybuffer',
    }
  )
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', `${Date.now()}.xlsx`)
      document.body.appendChild(link)
      link.click()

      link.remove()
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => setDownloading(false))
}
