import { createSlice } from "@reduxjs/toolkit";

const clientsSlice = createSlice({
    name: "clients",
    initialState: {
        clients: [],
        loading: false,
     },
    reducers: {
        fetchingClients: (state, action) => {
            state.loading = true;
        },
        fetchedClients: (state, action) => {
            state.clients = action.payload;
            state.loading = false;
        },
        fetchingClientsError: (state, action) => {
            state.loading = false;
        },
        deleteClient: (state, action) => {
            state.clients = state.clients.filter(client => client.id !== action.payload);
        }
    }
});


const clientsReducer = clientsSlice.reducer;
export const { fetchingClients, fetchedClients, fetchingClientsError, deleteClient } = clientsSlice.actions;
export default clientsReducer;

