import moment from "moment";
import API from "../../assets/api/api";

export const downloadXLS = ({ branch_id, assistant_id, completed, merge, from = moment(new Date()).format("YYYY-MM-DD"), to = moment(new Date()).format("YYYY-MM-DD"), setDownloading }) => {
  setDownloading(true)
  API.get(
    `/api/gifts/export/requests?from=${from}&to=${to}&given=${completed}&employee_id=${assistant_id}`, {
    headers: {
      "Content-Type": "blob"
    },
    responseType: "arraybuffer"
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute(
        'download',
        `${Date.now()}.xlsx`,
      );

      document.body.appendChild(link);
      link.click();

      link.remove();
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => setDownloading(false))
}