import SidebarMenuItem from './SidebarMenuItem'
import { Sidebar, Menu } from 'react-pro-sidebar'
import { newSidebarMenus } from '../../assets/Data/newSidebarData'
import logo from '../../assets/images/logo.svg'
import RoleRequirment from '../../utils/RoleRequirment/RoleRequirment'

const SidebarMenuContainer = ({ setSidebarOpen, sidebarOpen }) => {
	return (
		<Sidebar
			className='w-full border-none'
			width='250px'
			collapsedWidth='250px'
		>
			<div className={'w-full flex items-center justify-center mb-4'}>
				<img className={'w-52'} src={logo} alt='' />
			</div>
			<Menu iconShape='rounded'>
				{newSidebarMenus.map(item => (
					<RoleRequirment allowedRoles={item.roles}>
						<SidebarMenuItem item={item} />
					</RoleRequirment>
				))}
			</Menu>
		</Sidebar>
	)
}
export default SidebarMenuContainer
