import React from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../assets/api/api";
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  fetchedCategories,
  fetchingCategories,
  updateCategory,
} from "../../redux/categoriesSlice";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import SearchInput from "../../UI/SearchInput";

import { Loading } from "react-loading-ui";
import { message, Pagination, Popconfirm, Skeleton, Spin, Table } from "antd";
import { logout } from "../../redux/loginSlice";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal";
import { useState } from "react";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axios from "axios";

const Gift = () => {
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = React.useState("");
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [newCategory, setNewCategory] = React.useState({
    name: modalType === "add" ? "" : selectedCategory.name,
    point: modalType === "add" ? null : selectedCategory?.point,
    image: modalType === "add" ? "" : selectedCategory?.image
  });
  const [uploading, setUploading] = React.useState(false);
  const [newDataSource, setNewDataSource] = React.useState([]);
  const [categoriesData, setCategoriesData] = React.useState(categories);
  const [refresh, setRefresh] = useState(false);
  const [per_page, setPerPage] = useState(0);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [photoUploaded, setPhotoUploaded] = useState(false)

  // SET INPUT VALUE
  React.useEffect(() => {
    if (modalType === "add") {
      setNewCategory({
        name: "",
        point: null,
        image: ""
      });
    }

    if (modalType === "edit") {
      setNewCategory({
        name: selectedCategory.name,
        point: selectedCategory?.point,
        image: selectedCategory?.image
      });
    }
  }, [modalType, selectedCategory]);

  // MODAL WINDOW CONTENT
  const content = () => {
    if (modalType === "gifts") {
      return (
        <div>
          <table>
            <thead className="w-full">
              <tr className="w-full bg-background-color">
                <th className="px-2 py-2 w-full">Название подарок</th>
                <th className="px-2 py-2 w-full">Балл</th>
                <th className="px-2 py-2 w-full">Действия</th>
              </tr>
            </thead>

            <tbody>
              {
                selectedCategory?.gifts?.map((item, index) => (
                  <tr >
                    <td className="cursor-pointer px-2 py-2 border-b border-table-border capitalize flex gap-x-4 items-center">
                      <Zoom className="cursor-pointer hover:opacity-50">
                        <img width={ 40 } height={ 40 } src={ item?.image || "https://www.nbmchealth.com/wp-content/uploads/2018/04/default-placeholder.png" } alt="" />
                      </Zoom>
                      { item?.name }
                    </td>
                    <td className=" px-4 py-2 border-b border-table-border capitalize" align="center">{ item?.point?.toLocaleString() }</td>
                    <td className=" px-4 py-2 border-b border-table-border capitalize" align="center">
                      <div className="flex gap-x-2">
                        <Button
                          onClick={ () => {
                            setModalType("edit")
                            setModalIsOpen(true)
                            setSelectedCategory(item)
                          } }
                          text={ <ion-icon name="create-outline"></ion-icon> } mode="icon" />
                        <Popconfirm
                          title="Вы уверены, что хотите удалить подарок?"
                          onConfirm={ () => handleDelete(item?.id) }
                          okText="Да"
                          cancelText="Нет"
                        >
                          <Button
                            className={ "bg-yellow-500 text-white" }
                            mode="icon"
                            text={ <ion-icon name="trash-outline"></ion-icon> }
                          />
                        </Popconfirm>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      )
    }

    // UPLOAD PHOTO TO CLOUDINARY
    const photoUploader = (e) => {
      setPhotoUploaded(true);

      const formData = new FormData();
      formData.append("file", e);
      formData.append("upload_preset", "vivian");

      axios
        .post(
          "https://api.cloudinary.com/v1_1/http-electro-life-texnopos-site/image/upload",
          formData
        )
        .then((res) => {
          setNewCategory({ ...newCategory, image: res.data.secure_url })
        })
        .finally(() => setPhotoUploaded(false))
    };

    return (
      <div>
        <form
          onSubmit={ (e) => {
            handleSubmit();
            e.preventDefault();
          } }
        >
          <label className="form-label">
            <span className="form-label__text">Название подарок</span>
            <input
              required
              className="form-control"
              type="text"
              placeholder="Название подарок"
              value={ newCategory.name }
              onChange={ (e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
            />
          </label>
          <label className="form-label">
            <span className="form-label__text">Балл</span>
            <input
              required
              className="form-control"
              type="number"
              placeholder="Балл"
              value={ newCategory.point }
              onChange={ (e) =>
                setNewCategory({ ...newCategory, point: e.target.value })
              }
            />
          </label>
          <label className="form-label">
            <span className="form-label__text">Изображение</span>
            <Spin spinning={ photoUploaded }>
              <input
                required
                className="form-control"
                type="file"
                accept="image/*"
                placeholder="Изображение"
                onChange={ (e) =>
                  photoUploader(e.target.files[0])
                }
              />
            </Spin>
          </label>
        </form>
      </div>
    );
  };

  // SUBMIT
  const handleSubmit = () => {
    setUploading(true);
    if (newCategory.name.length > 0) {
      if (modalType === "add") {
        API.post("/api/gifts", newCategory)
          .then((res) => {
            setModalIsOpen(false);
            setUploading(false);
            setNewCategory({
              name: "",
              point: null,
              image: ""
            });
            setRefresh(!refresh);
            message.success("подарок успешно создана");
          })
          .catch((err) => {
            console.log(err);
            setUploading(false);
            if (err.response.data.message === "name already exists") {
              message.error("Это имя подарка было там раньше!");
            }

            if (err.response.data.message === "The point field is required.") {
              message.error("Ввод баллов обязателен!");
            }
          });
      }

      if (modalType === "edit") {
        API.patch(`/api/gifts`, {
          id: selectedCategory.id,
          name: newCategory.name,
          point: newCategory.point,
          image: newCategory?.image
        })
          .then((res) => {
            setModalIsOpen(false);
            setUploading(false);
            setRefresh(!refresh);
            setNewCategory({
              name: "",
              point: null,
              image: ""
            });
            setSelectedCategory({});
            message.success("подарок успешно обновлена");
          })
          .catch((err) => {
            console.log(err);
            setUploading(false);
            message.error("Ошибка при обновлении подарок");
          });
      }
    } else {
      message.error("Введите название подарок");
      setUploading(false);
    }
  };

  // MODAL WINDOW FOOTER
  const footer = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          loading={ uploading }
          onClick={ () => {
            handleSubmit();
          } }
          text={ "Cохранить" }
        />
        <Button
          onClick={ () => {
            setModalIsOpen(false);
          } }
          text={ "Отмена" }
        />
      </div>
    );
  };

  // FETCHING CATEGORIES
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    setLoading(true);
    API.get(`/api/gifts?page=${current_page}`)
      .then((res) => {
        setCategoriesData(res.data.payload);
        setCategories(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(logout());
          navigate("/login", { replace: true });
        }
      })
      .finally(() => {
        Loading();
        setLoading(false);
      });
  }, [refresh, current_page]);

  // DELETE CATEGORY
  const handleDelete = (id) => {
    API.delete(`/api/gifts/${id}`)
      .then((res) => {
        message.success("подарок успешно удалена");
        setRefresh(!refresh);
        setModalIsOpen(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    setCategoriesData(categories);
  }, [categories]);

  // SEARCH
  React.useEffect(() => {
    if (searchStr.length > 0) {
      const newDataSource = categories.filter((category) => {
        return category.name.toLowerCase().includes(searchStr.toLowerCase());
      });
      setCategoriesData(newDataSource);
    } else {
      setCategoriesData(categories);
    }
  }, [searchStr]);

  const dataSource = [];
  console.info(categoriesData)
  categoriesData?.forEach((category) => {
    dataSource.push({
      id: category?.id,
      point: category?.point,
      actions: (
        <div className="flex gap-x-2">
          <Button
            className={ "bg-yellow-500 text-white" }
            onClick={ () => {
              setModalIsOpen(true);
              setModalType("gifts");
              setSelectedCategory(category);
            } }
            mode="icon"
            text={ <ion-icon name="list-outline"></ion-icon> }
          />
        </div>
      ),
    });
  });

  const columns = [
    {
      key: "point",
      title: <b>Балл</b>,
      dataIndex: "point",
      width: "80%",
    },
    {
      key: "actions",
      title: <span className="text-table-gray">Действия</span>,
      dataIndex: "actions",
      width: "20%",
    },
  ];

  return (
    <div className="w-full">
      { modalIsOpen && (
        <Modal
          onClose={ () => setModalIsOpen(false) }
          title={
            modalType === "add" ? "Добавить подарок" : modalType === "edit" ? "Редактировать подарок" : "Подарки"
          }
          content={ () => content() }
          footer={ () => footer() }
        />
      ) }
      <Heading title={ "Подарок" } />
      {/*  SECTION  */ }
      <div
        className={ "px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto" }
        style={ { minHeight: "80vh", maxHeight: "100vh" } }
      >
        {/* SECTION HEADING */ }
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between"
          }
        >
          <SearchInput
            placeholder={ "Поиск по подарок" }
            width={ "w-326 sm:w-full" }
            search={ search }
            setSearch={ setSearch }
            debounceTimeOut={ 800 }
            prefix={ <ion-icon name="search-outline"></ion-icon> }
            onChange={ setSearchStr }
          />

          <Button
            text={ "Добавить подарок" }
            startedIcon={ "plus" }
            width={ "w-320 sm:w-full" }
            onClick={ () => {
              setModalIsOpen(true);
              setModalType("add");
            } }
          />
        </div>
        {/*  SECTION BODY  */ }
        <div className={ "mt-6 w-full" }>
          <Skeleton loading={ loading } active>
            <Table
              rowKey={ (record) => record.id }
              dataSource={ dataSource }
              columns={ columns }
              pagination={ true }
            />
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default Gift;


