import { Link, useLocation } from 'react-router-dom'
import { MenuItem, SubMenu } from 'react-pro-sidebar'
import RoleRequirment from '../../utils/RoleRequirment/RoleRequirment'
import { Badge } from 'antd'
const SidebarMenuItem = ({ item }) => {
	const path = useLocation()
	const currentPath = path.pathname
	return (
		<div className={`${item.division ? "border-t border-gray-150" : ""}`}>
			{item.subitems ? (
				<SubMenu
					className='text-gray-500 '
					title={item.label}
					label={item.label}
					icon={item.icon('#8c8c8c')}
					suffix={
						item.suffix ? <Badge count={item.suffix} size='medium' /> : false
					}
					disabled={item.disabled}
				>
					{item?.subitems?.map(subitem => (
						<RoleRequirment key={subitem.path} allowedRoles={subitem.roles}>
							<MenuItem
								// suffix={<Badge count={'new'} size='small' />}
								className={`${
									currentPath === subitem.path && ' bg-gray-200 '
								} opacity-70 border-b`}
								icon={subitem.icon(
									currentPath === subitem.path ? '#555' : '#8c8c8c'
								)}
								component={<Link to={subitem.path} />}
							>
								{subitem.label}
							</MenuItem>
						</RoleRequirment>
					))}
				</SubMenu>
			) : (
				<Link className={`text-gray-500 `} to={item.path}>
					<MenuItem
						className={`${currentPath === item.path && 'bg-gray-200 '} `}
						icon={item.icon(currentPath === item.path ? '#555' : '#8c8c8c')}
					>
						{item.label}
					</MenuItem>
				</Link>
			)}
		</div>
	)
}
export default SidebarMenuItem
