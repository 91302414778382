import { Popconfirm, Select, Spin } from 'antd';
import React from 'react'
import { useState } from 'react';
import SearchInput from '../../UI/SearchInput';
import { downloadXLS } from './downloadXLS';

const HeadingTop = ({ setSupervisor_id,
  supervisor_id,
  supervisors,
  setAssistant_id,
  assistant_id,
  assistants,
  search,
  setSearch,
  setSearchStr,
  setFilter,
  filter,
  statuses,
  setPoint_type,
  point_type,
  pointTypes,
  max_point1,
  setMax_point1,
  setMax_point,
  min_point1,
  setMin_point1,
  setMin_point,
  setModalIsOpen,
  setModalType,
  Button,
  selectedUsers,
  TreeSelect,
  branch_id,
  setBranch_id,
  branchesOptions,
  setShowReplaceWindow,
  XLSData,
  handleDeleteClient,
  deleting }) => {
  const { TreeNode } = TreeSelect
  const [downloading, setDownloading] = useState(false)

  return (
    <>
      {/* SECTION HEADINGTop */ }
      <div
        className={
          "gap-4 inline-flex items-start flex-wrap mb-8 w-full justify-between sm:flex-col "
        }
      >
        {/* LEFT DETAILS */ }
        <div className="flex gap-x-4 sm:flex-col sm:w-full gap-y-6">
          <div className="w-50 flex flex-col gap-y-6 sm:w-full">
            {/*  BRANCH  */ }
            <div className="inline-flex flex-col sm:w-full">
              <span className="mb-2 text-xs">Филиал</span>
              {/* FILTER BY BRANCHES */ }
              <TreeSelect
                value={ branch_id }
                defaultValue={ branch_id }
                dropdownStyle={ {
                  maxHeight: 400,
                  overflow: "auto",
                } }
                placeholder="Все филиал"
                className="form-control__multiselect w-326 sm:w-full"
                onChange={ (e) => {
                  setBranch_id(e);
                } }
              >
                { branchesOptions.map((branch) => {
                  if (branch.childrens) {
                    return (
                      <TreeNode
                        value={ branch?.value }
                        title={ branch.label }
                        key={ branch.value }
                      >
                        { branch?.childrens?.map((child) => (
                          <TreeNode
                            value={ child.value }
                            title={ child.label }
                            key={ child.value }
                          />
                        )) }
                      </TreeNode>
                    );
                  }
                  return (
                    <TreeNode
                      value={ branch.value }
                      title={ branch.label }
                      key={ branch.value }
                    />
                  );
                }) }
              </TreeSelect>
            </div>

            {/*  SUPERVISOR  */ }
            {/* <div className="inline-flex flex-col">
              <span className="mb-2 text-xs">Руководитель</span>
              <Select
                className="form-control__multiselect w-326 sm:w-full"
                onChange={ (value) => {
                  setSupervisor_id(value);
                } }
                placeholder={ "Все руководитель" }
                defaultValue={ supervisor_id }
              >
                { [
                  {
                    id: "",
                    name: "Все руководитель",
                  },
                  ...supervisors,
                ]?.map((supervisor, index) => (
                  <Select.Option value={ supervisor?.id } key={ index }>
                    { supervisor?.name }
                  </Select.Option>
                )) }
              </Select>
            </div> */}

            {/*  ASSISTANT  */ }
            <div className="inline-flex flex-col">
              <span className="mb-2 text-xs">Помощник</span>
              <Select
                className="form-control__multiselect w-326 sm:w-full"
                onChange={ (value) => {
                  setAssistant_id(value);
                } }
                placeholder={ "Выберите помощника" }
                defaultValue={ assistant_id }
              >
                { [
                  {
                    id: "",
                    name: "Все помощники",
                  },
                  {
                    id: "noAssistant",
                    name: "Без ассистента"
                  },
                  ...assistants,
                ]?.map((supervisor, index) => (
                  <Select.Option value={ supervisor?.id } key={ index }>
                    { supervisor?.name }
                  </Select.Option>
                )) }
              </Select>
            </div>
          </div>

          <div className="w-50 flex flex-col gap-y-6">
            {/*  NAME  */ }
            <div className="inline-flex flex-col">
              <span className="mb-2 text-xs">
                Имя клиента или номер телефона
              </span>
              <SearchInput
                search={ search }
                setSearch={ setSearch }
                placeholder="Имя клиента или номер телефона"
                width={ "w-326 sm:w-full" }
                debounceTimeOut={ 800 }
                onChange={ setSearchStr }
              />
            </div>

            {/*  STATUS  */ }
            <div className="inline-flex flex-col">
              <span className="mb-2 text-xs">Статус клента</span>

              <Select
                className="form-control__multiselect w-326 sm:w-full"
                onChange={ (value) => {
                  setFilter({ ...filter, status_id: value });
                } }
                placeholder={ "Статус клента" }
                defaultValue={ filter?.status_id }
              >
                { statuses?.map((status, index) => (
                  <Select.Option value={ status?.value } key={ index }>
                    { status?.label }
                  </Select.Option>
                )) }
              </Select>
            </div>
          </div>
        </div>

        {/* RIGHT DETAILS */ }
        <div className="flex flex-col gap-y-6 sm:w-full">
          {/*  BALL  */ }
          <div className="inline-flex flex-col">
            <span className="mb-2 text-xs">Балл</span>

            <Select
              placeholder="Выберите балл"
              onChange={ (e) => {
                setPoint_type(e);
              } }
              defaultValue={ point_type }
              className="form-control__multiselect w-326 sm:w-full"
            >
              { pointTypes?.map((pointType) => (
                <Select.Option value={ pointType?.value }>
                  { pointType?.label }
                </Select.Option>
              )) }
            </Select>
          </div>

          {/* BALL FROM */ }
          <div className="flex flex-col">
            <span className="mb-2 text-xs">Балл от</span>

            <SearchInput
              search={ max_point1 }
              setSearch={ setMax_point1 }
              placeholder="Балл от"
              width={ "form-control" }
              debounceTimeOut={ 800 }
              onChange={ setMax_point }
            />
          </div>

          {/* BALL TO */ }
          <div className="flex flex-col">
            <span className="mb-2 text-xs "> Балл до</span>
            <SearchInput
              search={ min_point1 }
              setSearch={ setMin_point1 }
              placeholder=" Балл до"
              width={ "form-control w-30%" }
              debounceTimeOut={ 800 }
              onChange={ setMin_point }
            />
          </div>

          {/*  UPDATE BUTTON  */ }

          <div className="flex gap-x-4 sm:w-full  justify-end sm:flex-wrap">
            {
              localStorage.getItem("vivian_role") === "ceo" ? (
                <div className="inline-flex flex-col">
                  <Button
                    text={ "Заканчивается год" }
                    onClick={ () => {
                      setModalIsOpen(true);
                      setModalType("end_year");
                    } }
                  />
                </div>

              ) : null
            }
            <Button
              text={ "Заменить" }
              disabled={ selectedUsers.length === 0 }
              onClick={ () => {
                setShowReplaceWindow(true);
              } }
            />
            <Spin spinning={ downloading }>
              <Button
                text={ "Скачать XLS" }
                onClick={ () => {
                  const data = {
                    branch_id,
                    assistant_id,
                    searchStr: search,
                    status_id: filter?.status_id,
                    point_type,
                    min_point: min_point1,
                    max_point: max_point1,
                    downloading,
                    setDownloading
                  }
                  downloadXLS(data)
                } }
              />
            </Spin>

          </div>
          {
            selectedUsers.length === 0 ? (null) : (
              <Popconfirm
                title="Вы хотите удалить клиента?!"
                onConfirm={ () => {
                  handleDeleteClient()
                } }
                okText="Да"
                cancelText="Нет"
              >
                <Spin spinning={ deleting }>
                  <Button
                    text={ "Удалить" }
                  />
                </Spin>
              </Popconfirm>

            )
          }
        </div>
      </div>
    </>
  )
}

export default HeadingTop