import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import API from "../../assets/api/api";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import { InputNumber, message, Pagination, Popconfirm, Skeleton, Spin, Table, TreeSelect } from "antd";
import { Loading } from "react-loading-ui";
import { setComboPageType, setSelectedData } from "../../redux/productsSlice";
import "react-medium-image-zoom/dist/styles.css";
import Modal from "../../UI/Modal";
import { useEffect } from "react";
const ComboView = () => {
  // MUST BE INITIALIZED
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const [dataSource, setDataSource] = React.useState(products);
  const [branches, setBranches] = React.useState([]);
  const categoriesOptions = [
    {
      value: "",
      label: "Все категории",
    },
  ];
  const [selectedItem, setSelectedItem] = React.useState({});
  const [showOrdersModal, setShowOrdersModal] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [comboItems, setComboItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [last_page, setLastPage] = useState(0)
  const [per_page, setPerPage] = useState(40)
  const [currentPage, setCurrentPage] = useState(1)
  // fetching branches
  useEffect(() => {
    API.get(`/api/branches/${localStorage.getItem("vivian_role") !== "ceo" ? "children" : ''}`).then((res) => {
      if (localStorage.getItem('vivian_role') === "ceo") {
        setBranches(res.data.payload);
      } else {
        setBranches([res.data.payload]);
      }
    });

  }, []);

  // FETCHING PRODUCTS
  React.useEffect(() => {
    setloading(true);
    Loading({ topBar: true, topBarColor: "#377DFF" });
    API.get(`/api/combos`)
      .then((res) => {
        setComboItems(res.data.payload.data);
      })
      .finally(() => {
        setloading(false);
        Loading();
      });
  }, [refresh]);

  // deleteProduct
  const deleteProduct = (type, id) => {
    setDeleting(true);
    if (type === "item") {
      API.delete(`/api/combos/${selectedItem?.id}/${id}`)
        .then((res) => {
          setRefresh(!refresh);
          message.success("Товар удален из комбинации!");
        })
        .catch(() => {
          message.error("Произошла ошибка. Пожалуйста, попробуйте еще раз!");
        })
        .finally(() => {
          setShowOrdersModal(false);
          setDeleting(false);
        });
    }

    if (type === "combo") {
      API.delete(`/api/combos/${id}`)
        .then((res) => {
          setRefresh(!refresh);
          message.success("Комбинация успешно удалена!!");
        })
        .catch(() => {
          message.error("Произошла ошибка. Пожалуйста, попробуйте еще раз!");
        })
        .finally(() => {
          setShowOrdersModal(false);
          setDeleting(false);
        });
    }
  };

  // TABLE DATA RENDER
  const tableData = [];

  comboItems &&
    comboItems.length &&
    comboItems?.map((item) => {
      tableData.push({
        id: item.id,
        name: item.name,
        price: (
          <>
            { item?.price?.toLocaleString() }
            <b>UZS</b>
          </>
        ),
        point: item.point?.toLocaleString(),
        delete: (
          <div className="flex gap-2">
            <Button
              mode={ "icon" }
              text={ <ion-icon name="add-circle-outline"></ion-icon> }
              onClick={ () => {
                dispatch(setSelectedData(item));
                dispatch(setComboPageType("update"));
              } }
            />
            <Popconfirm
              title="Удалить комбинацию??"
              onConfirm={ () => deleteProduct("combo", item?.id) }
              okText="Да"
              cancelText="Нет"
            >
              <Button
                mode={ "icon" }
                text={ <ion-icon name="trash-outline"></ion-icon> }
              />
            </Popconfirm>
          </div>
        ),
      });
    });

  // TABLE HEADERS
  let columns = [
    {
      key: "id",
      title: <b>Название комбинации</b>,
      dataIndex: "name",
      width: "40%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Комбинированная цена</span>,
      dataIndex: "price",
      width: "10%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Балл</span>,
      dataIndex: "point",
      width: "10%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Удалить</span>,
      dataIndex: "delete",
      width: "10%",
    },
  ];


  // get total sum and ball
  const getTotals = (item) => {
    console.info(item)
    const allSum = []
    const allBall = []
    let totalSum = 0
    let totalBall = 0
    item.products.map((item) => {
      allSum.push(item.price)
      allBall.push(item.point)
    })
    totalBall = (Math.max(...allBall) * item.limit)
    totalSum = (Math.max(...allSum) * item.limit)
    return { totalBall, totalSum }
  }

  // MAIN RENDER
  return (
    <div className="w-full">
      { showOrdersModal ? (
        <Modal
          title={ selectedItem?.name }
          content={ () => {
            return (
              <>
                <div className="table w-full">
                  { selectedItem?.items?.map((item) => (
                    <>
                      <div className="w-full mb-4 bg-background-color px-2 py-2 rounded-md shadow-md">
                        <div className="flex w-full justify-between">
                          <div className="flex flex-col">
                            <span><b className="text-gray-txt-color">Лимит:</b> { item?.limit }</span>
                            <span><b className="text-gray-txt-color">Сумма:</b> { getTotals(item)?.totalSum?.toLocaleString() } UZS</span>
                            <span><b className="text-gray-txt-color">Балл:</b> { getTotals(item)?.totalBall?.toLocaleString() }</span>
                          </div>
                          <Button
                            mode={ "icon" }
                            text={ <ion-icon name="trash-outline"></ion-icon> }
                            onClick={ () => deleteProduct("item", item?.item_id) }
                            loading={ deleting }
                          />
                        </div>
                        <div className="w-full">
                          { item?.products?.map((item2) => (
                            <li
                              key={ item2?.id }
                              className="flex justify-between w-full border-b border-table-border"
                            >
                              <span className="color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2 ">
                                { item2?.name }
                              </span>
                            </li>
                          )) }
                        </div>
                      </div>
                    </>
                  )) }
                </div>
              </>
            );
          } }
          footer={ false }
          onClose={ () => setShowOrdersModal(false) }
        />
      ) : null }
      <Heading title="Комбинации" />
      {/*  SECTION  */ }
      <div
        className={ "px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto" }
        style={ { minHeight: "80vh", maxHeight: "100vh" } }
      >
        {/* SECTION HEADING */ }
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between"
          }
        >
          <div className="w-full flex flex-wrap gap-4 justify-between">
            <div></div>
            <Button
              text={ "Создать комбинацию" }
              onClick={ () => dispatch(setComboPageType("create")) }
            />
          </div>
        </div>
        {/*  SECTION BODY  */ }
        <div className={ "mt-6 w-full" }>
          { tableData?.length !== 0 ? (
            <Skeleton loading={ loading } active>
              <Table
                rowKey={ "key" }
                columns={ columns }
                dataSource={ tableData }
                pagination={ false }
                loading={ loading }
                rowClassName={ (record, index) => {
                  return "cursor-pointer";
                } }
                onRow={ (record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      console.info(event.target.classList);
                      if (
                        event.target.classList[0] !== "font-semibold" &&
                        event.target.classList[0] !== "md" &&
                        event.target.classList[0] &&
                        event.target.classList[0] !== ""
                      ) {
                        setSelectedOrderId(record.id);
                        setSelectedItem(
                          comboItems.find((x) => x.id === record.id)
                        );
                        setShowOrdersModal(true);
                      }
                    }, // click row
                  };
                } }
              />
              <center>
                <br /><br />
                { last_page !== 1 ? (
                  <div className="flex gap-x-4 justify-center">
                    <Pagination
                      pageSize={ per_page ? per_page : 40 }
                      total={ last_page * per_page }
                      current={ currentPage }
                      onChange={ (page, x) => {
                        setCurrentPage(page)
                        setPerPage(x)
                      } }
                    />
                  </div>
                ) : null }
              </center>
            </Skeleton>
          ) : (
            <center>
              <h1>Даты не найдены!</h1>
            </center>
          ) }
        </div>
      </div>
    </div>
  );
};

export default ComboView;
