import React from "react";
import { useDrag } from "react-dnd/dist/hooks";
const Card = ({ item }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={ drag }
      className="px-2 py-2 rounded-lg shadow-sm flex gap-x-4 items-center cursor-pointer select-none justify-between"
    >
      <div className="flex items-center gap-x-4 text-xs">
        <img
          src={
            item?.image !== null
              ? item?.image
              : "https://www.yiwubazaar.com/resources/assets/images/default-product.jpg"
          }
          width={ 40 }
          height={ 40 }
          className="rounded sm:hidden text-xs"
          style={ { maxWidth: "50px", maxHeight: "50px", objectFit: "contain" } }
        />
        { item?.name }
      </div>
      <div>
        <span className="text-xs flex flex-col">
          <span><b>{ item?.price?.toLocaleString() }</b> <>sum</></span>
          <span><b>{ item?.point?.toLocaleString() }</b> <>ball</></span>
        </span>
      </div>
    </div>
  );
};

export default Card;
