import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import API from '../../assets/api/api'

import Heading from '../../UI/Heading'

import { Loading } from 'react-loading-ui'
import { message, Popconfirm, Select, Table, TreeSelect } from 'antd'
import { logout } from '../../redux/loginSlice'
import { useNavigate } from 'react-router-dom'
import Modal from '../../UI/Modal'
import Dropdown from '../../UI/Dropdown'
import _debounce from 'lodash/debounce'
import axios from 'axios'
import ReactHtmlTableToExcel from 'react-html-table-to-excel'
import moment from 'moment'
import { useEffect } from 'react'

const { TreeNode } = TreeSelect

const Statistics = () => {
	const [search, setSearch] = React.useState('')
	const dispatch = useDispatch()
	const [searchStr, setSearchStr] = React.useState('')
	const navigate = useNavigate()

	const [branch_id, setBranch_id] = React.useState(
		localStorage.getItem('myBranch') || ''
	)
	// BRANCHES
	const [branches, setBranches] = React.useState([
		{
			value: '',
			label: 'Все филиалы',
		},
	])

	// ASSISTANTS
	const [assistants, setAssistants] = React.useState([
		{
			value: '',
			label: 'Все ассистенты',
		},
	])

	// SUPERVISORS
	const [supervisors, setSupervisors] = React.useState([
		{
			value: '',
			label: 'Все Руководитель',
		},
	])

	// SELECTED ITEMS
	const [point_type, setPoint_type] = React.useState('')
	const [statistics, setStatistics] = React.useState([])
	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')
	const [statisticaType, setStatisticaType] = useState('assistant')
	const [loading, setLoading] = useState(false)
	const [supervisor_id, setSupervisor_id] = useState('')
	const [user_id, setUserId] = useState('')
	const [assistant_id, setAssistant_id] = useState('')
	const [selectedBranch, setSelectedBranch] = useState('')
	// FETCHING BRANCHES
	React.useEffect(() => {
		API.get(
			`/api/branches/${
				localStorage.getItem('vivian_role') !== 'ceo' ? 'children' : ''
			}`
		).then(res => {
			if (localStorage.getItem('vivian_role') === 'ceo') {
				setBranches(res.data.payload)
			} else {
				setBranches([res.data.payload])
			}
		})

		API.get(
			`/api/employees/?role=supervisor&${
				branch_id !== '' ? `branch_id=${branch_id}` : ''
			}`
		).then(res => {
			setSupervisors(res?.data?.payload)
		})
		API.get(
			`/api/employees/?role=assistant&${
				branch_id !== '' ? `branch_id=${branch_id}` : ''
			}`
		).then(res => {
			setAssistants(res?.data?.payload)
		})
	}, [branch_id])

	// BRANCHES OPTIONS
	const branchesOptions = branches.map(branch => {
		if (branch.childrens) {
			return {
				value: branch.id,
				label: branch.name,
				childrens: branch.childrens.map(child => {
					if (child.childrens) {
						return {
							value: child.id,
							label: child.name,
							childrens: child.childrens.map(grandChild => {
								return {
									value: grandChild.id,
									label: grandChild.name,
								}
							}),
						}
					}

					return {
						value: child.id,
						label: child.name,
					}
				}),
			}
		}
		return {
			value: branch.id,
			label: branch.name,
		}
	})

	// FETCHING CATEGORIES
	React.useEffect(() => {
		Loading({ topBar: true, topBarColor: '#377DFF' })
		setLoading(true)

		if (statisticaType === 'products') {
			API.get(
				`/api/statistics/products?
        from=${from}
        &to=${to}
        &supervisor_id=${supervisor_id}
        &assistant_id=${assistant_id}
        &branch_id=${branch_id}`
			)
				.then(res => {
					setStatistics(res.data.payload)
				})
				.catch(err => {
					console.info(err)
				})
				.finally(() => {
					Loading()
					setLoading(false)
				})
		} else {
			API.get(
				`/api/statistics${
					statisticaType === 'supervisor'
						? ''
						: statisticaType === 'branches'
						? '/branches'
						: '/assistant'
				}?branch_id=${branch_id}&from=${from}&to=${to}`
			)
				.then(res => {
					setStatistics(res.data.payload)
				})
				.catch(err => {
					console.info(err)
				})
				.finally(() => {
					Loading()
					setLoading(false)
				})
		}
	}, [branch_id, from, to, statisticaType, supervisor_id, assistant_id])

	console.info(statistics)

	// GET TOTAL SUMM
	let currentUserDatas = []
	const getTotal = (type, id) => {
		if (statisticaType === 'supervisor') {
			currentUserDatas = []
			statistics?.map((item, index) => {
				currentUserDatas.push(item?.data?.find(x => x?.supervisor_id === id))
			})
		}

		if (statisticaType === 'branches') {
			currentUserDatas = []
			statistics?.map((item, index) => {
				const data = item?.data?.find(x => x?.branch_id === id)
				if (data !== undefined) {
					currentUserDatas.push(data)
				}
			})
		}

		if (statisticaType === 'assistant') {
			currentUserDatas = []
			statistics?.map((item, index) => {
				const data = item?.data?.find(x => x?.assistant_id == id)
				if (data !== undefined) {
					currentUserDatas.push(data)
				}
			})
		}

		if (type === 'debt') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.debt?.card + item?.debt?.cash)
			})
			return totalSums?.reduce((prev, curr) => {
				return prev + curr
			}, 0)
		}

		if (type === 'ass_debt' && statisticaType === 'assistant') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.debt)
			})
			return totalSums?.reduce((prev, curr) => {
				return prev + curr
			}, 0)
		}

		if (type === 'order') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.basket_count)
			})
			return totalSums?.reduce((prev, curr) => {
				return prev + curr
			}, 0)
		}

		if (type === 'total') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.card + item?.cash)
			})
			return totalSums?.reduce((prev, curr) => {
				return Number(prev + curr)
			}, 0)
		}

		if (type === 'card') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.card)
			})
			return totalSums?.reduce((prev, curr) => {
				return prev + curr
			}, 0)
		}

		if (type === 'cash') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.cash)
			})
			return totalSums?.reduce((prev, curr) => {
				return prev + curr
			}, 0)
		}

		if (type === 'vaucher') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.voucher_point)
			})
			return totalSums?.reduce((prev, curr) => {
				return prev + curr
			}, 0)
		}

		if (type === 'user_count') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.user_count)
			})
			return totalSums?.reduce((prev, curr) => {
				return prev + curr
			}, 0)
		}

		if (type === 'total_ass_debt' && statisticaType === 'assistant') {
			const totalSums = []
			currentUserDatas?.forEach((item, index) => {
				totalSums.push(item?.paid_debt)
			})
			return totalSums?.reduce((prev, curr) => {
				return prev + curr
			}, 0)
		}
	}

	return (
		<div className='w-full'>
			<Heading title={'Статистика'} />
			{/*  SECTION  */}
			<div
				className={'px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto'}
				style={{ minHeight: '80vh', maxHeight: '100vh' }}
			>
				{/* SECTION HEADING */}
				<div
					className={
						'gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between sm:flex-col '
					}
				>
					{/* LEFT DETAILS */}
					<div className='flex gap-x-4 sm:flex-col sm:w-full gap-y-6'>
						<div className='w-50 flex flex-col gap-y-6 sm:w-full'>
							{/*  BRANCH  */}
							<div className='inline-flex flex-col sm:w-full'>
								<span className='mb-2 text-xs'>Филиал</span>
								{/* FILTER BY BRANCHES */}
								<TreeSelect
									value={branch_id}
									defaultValue={branch_id}
									dropdownStyle={{
										maxHeight: 400,
										overflow: 'auto',
									}}
									placeholder='Все филиал'
									className='form-control__multiselect w-326 sm:w-full'
									onChange={(e, label) => {
										setBranch_id(e)
										setAssistant_id('')
										setSupervisor_id('')
										setSelectedBranch(label[0])
									}}
								>
									{[
										{
											value: '',
											label: 'Все филиалы',
										},
										...branchesOptions,
									].map(branch => {
										if (branch.childrens) {
											return (
												<TreeNode
													value={branch?.value}
													title={branch.label}
													key={branch.value}
												>
													{branch?.childrens?.map(child => (
														<TreeNode
															value={child.value}
															title={child.label}
															key={child.value}
														/>
													))}
												</TreeNode>
											)
										}
										return (
											<TreeNode
												value={branch.value}
												title={branch.label}
												key={branch.value}
											/>
										)
									})}
								</TreeSelect>
							</div>
						</div>

						<div className='w-50 flex  gap-y-6 gap-x-4 sm:flex-col md:flex-wrap justify-start'>
							{/*  NAME  */}
							<div className='inline-flex flex-col'>
								<span className='mb-2 text-xs'>От времени</span>
								<input
									type='date'
									className='form-control sm:w-full'
									onChange={e => setFrom(e.target.value)}
								/>
							</div>
							<div className='inline-flex flex-col'>
								<span className='mb-2 text-xs'>До времени</span>
								<input
									type='date'
									className='form-control sm:w-full'
									onChange={e => setTo(e.target.value)}
								/>
							</div>
							<div className='inline-flex flex-col'>
								<span className='mb-2 text-xs'>Тип статистики</span>
								<Select
									onChange={e => {
										setStatisticaType(e)
									}}
									className='form-control__multiselect w-326 sm:w-full'
									value={statisticaType}
								>
									<Select.Option value='supervisor'>
										Статистика супервайзера
									</Select.Option>
									<Select.Option value='branches'>
										Статистика филиала
									</Select.Option>
									<Select.Option value='products'>
										Статистика продукта
									</Select.Option>
									<Select.Option value='assistant'>
										Ассистент статистика
									</Select.Option>
								</Select>
							</div>
						</div>
					</div>
					<div className='w-50 flex  gap-y-6 gap-x-4 sm:flex-col md:flex-wrap justify-start items-end'>
						{statisticaType === 'products' ? (
							<>
								<div className='inline-flex flex-col'>
									<span className='mb-2 text-xs'>Доставщик</span>
									<Select
										onChange={e => {
											setSupervisor_id(e)
										}}
										className='form-control__multiselect w-326 sm:w-full'
										value={supervisor_id}
										showSearch
										filterOption={(input, option) =>
											option.children
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										placeholder={'Супервайзер не выбран'}
									>
										{[
											{
												value: '',
												name: 'Все Руководитель',
											},
											...supervisors,
										]?.map(item => (
											<Select.Option value={item?.id}>
												{item?.name}
											</Select.Option>
										))}
									</Select>
								</div>
								<div className='inline-flex flex-col'>
									<span className='mb-2 text-xs'>Ассистент</span>
									<Select
										onChange={e => {
											setAssistant_id(e)
										}}
										className='form-control__multiselect w-326 sm:w-full'
										value={assistant_id}
										showSearch
										filterOption={(input, option) =>
											option.children
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										placeholder={'Ассистент не выбран'}
									>
										{[
											{
												value: '',
												name: 'Все ассистенты',
											},
											...assistants,
										]?.map(item => (
											<Select.Option value={item?.id}>
												{item?.name}
											</Select.Option>
										))}
									</Select>
								</div>
							</>
						) : null}
						{/*  UPDATE BUTTON  */}
						<ReactHtmlTableToExcel
							className='font-semibold
                bg-primary-color
                text-white flex 
                items-center gap-2 
                rounded-5 py-2
                px-4 
                opacity-90
                hover:opacity-100
                disabled:opacity-50
                disabled:cursor-not-allowed
                sm:w-full
                '
							table='table-to-xls'
							filename='tablexls'
							sheet='tablexls'
							buttonText='Download as XLS'
						/>
					</div>

					{/* RIGHT DETAILS */}
					<div className='flex sm:flex-col gap-y-6 sm:w-full'></div>
				</div>
				{/*  SECTION BODY  */}
				<div className={'mt-6 w-full overflow-auto'}>
					{statisticaType !== 'products' ? (
						<>
							{/* SUPERVISORS STATISTICS */}
							<div>
								{!loading ? (
									<table
										border='1'
										cellspasing='1'
										cellpadding='1'
										className='shadow-md w-full overflow-auto color-gray-txt-color'
										style={{ minWidth: '1000px' }}
										id='table-to-xls'
									>
										{statistics?.map((item, index) => {
											if (index === 0) {
												return (
													<>
														<tr align='center'>
															<td
																className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'
																rowspan='2'
															>
																№
															</td>
															{item?.data?.map((user, userIndex) => (
																<td
																	className='px-2 py-2 border-b border-table-border whitespace-nowrap	 border-r bg-background-color border-t'
																	colspan={
																		statisticaType === 'assistant'
																			? '8'
																			: statisticaType === 'supervisor'
																			? '7'
																			: '6'
																	}
																>
																	{statisticaType === 'supervisor'
																		? user?.supervisor_name
																		: user?.branch_name
																		? user?.branch_name
																		: user?.assistant_name}
																</td>
															))}
														</tr>
														<tr align='center'>
															{item?.data?.map((user, userIndex) => (
																<>
																	<td className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color'>
																		<b>Заявки</b>
																	</td>
																	<td className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color'>
																		<b>Клиенти</b>
																	</td>
																	<td className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color'>
																		<b>Общая сумма</b>
																	</td>
																	<td className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color'>
																		<b>Наличные</b>
																	</td>
																	<td className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color'>
																		<b>Пластиковая карта</b>
																	</td>
																	{(statisticaType === 'supervisor' ||
																		statisticaType === 'assistant') && (
																		<td className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color'>
																			<b>Долг</b>
																		</td>
																	)}
																	{statisticaType === 'assistant' && (
																		<td className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color'>
																			<b>Оплаченный долг</b>
																		</td>
																	)}
																	<td className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color'>
																		<b>Для ваучера</b>
																	</td>
																</>
															))}
														</tr>
														<tr align='center'>
															{item?.data?.map((user, userIndex) => (
																<>
																	{userIndex === 0 ? (
																		<td
																			className='sticky left-0 bg-background-color shadow-md border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l '
																			style={{ minWidth: '100px' }}
																		>
																			{item?.date}
																		</td>
																	) : null}
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	'>
																		{user?.basket_count?.toLocaleString()}{' '}
																	</td>
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	'>
																		{user?.user_count?.toLocaleString()}{' '}
																	</td>
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	'>
																		{(
																			user?.card + user?.cash
																		)?.toLocaleString()}{' '}
																		<b> </b>
																	</td>
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	'>
																		{user?.cash?.toLocaleString()} <b> </b>
																	</td>
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 '>
																		{user?.card?.toLocaleString()} <b> </b>
																	</td>
																	{(statisticaType === 'supervisor' ||
																		statisticaType === 'assistant') && (
																		<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 '>
																			{statisticaType === 'supervisor' ? (
																				<>
																					<b>Kарта:</b>{' '}
																					{user?.debt?.card?.toLocaleString()}{' '}
																					<b></b> <br />
																					<b>Наличные:</b>{' '}
																					{user?.debt?.cash?.toLocaleString()}{' '}
																					<b></b>
																				</>
																			) : (
																				<>
																					{user?.debt?.toLocaleString()} <b></b>
																				</>
																			)}
																		</td>
																	)}
																	{statisticaType === 'assistant' && (
																		<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 '>
																			{
																				<>
																					<b>Общий:</b>{' '}
																					{user?.paid_debt?.toLocaleString()}{' '}
																					<b></b> <br />
																					<b>Kарта:</b>{' '}
																					{user?.paid_debt_card?.toLocaleString()}{' '}
																					<b></b> <br />
																					<b>Наличные:</b>{' '}
																					{user?.paid_debt_cash?.toLocaleString()}{' '}
																					<b></b>
																				</>
																			}
																		</td>
																	)}
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	border-r'>
																		{user?.voucher_point?.toLocaleString()}
																	</td>
																</>
															))}
														</tr>
													</>
												)
											} else {
												return (
													<>
														<tr align='center'>
															{item?.data?.map((user, userIndex) => (
																<>
																	{userIndex === 0 ? (
																		<td className='sticky left-0 bg-background-color shadow-md border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'>
																			{item?.date}
																		</td>
																	) : null}
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	'>
																		{user?.basket_count?.toLocaleString()}
																	</td>
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	'>
																		{user?.user_count?.toLocaleString()}
																	</td>
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	'>
																		{(
																			user?.card + user?.cash
																		)?.toLocaleString()}
																		<b> </b>
																	</td>
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	'>
																		{user?.cash?.toLocaleString()}
																		<b> </b>
																	</td>
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 '>
																		{user?.card?.toLocaleString()} <b> </b>
																	</td>
																	{(statisticaType === 'supervisor' ||
																		statisticaType === 'assistant') && (
																		<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 '>
																			{statisticaType === 'supervisor' ? (
																				<>
																					<b>Kарта:</b>{' '}
																					{user?.debt?.card?.toLocaleString()}{' '}
																					<b></b> <br />
																					<b>Наличные:</b>{' '}
																					{user?.debt?.cash?.toLocaleString()}{' '}
																					<b></b>
																				</>
																			) : (
																				<>
																					{user?.debt?.toLocaleString()} <b></b>
																				</>
																			)}
																		</td>
																	)}
																	{statisticaType === 'assistant' && (
																		<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 '>
																			{
																				<>
																					<b>Общий:</b>{' '}
																					{user?.paid_debt?.toLocaleString()}{' '}
																					<b></b> <br />
																					<b>Kарта:</b>{' '}
																					{user?.paid_debt_card?.toLocaleString()}{' '}
																					<b></b> <br />
																					<b>Наличные:</b>{' '}
																					{user?.paid_debt_cash?.toLocaleString()}{' '}
																					<b></b>
																				</>
																			}
																		</td>
																	)}
																	<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	border-r'>
																		{user?.voucher_point}
																	</td>
																	{/* <td className="border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r">
                                      { user?.debt?.card?.toLocaleString() }  <br />
                                      { user?.debt?.cash?.toLocaleString() } 
                                    </td> */}
																</>
															))}
														</tr>
													</>
												)
											}
										})}
										<tr align='center'>
											{statistics[0]?.data?.map((x, y) => {
												return (
													<>
														{y === 0 ? (
															<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	  border-r border-l'>
																<b>Итого</b>
															</td>
														) : null}
														<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	 '>
															{getTotal(
																'order',
																x?.supervisor_id
																	? x?.supervisor_id
																	: x?.branch_id
																	? x?.branch_id
																	: x?.assistant_id
															)?.toLocaleString()}
														</td>
														<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	 '>
															{getTotal(
																'user_count',
																x?.supervisor_id
																	? x?.supervisor_id
																	: x?.branch_id
																	? x?.branch_id
																	: x?.assistant_id
															)?.toLocaleString()}
														</td>
														<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	 '>
															{statisticaType === 'assistant'
																? (
																		getTotal(
																			`${'total_ass_debt'}`,
																			x?.assistant_id
																		) +
																		getTotal(
																			'total',
																			x?.supervisor_id
																				? x?.supervisor_id
																				: x?.branch_id
																				? x?.branch_id
																				: x?.assistant_id
																		)
																  )?.toLocaleString()
																: getTotal(
																		'total',
																		x?.supervisor_id
																			? x?.supervisor_id
																			: x?.branch_id
																			? x?.branch_id
																			: x?.assistant_id
																  )?.toLocaleString()}
															<b> </b>
														</td>
														<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	 '>
															{getTotal(
																'cash',
																x?.supervisor_id
																	? x?.supervisor_id
																	: x?.branch_id
																	? x?.branch_id
																	: x?.assistant_id
															)?.toLocaleString()}
															<b> </b>
														</td>
														<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	 '>
															{getTotal(
																'card',
																x?.supervisor_id
																	? x?.supervisor_id
																	: x?.branch_id
																	? x?.branch_id
																	: x?.assistant_id
															)?.toLocaleString()}
															<b> </b>
														</td>
														{(statisticaType === 'supervisor' ||
															statisticaType === 'assistant') && (
															<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	 '>
																{getTotal(
																	`${
																		statisticaType === 'supervisor'
																			? 'debt'
																			: 'ass_debt'
																	}`,
																	x?.supervisor_id
																		? x?.supervisor_id
																		: x?.branch_id
																		? x?.branch_id
																		: x?.assistant_id
																)?.toLocaleString()}
																<b> </b>
															</td>
														)}
														{statisticaType === 'assistant' && (
															<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	 '>
																{getTotal(
																	`${'total_ass_debt'}`,
																	x?.assistant_id
																)?.toLocaleString()}
																<b> </b>
															</td>
														)}
														<td className='border-b bg-background-color py-2 px-2 border-table-border whitespace-nowrap	  border-r'>
															{getTotal(
																'vaucher',
																x?.supervisor_id
																	? x?.supervisor_id
																	: x?.branch_id
																	? x?.branch_id
																	: x?.assistant_id
															)?.toLocaleString()}
														</td>
													</>
												)
											})}
										</tr>
									</table>
								) : (
									<div className={'w-full flex items-center justify-center'}>
										<h3 className='text-gray-txt-color'>
											Ожидайте загрузки данных...
										</h3>
									</div>
								)}

								{statistics?.length === 0 ? (
									<center>
										<p className='text-gray-txt-color'>Данные не найдены!</p>
									</center>
								) : null}
							</div>
						</>
					) : (
						<>
							{!loading ? (
								<table
									border='1'
									cellspasing='1'
									cellpadding='1'
									className='shadow-md w-full overflow-auto color-gray-txt-color'
									style={{ minWidth: '1000px' }}
								>
									<thead>
										<th className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'>
											№
										</th>
										<th className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'>
											Название товара
										</th>
										<th className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'>
											Продано количество
										</th>
									</thead>
									<tbody>
										{statistics?.map((item, index) => {
											return (
												<>
													{item?.count !== 0 ? (
														<tr key={item?.position}>
															<td
																align='center'
																className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'
															>
																{item?.position}
															</td>
															<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'>
																{item?.name}
															</td>
															<td
																align='center'
																className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'
															>
																{item?.count}
															</td>
														</tr>
													) : null}
												</>
											)
										})}
									</tbody>
								</table>
							) : (
								<div className={'w-full flex items-center justify-center'}>
									<h3 className='text-gray-txt-color'>
										Ожидайте загрузки данных...
									</h3>
								</div>
							)}

							{statistics?.length === 0 ? (
								<center>
									<p className='text-gray-txt-color'>Данные не найдены!</p>
								</center>
							) : null}

							<table
								id='table-to-xls'
								border='1'
								cellspasing='1'
								cellpadding='1'
								className='shadow-md w-full overflow-auto color-gray-txt-color hidden'
								style={{ minWidth: '1000px' }}
							>
								<tr>
									<td style={{ width: '60px', backgroundColor: 'yellow' }}></td>
									<td
										style={{
											width: '600px',
											verticalAlign: 'middle',
											backgroundColor: 'yellow',
										}}
										className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'
									>
										<b>Супервайзер: </b>
										{supervisor_id
											? `${
													supervisors?.find(item => item?.id === supervisor_id)
														?.name
											  }`
											: 'Не выбран'}{' '}
										<br />
										<b>Ассистент: </b>
										{assistant_id
											? `${
													assistants?.find(item => item?.id === assistant_id)
														?.name
											  }`
											: 'Не выбран'}{' '}
										<br />
										<b>Филиал: </b>
										{selectedBranch ? ` ${selectedBranch}` : 'Не выбран'}
									</td>
									<td
										style={{
											width: '250px',
											verticalAlign: 'middle',
											backgroundColor: 'yellow',
										}}
									>
										<b>С даты:</b>{' '}
										{from ? `${from}` : moment(Date.now()).format('DD/MM/YYYY')}{' '}
										<br />
										<b>До даты:</b>{' '}
										{to ? ` ${to}` : moment(Date.now()).format('DD/MM/YYYY')}
									</td>
								</tr>

								<thead>
									<tr>
										<th
											style={{ width: '60px' }}
											className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'
										>
											№
										</th>
										<th
											style={{ width: '500px', height: '36px' }}
											className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'
										>
											Название товара
										</th>
										<th
											style={{ width: '250px' }}
											className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'
										>
											Продано количество
										</th>
									</tr>
								</thead>
								<tbody>
									{statistics?.map((item, index) => {
										return (
											<>
												<tr key={item?.position}>
													<td
														style={{ verticalAlign: 'middle', height: '30px' }}
														align='center'
														className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'
													>
														{item?.position}
													</td>
													<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'>
														{item?.name}
													</td>
													<td
														align='center'
														className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'
													>
														{item?.count}
													</td>
												</tr>
											</>
										)
									})}
								</tbody>
							</table>
						</>
					)}

					{/* PRODUCTS STATISTICS */}
				</div>
			</div>
		</div>
	)
}

export default Statistics
