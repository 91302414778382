import React, { useState } from "react";
import Heading from "../../UI/Heading";
import Day from "../../UI/Day";
import Dropdown from "../../UI/Dropdown";
import { dropdownData } from "../../assets/Data/AnyDatas";
import Table from "../../UI/Table";
import { activeDeActive } from "../../assets/Data/AnyDatas";

const TodaysOrders = () => {
  const [selected, setSelected] = useState("");

  let dataSource = [];

  activeDeActive.map((item) => {
    dataSource.push({
      avatar: item.avatar,
      name: item.name,
      ball: item.ball,
      role: item.role,
      status: (
        <span
          className={`px-3 py-1 text-xs text-white shadow rounded-full ${
            item.status === "active" ? "bg-light-green" : "bg-yellow"
          }`}
        >
          {item.status === "active" ? "Актив" : "Неактив"}
        </span>
      ),
    });
  });

  const columns = [
    {
      label: "User",
      data: "avatar",
    },
    {
      label: "Name",
      data: "name",
    },
    {
      label: "Ball",
      data: "ball",
    },
    {
      label: "Role",
      data: "role",
    },
    {
      label: "Status",
      data: "status",
    },
  ];

  return (
    <div className={"w-full"}>
      <Heading title={"Сегодняшние заказы"} />

      {/*  SECTION  */}
      <div
        className={"px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto"}
        style={{ minHeight: "80vh", maxHeight: "100vh" }}
      >
        {/* SECTION HEADING */}
        <div>
          <div className={"gap-4 inline-flex w-28 items-center mb-8"}>
            {/*<Heading title={'Заказы'} />*/}
            <h2 className={"font-medium text-txt-color text-lg"}>Заказы</h2>
            <Day day={"23"} />
          </div>

          {/*  Dropdown  */}
          <Dropdown
            search={true}
            placeholder={"Филиал"}
            data={dropdownData}
            setSelected={setSelected}
            selected={selected}
            width={"w-80"}
          />
        </div>
        {/*  SECTION BODY  */}
        <div className={"mt-6"}>
          <Table columns={columns} dataSource={dataSource} />
        </div>
      </div>
    </div>
  );
};

export default TodaysOrders;
