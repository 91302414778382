import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import API from '../../assets/api/api'
import {
	fetchingClients,
	fetchedClients,
	deleteClient,
} from '../../redux/clientsSlice'
import Button from '../../UI/Button'
import Heading from '../../UI/Heading'
import { Loading } from 'react-loading-ui'
import {
	Checkbox,
	Form,
	Input,
	InputNumber,
	message,
	Pagination,
	Popconfirm,
	Select,
	Spin,
	Table,
	TreeSelect,
	DatePicker,
	Dropdown,
} from 'antd'
import { logout } from '../../redux/loginSlice'
import { useNavigate } from 'react-router-dom'
import Modal from '../../UI/Modal'
import _debounce from 'lodash/debounce'
import { useEffect } from 'react'
import HeadingTop from './Heading'
import DropdownItem from '../../UI/DropdownItem'

const { TreeNode } = TreeSelect

const Clients = () => {
	const [search, setSearch] = React.useState('')
	const dispatch = useDispatch()
	const { clients, loading } = useSelector(state => state.clients)
	const [searchStr, setSearchStr] = React.useState('')
	const navigate = useNavigate()
	const [modalIsOpen, setModalIsOpen] = React.useState(false)
	const [modalType, setModalType] = React.useState('')
	const [selectedCategory, setSelectedCategory] = React.useState({})
	const [newClient, setNewClient] = React.useState({
		id: 3,
		branch_id: 2,
		name: '',
		phone: '',
		assistant_id: 1,
		address: '',
	})
	const [uploading, setUploading] = React.useState(false)
	const [clientsData, setClientsData] = React.useState(clients)
	const [refresh, setRefresh] = useState(false)
	// FILTER STATES
	const [filter, setFilter] = React.useState({
		name: '',
		phone: '',
		branch_id: '',
		assistant_id: '',
		status_id: '',
		point: '',
		supervisor_id: '',
		point_from: '',
		point_to: '',
		search,
	})

	const [branch_id, setBranch_id] = React.useState(
		localStorage.getItem('myBranch') || ''
	)
	const [supervisor_id, setSupervisor_id] = React.useState('')
	const [assistant_id, setAssistant_id] = React.useState('')
	// BRANCHES
	const [branches, setBranches] = React.useState([
		{
			value: '',
			label: 'Все филиалы',
		},
	])

	// ASSISTANTS
	const [assistants, setAssistants] = React.useState([
		{
			value: '',
			label: 'Все ассистенты',
		},
	])

	// SUPERVISORS
	const [supervisors, setSupervisors] = React.useState([
		{
			value: '',
			label: 'Все Руководитель',
		},
	])

	// STATUSES
	const [statuses, setStatuses] = React.useState([
		{
			value: '',
			label: 'Все статусы',
		},
		{
			value: 'green',
			label: (
				<span className='color'>
					<div className='green'></div>
					Зеленый
				</span>
			),
		},
		{
			value: 'yellow',
			label: (
				<span className='color'>
					<span className='yellow'></span>
					Желтый
				</span>
			),
		},
		{
			value: 'red',
			label: (
				<span className='color'>
					<div className='red'></div>
					Красный
				</span>
			),
		},
		{
			value: 'cyan',
			label: (
				<span className='color'>
					<div className='cyan'></div>
					Голубой
				</span>
			),
		},
		{
			value: 'dark gray',
			label: (
				<span className='color'>
					<div className='grey'></div>
					Тёмно-серый
				</span>
			),
		},
	])
	// SELECTED ITEMS
	const [selectedItems, setSelectedItems] = React.useState([])
	const [point_type, setPoint_type] = React.useState('')

	const [min_point, setMin_point] = React.useState('')
	const [max_point, setMax_point] = React.useState('')
	const [min_point1, setMin_point1] = React.useState('')
	const [max_point1, setMax_point1] = React.useState('')

	const [per_page, setPerPage] = useState(40)
	const [last_page, setLastPage] = useState()
	const [currentPage, setCurrentPage] = useState(1)

	const [selectedUsers, setSelectedUsers] = useState([])
	const [fullSelectedPages, setFullSelectedPages] = useState([])

	const [showReplaceWindow, setShowReplaceWindow] = useState(false)
	const [windowEmployees, setWindowEmployees] = useState([])
	const [dataForReplaceAssistant, setDataReplaceAssistant] = useState({
		assistant_id: null,
		user_ids: selectedUsers,
	})
	const [point, setPoint] = useState(null)
	const [allPoint, setAllPoint] = useState(null)
	const [prefix, setPrefix] = useState('')
	const [gifts, setGifts] = useState([])
	const [giftId, setGiftId] = useState('')
	const [giftCount, setGiftCount] = useState(0)
	const [giftBall, setGiftBall] = useState()
	const [userBasket, setUserBasket] = useState({})
	const [loadingBaskets, setLoadingBaskets] = useState(false)
	const [basket_curr, setBasketCurr] = useState(1)
	const [basket_per, setBasketPer] = useState(0)
	const [basket_last, setBasketLast] = useState(0)
	const [couponBall, setCouponBall] = useState(0)
	const [currentPerPage, setCurrentPerPage] = useState(per_page)
	const [scrollTop, setScrollTop] = useState(false)
	const [statisticsFromTo, setStatisticsFromTo] = useState({
		from: '',
		to: '',
	})
	const [userStat, setUserStat] = useState([])
	const [loadingStat, setLoadingStat] = useState(false)
	const [XLSData, setXLSData] = useState('')
	const [fromTo, setFromTo] = useState({
		from: '',
		to: '',
	})

	const [selectedBasketItem, setSelectedBasketItem] = useState({})
	const [productsModalIsOpen, setProductsModalIsOpen] = useState(false)
	const [statisticBasketPageSize, setStatisticBasketPageSize] = useState(10)

	const [deleting, setDeleting] = useState(false)
	const [addCouponCount, setAddCouponCount] = useState(1)
	const [refreshBasket, setRefreshBasket] = useState(false)
	// FETCHING BASKETS
	React.useEffect(() => {
		if (modalType === 'baskets') {
			setLoadingBaskets(true)
			API.get(
				`/api/orders/histories?user_id=${selectedCategory?.id}
        &page=${basket_curr}
        &from=${fromTo.from}
        &to=${fromTo?.to}
        `
			)
				.then(res => {
					setUserBasket(res.data.payload)
					setBasketPer(res.data.payload.per_page)
					setBasketLast(res.data.payload.last_page)
				})
				.catch(() => {
					message.error('Произошла ошибка!')
					setModalIsOpen(false)
				})
				.finally(() => setLoadingBaskets(false))
		}
		setStatisticsFromTo({ from: '', to: '' })
	}, [
		modalIsOpen,
		modalType,
		basket_curr,
		fromTo.from,
		fromTo.to,
		refreshBasket,
	])

	// GET USER STATISTICS
	React.useEffect(() => {
		setLoadingStat(true)
		if (modalIsOpen && modalType === 'statistics') {
			API.get(
				`/api/statistics/products?from=${statisticsFromTo?.from}&to=${statisticsFromTo?.to}&user_id=${selectedCategory?.id}`
			)
				.then(res => {
					setUserStat(res.data.payload)
				})
				.catch(() => {
					message.error('Произлошло ошибка!')
					setModalIsOpen(false)
					setModalType('')
					setSelectedCategory({})
				})
				.finally(() => setLoadingStat(false))
		}
	}, [modalType, selectedCategory, statisticsFromTo.from, statisticsFromTo.to])

	const pointTypes = [
		{
			label: 'Все баллы',
			value: '',
		},
		{
			label: 'Текущий бал',
			value: 'point',
		},
		{
			label: 'Всего баллов',
			value: 'total_point',
		},
	]

	// BRANCHES OPTIONS
	const branchesOptions = branches.map(branch => {
		if (branch.childrens) {
			return {
				value: branch.id,
				label: branch.name,
				childrens: branch.childrens.map(child => {
					if (child.childrens) {
						return {
							value: child.id,
							label: child.name,
							childrens: child.childrens.map(grandChild => {
								return {
									value: grandChild.id,
									label: grandChild.name,
								}
							}),
						}
					}

					return {
						value: child.id,
						label: child.name,
					}
				}),
			}
		}
		return {
			value: branch.id,
			label: branch.name,
		}
	})

	// SET INPUT VALUE
	React.useEffect(() => {
		setNewClient({
			id: selectedCategory?.id,
			branch_id: selectedCategory?.branch?.id,
			name: selectedCategory?.name,
			phone: selectedCategory?.phone,
			assistant_id: selectedCategory?.assistant?.id || '',
			address: selectedCategory?.address,
		})
		setGiftId('')
		setGiftBall(0)
		setGiftCount(0)
	}, [modalType, selectedCategory])

	// FETCHING BRANCHES
	React.useEffect(() => {
		API.get(
			`/api/branches/${
				localStorage.getItem('vivian_role') !== 'ceo' ? 'children' : ''
			}`
		).then(res => {
			if (localStorage.getItem('vivian_role') === 'ceo') {
				setBranches(res.data.payload)
			} else {
				setBranches([res.data.payload])
			}
		})
	}, [])

	// FETCHING EMPLOYEE
	React.useEffect(() => {
		API.get(
			`/api/employees?role=supervisor&${
				branch_id !== undefined ? `branch_id=${branch_id}` : ''
			}`
		).then(res => {
			setSupervisors(res.data.payload)
		})
		API.get(
			`/api/employees?role=assistant&${
				branch_id !== undefined ? `branch_id=${branch_id}` : ''
			}`
		).then(res => {
			setAssistants(res.data.payload)
		})
	}, [branch_id])

	// FETCH WINDOW EMPLOYEES
	React.useEffect(() => {
		API.get(
			`/api/employees?role=assistant&${
				branch_id !== undefined ? `branch_id=${branch_id}` : ''
			}`
		).then(res => {
			setWindowEmployees(res.data.payload)
		})
	}, [newClient?.branch_id])

	// FETCH GIFTS
	React.useEffect(() => {
		API.get('/api/gifts').then(res => {
			setGifts(res.data.payload.data)
		})
	}, [])

	// delete basket
	function deleteBasket(id) {
		API.post(`/api/orders/return/${id}`)
			.then(res => {
				setRefreshBasket(!refreshBasket)
				message.success('Корзина успешно удалена!')
			})
			.catch(() => {
				message.error('Вы не можете удалить эту корзину!')
			})
	}

	// MODAL WINDOW CONTENT
	const content = () => {
		if (modalType === 'update') {
			return (
				<div>
					<form
						onSubmit={e => {
							handleSubmit()
							e.preventDefault()
						}}
					>
						<label className='form-label'>
							<span className='form-label__text'>Имя Клиента</span>
							<input
								required
								className='form-control'
								type='text'
								placeholder='Имя Клиента'
								value={newClient.name}
								onChange={e =>
									setNewClient({ ...newClient, name: e.target.value })
								}
							/>
						</label>
						<label className='form-label'>
							<span className='form-label__text'>Выберите филиал</span>
							<TreeSelect
								value={newClient?.branch_id}
								defaultValue={newClient?.branch_id}
								dropdownStyle={{
									maxHeight: 400,
									overflow: 'auto',
								}}
								placeholder='Выберите филиал'
								className='form-control__multiselect  sm:w-full'
								onChange={e => {
									setNewClient({ ...newClient, branch_id: e })
								}}
							>
								{branchesOptions.map(branch => {
									if (branch.childrens) {
										return (
											<TreeNode
												value={branch?.value}
												title={branch.label}
												key={branch.value}
												dispatch
											>
												{branch?.childrens?.map(child => (
													<TreeNode
														value={child.value}
														title={child.label}
														key={child.value}
													/>
												))}
											</TreeNode>
										)
									}
									return (
										<TreeNode
											value={branch.value}
											title={branch.label}
											key={branch.value}
										/>
									)
								})}
							</TreeSelect>
						</label>
						<label className='form-label'>
							<span className='form-label__text'>Выбрать Ассистента</span>
							<Select
								className='form-control__multiselect sm:w-full'
								onChange={value => {
									setNewClient({ ...newClient, assistant_id: value })
								}}
								placeholder={'Выбрать Ассистента'}
								defaultValue={newClient.assistant_id}
							>
								{windowEmployees?.map((supervisor, index) => (
									<Select.Option value={supervisor?.id} key={index}>
										{supervisor?.name}
									</Select.Option>
								))}
							</Select>
						</label>
						<label className='form-label'>
							<span className='form-label__text'>Номер телефона</span>
							<input
								required
								className='form-control'
								type='text'
								placeholder='Номер телефона'
								value={newClient.phone}
								onChange={e =>
									setNewClient({ ...newClient, phone: e.target.value })
								}
							/>
						</label>
						<label className='form-label'>
							<span className='form-label__text'>Напишите адрес</span>
							<input
								required
								className='form-control'
								type='text'
								placeholder='Напишите адрес'
								value={newClient.address}
								onChange={e =>
									setNewClient({ ...newClient, address: e.target.value })
								}
							/>
						</label>
					</form>
				</div>
			)
		}

		if (modalType === 'add_point') {
			return (
				<Form layout='vertical'>
					<Form.Item label=' Текущий счет'>
						<InputNumber
							required
							style={{ width: '100%' }}
							className='w-full form-control border-0'
							type='number'
							placeholder='Текущий счет'
							value={point}
							onChange={e => setPoint(e)}
							prefix={`${selectedCategory?.point}/`}
						/>
					</Form.Item>
					<Form.Item label=' Общий счет'>
						<InputNumber
							required
							style={{ width: '100%' }}
							className='w-full form-control border-0'
							type='number'
							placeholder='Общий счет'
							value={allPoint}
							onChange={e => setAllPoint(e)}
							prefix={`${selectedCategory?.total_point}/`}
						/>
					</Form.Item>
				</Form>
			)
		}

		if (modalType === 'minus_point') {
			return (
				<Form layout='vertical'>
					<Form.Item label=' Текущий счет'>
						<InputNumber
							required
							style={{ width: '100%' }}
							className='w-full form-control border-0'
							max={selectedCategory?.point}
							type='text'
							placeholder='Текущий счет'
							value={point}
							onChange={e => setPoint(e)}
							prefix={`${selectedCategory?.point}/`}
						/>
					</Form.Item>
					<Form.Item label=' Общий счет'>
						<InputNumber
							required
							style={{ width: '100%' }}
							className='w-full form-control border-0'
							max={selectedCategory?.total_point}
							type='text'
							placeholder='Общий счет'
							value={allPoint}
							onChange={e => setAllPoint(e)}
							prefix={`${selectedCategory?.total_point}/`}
						/>
					</Form.Item>
					{/* <Form.Item label=" Купон счет">
            <InputNumber
              required
              style={ { width: "100%" } }
              className="w-full form-control border-0"
              type="text"
              placeholder="Купон счет"
              value={ couponBall }
              onChange={ (e) => {
                setCouponBall(e)
              } }
            />
          </Form.Item> */}
				</Form>
			)
		}

		if (modalType === 'end_year') {
			return (
				<Form layout='vertical'>
					<Form.Item label=' Введите префикс для купона'>
						<Input
							required
							style={{ width: '100%' }}
							className='w-full form-control border-0'
							type='text'
							placeholder='Введите префикс для купона'
							value={prefix}
							onChange={e => setPrefix(e.target.value)}
						/>
					</Form.Item>
				</Form>
			)
		}

		if (modalType === 'coupon') {
			return (
				<table className='table w-full'>
					<thead>
						<tr className='bg-background-color'>
							<td className='py-2 bg-grey-100 px-2 font-medium'>Промо-код</td>
							<td className='py-2 bg-grey-100 px-2 font-medium'>Дата</td>
						</tr>
					</thead>
					<tbody>
						{selectedCategory?.coupons?.map((item, index) => (
							<tr key={index}>
								<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
									{item?.coupon}
								</td>
								<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
									{item?.created_at}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)
		}

		if (modalType === 'gift') {
			return (
				<form>
					<label className='form-label'>
						<span className='form-label__text'>Выбрать подарок</span>
						<Select
							className='form-control__multiselect  sm:w-full'
							value={giftId}
							onChange={e => {
								setGiftId(e)
								const point = gifts?.find(x => x.id === e)?.point
								setGiftBall(point)
							}}
							placeholder='Дарить подарок'
						>
							{gifts?.map((item, index) => {
								if (item.point < selectedCategory?.point) {
									return (
										<Select.Option value={item.id}>
											<span className='w-full flex justify-between capitalize'>
												{item.name} <span>{item.point} балл</span>
											</span>
										</Select.Option>
									)
								}
							})}
						</Select>
					</label>
					{/* 
          {giftId !== "" ? (
            <label className="form-label">
              <span className="form-label__text">Количество подарков!</span>
              <InputNumber
                className="form-control__multiselect  sm:w-full"
                // value={giftCount}
                onChange={(e) => setGiftCount(e.target.value)}
                placeholder="Количество подарков!"
                style={{ width: "100%" }}
              />
            </label>
          ) : null} */}
				</form>
			)
		}

		if (modalType === 'baskets') {
			return (
				<Spin spinning={loadingBaskets}>
					{/* basket heading */}
					<div className='flex items-start gap-x-6'>
						<div>
							<span className='px-2 py-1 bg-background-color rounded-md block mb-2'>
								Наличные
							</span>
							<div>
								<b>Пластик</b>: {userBasket?.data?.card?.toLocaleString()} UZS
							</div>
							<div>
								<b>Наличные</b>: {userBasket?.data?.cash?.toLocaleString()} UZS
							</div>
						</div>

						<div>
							<span className='px-2 py-1 bg-background-color rounded-md block mb-2'>
								Долг
							</span>

							<div className='flex gap-x-2'>
								<div>
									<div>
										<b>Общий</b>:{' '}
										{userBasket?.data?.debt?.debt?.toLocaleString()} UZS
									</div>
									<div>
										<b>Оплаченный</b>:{' '}
										{userBasket?.data?.debt?.paid_debt?.toLocaleString()} UZS
									</div>
								</div>
								<div>
									<div>
										<b>Пластик</b>:{' '}
										{userBasket?.data?.debt?.paid_debt_card?.toLocaleString()}{' '}
										UZS
									</div>
									<div>
										<b>Наличные</b>:{' '}
										{userBasket?.data?.debt?.paid_debt_cash?.toLocaleString()}{' '}
										UZS
									</div>
								</div>
							</div>
						</div>
						<div>
							<span className='px-2 py-1 bg-background-color rounded-md block mb-2'>
								Балл
							</span>
							<div>
								<b>Общий</b>: {userBasket?.data?.total_point?.toLocaleString()}
							</div>
							<div>
								<b>Ваучер</b>:{' '}
								{userBasket?.data?.voucher_point?.toLocaleString()}
							</div>
						</div>
						<div>
							<span className='px-2 py-1 bg-background-color rounded-md block mb-2'>
								Дата диапазон
							</span>
							<div className='flex gap-2 sm:flex-col'>
								<div className='inline-flex flex-col'>
									<span className='mb-2 text-xs'>От времени</span>
									<input
										type='date'
										className='form-control sm:w-full'
										onChange={e => {
											setFromTo({ ...fromTo, from: e.target.value })
										}}
									/>
								</div>
								<div className='inline-flex flex-col'>
									<span className='mb-2 text-xs'>До времени</span>
									<input
										type='date'
										className='form-control sm:w-full'
										onChange={e => {
											setFromTo({ ...fromTo, to: e.target.value })
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<br />
					{/* basket table */}
					{userBasket.length !== 0 ? (
						<table className='table w-full' style={{ minWidth: '1500px' }}>
							<thead>
								<tr className='bg-background-color'>
									<td className='py-2 bg-grey-100 px-2 font-medium'>Клиент</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>
										Создатель
									</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>
										Доставщик
									</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>
										Ассистент
									</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>Филиал</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>Сумм</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>Долг</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>Балл</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>
										Комбинация
									</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>Дата</td>
									<td className='py-2 bg-grey-100 px-2 font-medium'>
										Действия
									</td>
								</tr>
							</thead>
							<tbody>
								{userBasket?.data?.baskets?.map(item => (
									<tr key={item?.id}>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											<b className='text-gray-txt-color'> {item?.user?.name}</b>
											<br />
											{item?.user?.phone}
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											<b className='text-gray-txt-color'>
												{' '}
												{item?.creator?.name}
											</b>
											<br />
											{item?.creator?.phone} <br />
											{item?.creator?.role}
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											{item?.supervisor?.name}
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											{item?.assistant?.name}
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											{item?.branch?.name}
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											<b className='text-gray-txt-color'> Общий:</b>{' '}
											{item?.total_sum?.toLocaleString()} UZS <br />
											<b className='text-gray-txt-color'> Пластик:</b>{' '}
											{item?.card?.toLocaleString()} UZS <br />
											<b className='text-gray-txt-color'> Наличные:</b>{' '}
											{item?.cash?.toLocaleString()} UZS
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											<b className='text-gray-txt-color'> Общий:</b>{' '}
											{item?.debt?.debt?.toLocaleString()} UZS <br />
											<b className='text-gray-txt-color'> Оплаченный:</b>{' '}
											{item?.debt?.paid_debt?.toLocaleString()} UZS <br />
											<b className='text-gray-txt-color'> Пластик:</b>{' '}
											{item?.debt?.paid_debt_card?.toLocaleString()} UZS <br />
											<b className='text-gray-txt-color'> Наличные:</b>{' '}
											{item?.debt?.paid_debt_cash?.toLocaleString()} UZS
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											<b className='text-gray-txt-color'> Ваучер:</b>{' '}
											{item?.voucher_point?.toLocaleString()} <br />
											<b className='text-gray-txt-color'> Общий балл:</b>{' '}
											{item?.total_point?.toLocaleString()}
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											{item?.is_combo ? 'Да' : 'Нет'}
										</td>
										<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
											<div className='flex gap-x-2'>
												<b>Время заказа:</b> {item?.ordered_at}
											</div>
											<div className='flex gap-x-2'>
												<b>Время доставки:</b> {item?.delivered_at}
											</div>
										</td>

										<td align='center' className='py-2'>
											<div className=' flex gap-x-2 '>
												<Button
													mode={'icon'}
													text={<ion-icon name='menu-outline'></ion-icon>}
													onClick={() => {
														setProductsModalIsOpen(true)
														setSelectedBasketItem(item)
													}}
												/>
												<Popconfirm
													title='Вы уверены, что хотите удалить этот товар?'
													onConfirm={() => deleteBasket(item?.id)}
													okText='Да'
													cancelText='Нет'
												>
													<Button
														mode={'icon'}
														text={<ion-icon name='trash-outline'></ion-icon>}
													/>
												</Popconfirm>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<center className='text-gray-txt-color'>
							Информация не найдена.
						</center>
					)}
					<br />
					{/* basket footer pagination */}
					<center>
						<Pagination
							pageSize={basket_per ? basket_per : 40}
							total={basket_last * basket_per}
							current={basket_curr}
							onChange={page => setBasketCurr(page)}
						/>
					</center>
					<br />
				</Spin>
			)
		}

		if (modalType === 'comment') {
			return (
				<p className='p-2 rounded-5 bg-background-color'>
					{selectedCategory?.description}
				</p>
			)
		}

		if (modalType === 'statistics') {
			const columns = [
				{
					title: '#',
					dataIndex: 'number',
				},
				{
					title: 'Название товара	',
					dataIndex: 'name',
				},
				{
					title: 'Продано количество',
					dataIndex: 'count',
					sorter: (a, b) => a.count - b.count,
				},
			]

			const dataSource2 = []

			userStat
				?.filter(x => x?.count !== 0)
				?.map(item => {
					dataSource2.push({
						number: item?.position,
						name: item?.name,
						count: item?.count,
					})
				})

			return (
				<>
					<div className='w-50 flex  gap-y-6 gap-x-4 sm:flex-col md:flex-wrap justify-start'>
						{/*  NAME  */}
						<div className='inline-flex flex-col'>
							<span className='mb-2 text-xs'>От времени</span>
							<input
								type='date'
								className='form-control sm:w-full'
								onChange={e =>
									setStatisticsFromTo({
										...statisticsFromTo,
										from: e.target.value,
									})
								}
							/>
						</div>
						<div className='inline-flex flex-col'>
							<span className='mb-2 text-xs'>До времени</span>
							<input
								type='date'
								className='form-control sm:w-full'
								onChange={e =>
									setStatisticsFromTo({
										...statisticsFromTo,
										to: e.target.value,
									})
								}
							/>
						</div>
						{userStat?.length !== 0 && (
							<div className='inline-flex flex-col'>
								<span className='mb-2 text-xs'>Элементов на странице</span>
								<input
									type='number'
									className='form-control sm:w-full'
									value={statisticBasketPageSize}
									onChange={e => setStatisticBasketPageSize(e.target.value)}
								/>
							</div>
						)}
					</div>
					<br />
					{!loadingStat ? (
						<div className='relative'>
							<Table
								dataSource={dataSource2}
								columns={columns}
								showSorterTooltip
								locale={{
									triggerDesc: 'Нажмите чтобы tекст сортировки по убыванию',
									triggerAsc: 'Нажмите чтобы tекст сортировки по возрастанию',
									cancelSort: 'Нажмите чтобы oтменить сортировку текста',
									emptyText: 'Информация не найдена!',
								}}
								pagination={{
									showSizeChanger: true,
									pageSize: statisticBasketPageSize,
									onShowSizeChange: (current, size) =>
										setStatisticBasketPageSize(size),
								}}
							/>
						</div>
					) : (
						<div className={'w-full flex items-center justify-center'}>
							<h3 className='text-gray-txt-color'>
								Ожидайте загрузки данных...
							</h3>
						</div>
					)}

					<table
						id='table-to-xls'
						border='1'
						cellspasing='1'
						cellpadding='1'
						className='shadow-md w-full overflow-auto color-gray-txt-color hidden'
						style={{ minWidth: '1000px' }}
					>
						<thead>
							<th
								style={{ width: '60px' }}
								className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'
							>
								№
							</th>
							<th
								style={{ width: '500px', height: '36px' }}
								className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'
							>
								Название товара
							</th>
							<th
								style={{ width: '250px' }}
								className='px-2 py-2 border-r border-table-border whitespace-nowrap	 border-b bg-background-color border-l border-t'
							>
								Продано количество
							</th>
						</thead>
						<tbody>
							{userStat?.map((item, index) => {
								return (
									<>
										<tr key={item?.position}>
											<td
												style={{ verticalAlign: 'middle', height: '30px' }}
												align='center'
												className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'
											>
												{item?.position}
											</td>
											<td className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'>
												{item?.name}
											</td>
											<td
												align='center'
												className='border-b py-2 px-2 border-table-border whitespace-nowrap	 border-r border-l'
											>
												{item?.count}
											</td>
										</tr>
									</>
								)
							})}
						</tbody>
					</table>
				</>
			)
		}
	}

	// SUBMIT
	const handleSubmit = () => {
		if (modalType === 'update') {
			if (
				newClient?.id !== '' &&
				newClient?.name !== '' &&
				newClient?.address !== '' &&
				newClient?.branch_id !== '' &&
				newClient?.branch_id !== null &&
				newClient?.phone !== ''
			) {
				setUploading(true)
				API.patch(`/api/users/${selectedCategory?.id}`, newClient)
					.then(res => {
						message.success('Клиент успешно изменен!')
						setRefresh(!refresh)
					})
					.catch(() =>
						message.error('Произошла ошибка. Пожалуйста, попробуйте еще раз!')
					)
					.finally(() => {
						setModalIsOpen(false)
						setUploading(false)
					})
			} else {
				message.error('Пожалуйста, введите полную информацию.')
			}
		}

		if (modalType === 'add_point') {
			if (point !== '') {
				API.post(`/api/users/${selectedCategory?.id}/plus`, {
					point: point,
					total_point: allPoint,
				})
					.then(() => {
						message.success('Добавлено успешно!')
						setRefresh(!refresh)
					})
					.catch(() => {
						message.error('Произошла ошибка. Пожалуйста, попробуйте еще раз!')
					})
					.finally(() => {
						setModalIsOpen(false)
						setPoint(null)
						setAllPoint(null)
					})
			} else {
				message.error('Пожалуйста, введите полную информацию.')
			}
		}

		if (modalType === 'minus_point') {
			if (point !== '' || allPoint !== '') {
				API.post(`/api/users/${selectedCategory?.id}/minus`, {
					point: point,
					total_point: allPoint,
				})
					.then(() => {
						message.success('Возврат успешно!!')
						setRefresh(!refresh)
					})
					.catch(() => {
						message.error('Произошла ошибка. Пожалуйста, попробуйте еще раз!')
					})
					.finally(() => {
						setModalIsOpen(false)
						setPoint(null)
						setAllPoint(null)
						setCouponBall(null)
					})
			} else {
				message.error('Пожалуйста, введите полную информацию.')
			}
		}

		if (modalType === 'end_year') {
			if (prefix !== '') {
				API.post('/api/users/endYear', {
					prefix: prefix,
				})
					.then(() => {
						message.success('Год закончился! Удачи в следующем году!')
					})
					.catch(() => {
						message.error('Произошла ошибка! Попробуйте еще раз!')
					})
					.finally(() => {
						setModalIsOpen(false)
					})
			}
		}

		if (modalType === 'gift') {
			API.post('/api/gifts/request', {
				user_id: selectedCategory?.id,
				gifts: [{ gift_id: giftId, x: 1 }],
			})
				.then(res => {
					message.success('Выполнено успешно!')
				})
				.catch(err => {
					message.success('Произошла ошибка!')
				})
				.finally(() => {
					setModalIsOpen(false)
				})
		}
	}

	// MODAL WINDOW FOOTER
	const footer = () => {
		if (modalType === 'baskets') return null
		return (
			<div className='flex gap-x-2'>
				{modalType !== 'statistics' ? (
					<Button
						loading={uploading}
						onClick={() => {
							handleSubmit()
						}}
						text={'Cохранить'}
					/>
				) : null}
				<Button
					onClick={() => {
						setModalIsOpen(false)
					}}
					text={'Отмена'}
				/>
			</div>
		)
	}

	useEffect(() => {
		setDataReplaceAssistant({
			assistant_id: '',
			user_ids: [],
		})
	}, [showReplaceWindow])

	useEffect(() => {
		setPoint(null)
		setAllPoint(null)
		setPrefix('')
	}, [modalIsOpen])
	const handleSendToReplaceSupervisor = () => {
		if (dataForReplaceAssistant?.assistant_id !== '' && selectedUsers !== []) {
			API.post('/api/users/replace/assistant', {
				assistant_id: dataForReplaceAssistant?.assistant_id,
				user_ids: selectedUsers,
			})
				.then(res => {
					message.success('Клиент успешно перенесено!')
					setRefresh(!refresh)
				})
				.catch(() => {
					message.error('Произошла ошибка! Попробуйте еще раз')
				})
				.finally(() => {
					setShowReplaceWindow(false)
					setDataReplaceAssistant({
						assistant_id: '',
						user_ids: [],
					})
					setSelectedUsers([])
				})
		} else {
			message.error('Пожалуйста, введите свои данные!')
		}
	}

	const handleDeleteClient = id => {
		setDeleting(true)
		API.delete(`/api/users`, {
			data: {
				ids: selectedUsers,
			},
		})
			.then(res => {
				if (res.status === 200) {
					message.success('Клиент был удален!')
					setRefresh(!refresh)
				}
			})
			.catch(() => {
				message.error('Произошла ошибка! Попробуйте еще раз!')
			})
			.finally(() => {
				setDeleting(false)
				setDataReplaceAssistant({
					assistant_id: '',
					user_ids: [],
				})
				setSelectedUsers([])
			})
	}

	const deboundeFnc = useCallback(
		_debounce(value => {
			setPerPage(value)
		}, 500),
		[]
	)

	const onChangeFunc = e => {
		setCurrentPerPage(e)
		deboundeFnc(e)
	}
	// FETCHING CATEGORIES
	React.useEffect(() => {
		Loading({ topBar: true, topBarColor: '#377DFF' })
		dispatch(fetchingClients())
		API.get(
			`/api/users?branch_id=${branch_id}&assistant_id=${assistant_id}&search=${searchStr}&color=${
				filter?.status_id
			}&supervisor_id=${supervisor_id}&point_type=${point_type}&min_point=${min_point}&max_point=${max_point}&page=${currentPage}&per_page=${
				per_page !== null ? per_page : 40
			}`
		)
			.then(res => {
				setPerPage(res.data.payload.per_page)
				setLastPage(res.data.payload.last_page)
				dispatch(fetchedClients(res.data.payload.data.users))
				setClientsData(res.data.payload.data.users)
			})
			.catch(err => {
				console.log(err)
				if (err.response.status === 401) {
					dispatch(logout())
					navigate('/login', { replace: true })
				}
			})
			.finally(() => Loading())
	}, [
		branch_id,
		searchStr,
		supervisor_id,
		filter?.status_id,
		assistant_id,
		point_type,
		min_point,
		max_point,
		currentPage,
		refresh,
		per_page,
	])

	React.useEffect(() => {
		setCurrentPage(1)
	}, [
		branch_id,
		searchStr,
		supervisor_id,
		filter?.status_id,
		assistant_id,
		point_type,
		min_point,
		max_point,
		refresh,
	])

	React.useEffect(() => {
		setClientsData(clients)
	}, [clients])

	const dataSource = []

	const selectOneUser = (e, id) => {
		const index = selectedUsers.findIndex(item => item === id)
		if (index === -1) {
			if (e.target.checked) {
				setSelectedUsers([...selectedUsers, id])
			}
		}

		if (!e.target.checked) {
			let newArr = selectedUsers.filter(item => item !== id)
			setSelectedUsers(newArr)
		}
	}

	const addCouponClient = (clientId, name) => {
		API.post(`/api/coupons`, {
			user_id: clientId,
			count: addCouponCount,
		})
			.then(res => {
				message.success(`В ${name} добавлено ${addCouponCount} купонов`)
				setRefresh(!refresh)
			})
			.catch(() => {
				message.error('Произошла ошибка. Пожалуйста, попробуйте еще раз!')
			})
	}

	clientsData?.length &&
		clientsData?.forEach((client, index) => {
			dataSource.push({
				id: client?.id,
				select: (
					<input
						className='h-[15px] w-[15px] cursor-pointer'
						type={'checkbox'}
						onChange={e => selectOneUser(e, client?.id)}
						checked={selectedUsers.includes(client?.id)}
					/>
				),
				number: client?.id,
				name: (
					<>
						<h2 className='text-md text-gray-txt-color'>{client?.name}</h2>
						<span className='text-xs text-gray-txt -color block'>
							{client?.phone !== null && client?.phone.toLocaleString()}
						</span>
						<span className='text-xs text-gray-txt-color capitalize'>
							{client?.address}
						</span>
					</>
				),
				onlyName: client?.name,
				balance: (
					<>
						<span className='text-md text-gray-txt-color block'>
							{client?.balance !== null
								? client?.balance?.toLocaleString() + ' UZS'
								: 0}
						</span>
						<span className='text-xs text-gray-txt-color'>
							{client?.last_sale_date !== null
								? client?.last_sale_date
								: '--/--/--'}
						</span>
					</>
				),
				point: (
					<>
						<span className='text-md text-gray-txt-color block'>
							Текущий балл:{' '}
							{client?.point !== null ? client?.point.toLocaleString() : 0}
						</span>
						<span className='text-xs text-gray-txt-color'>
							Общий балл:{' '}
							{client?.total_point !== null
								? client?.total_point.toLocaleString()
								: '0'}
						</span>
					</>
				),
				deps: (
					<>
						<span className='text-md text-gray-txt-color block'>
							{client?.assistant?.name !== null
								? client?.assistant?.name
								: 'Нет Ассистента'}
						</span>
					</>
				),
				status: (
					<div className='flex gap-x-1 items-center'>
						{client?.color === 'dark gray' ? (
							<span
								onClick={() => {
									setSelectedCategory(client)
									setModalType('comment')
									setModalIsOpen(true)
								}}
								className='cursor-pointer text-white text-md bg-text-gray w-6 h-6 flex items-center justify-center rounded-full '
							>
								<ion-icon name='chatbox-ellipses-outline'></ion-icon>
							</span>
						) : (
							<span
								className={`text-md text-gray-txt-color block w-4 h-4 rounded-full ${
									client?.color && client?.color === 'green'
										? 'bg-green-clr'
										: client?.color === 'red'
										? 'bg-red-clr'
										: client?.color === 'cyan'
										? 'cyan-bg'
										: 'bg-yellow-clr'
								}`}
							></span>
						)}
					</div>
				),
				actions: (
					<div className='relative'>
						<Dropdown
							trigger={['click']}
							overlay={() => {
								return (
									<div className='shadow-lg rounded-md bg-white p-2'>
										<span className='text-primary-color px-2 opacity-60 mb-1'>
											{client?.name}
										</span>
										<Popconfirm
											destroyTooltipOnHide={true}
											destroyPopupOnHide={true}
											title={
												<div>
													<h3>Сколько купонов вы хотите подарить клиенту?</h3>
													<input
														value={addCouponCount}
														onChange={e => setAddCouponCount(e.target.value)}
														className='p-2 rounded-md bg-background-color'
														autoFocus
													/>
												</div>
											}
											onConfirm={() => {
												addCouponClient(client?.id, client?.name)
											}}
											onCancel={() => {}}
											okText='Сохранить'
											cancelText={null}
											showCancel={false}
										>
											<DropdownItem
												icon={() => (
													<ion-icon name='duplicate-outline'></ion-icon>
												)}
												text='Добавить купон'
												onClick={() => {
													setAddCouponCount(1)
												}}
											/>
										</Popconfirm>
										{client?.coupons?.length !== 0 ? (
											<DropdownItem
												icon={() => <ion-icon name='ticket-outline'></ion-icon>}
												text='Купоны'
												onClick={() => {
													setModalType('coupon')
													setModalIsOpen(true)
													setSelectedCategory(client)
												}}
											/>
										) : null}
										<DropdownItem
											icon={() => <ion-icon name='list-outline'></ion-icon>}
											text='Корзина'
											onClick={() => {
												setModalType('baskets')
												setModalIsOpen(true)
												setSelectedCategory(client)
											}}
										/>
										<Popconfirm
											title='Выберите, чтобы добавить или удалить баллы для клиента!'
											onConfirm={() => {
												setModalType('add_point')
												setModalIsOpen(true)
												setSelectedCategory(client)
											}}
											onCancel={() => {
												setModalType('minus_point')
												setModalIsOpen(true)
												setSelectedCategory(client)
											}}
											okText='+ Добавить'
											cancelText='- Уменьшить'
										>
											<DropdownItem
												icon={() => (
													<ion-icon name='bar-chart-outline'></ion-icon>
												)}
												text='Баллы'
											/>
										</Popconfirm>
										<DropdownItem
											icon={() => <ion-icon name='create-outline'></ion-icon>}
											text='Изменить'
											onClick={() => {
												setModalType('update')
												setSelectedCategory(client)
												setModalIsOpen(true)
											}}
										/>
									</div>
								)
							}}
						>
							<span className='text-2xl text-text-gray cursor-pointer pl-4 opacity-70 hover:opacity-100'>
								<ion-icon name='list-outline'></ion-icon>
							</span>
						</Dropdown>
					</div>
				),
			})
		})

	// rowSelection object indicates the need for row selection
	const selectAllUser = e => {
		if (e.target.checked) {
			setFullSelectedPages([...fullSelectedPages, currentPage])
			let newArr = []
			clientsData?.forEach((item, i) => {
				const index = selectedUsers.findIndex(x => x === item.id)
				if (index === -1) {
					newArr.push(item?.id)
				}
			})

			setSelectedUsers([...selectedUsers, ...newArr])
		} else {
			let newArr2 = fullSelectedPages.filter(item => item !== currentPage)
			setFullSelectedPages(newArr2)
			let newArr = []
			selectedUsers.forEach((item, i) => {
				let index = clientsData.findIndex(x => x.id === item)
				if (index === -1) {
					newArr.push(item)
				}
			})
			setSelectedUsers(newArr)
		}
	}

	let columns = []

	useEffect(() => {
		clientsData.map((item, i) => {
			let index = selectedUsers.findIndex(x => x === item.id)
			if (index === -1) {
				let newArr = fullSelectedPages.filter(x => x !== currentPage)
				setFullSelectedPages(newArr)
			}
		})
	}, [selectedUsers])

	if (
		localStorage.getItem('vivian_role') === 'ceo' ||
		localStorage.getItem('vivian_role') === 'super-admin'
	) {
		columns = [
			{
				key: 'select',
				title: (
					<input
						className='h-[15px] w-[15px] cursor-pointer'
						type={'checkbox'}
						onChange={selectAllUser}
						checked={fullSelectedPages.includes(currentPage)}
					/>
				),
				dataIndex: 'select',
				width: '3%',
			},
			{
				key: 'number',
				title: '',
				dataIndex: 'number',
				width: '3%',
			},
			{
				key: 'id',
				title: <b>Клиент данных</b>,
				dataIndex: 'name',
				width: '40%',
			},
			{
				key: 'balance',
				title: <span className='text-table-gray'>Баланс</span>,
				dataIndex: 'balance',
				width: '20%',
			},
			{
				key: 'point',
				title: <span className='text-table-gray'>Балл</span>,
				dataIndex: 'point',
				width: '20%',
			},
			{
				key: 'deps',
				title: <span className='text-table-gray'>Имя ассистентa</span>,
				dataIndex: 'deps',
				width: '20%',
			},
			{
				key: 'status',
				title: <span className='text-table-gray'>Статус</span>,
				dataIndex: 'status',
				width: '20%',
			},
			{
				key: 'actions',
				title: <span className='text-table-gray'>Действия</span>,
				dataIndex: 'actions',
				width: '20%',
			},
		]
	} else {
		columns = [
			{
				key: 'select',
				title: (
					<input
						className='h-[15px] w-[15px] cursor-pointer'
						type={'checkbox'}
						onChange={selectAllUser}
						checked={fullSelectedPages.includes(currentPage)}
					/>
				),
				dataIndex: 'select',
				width: '3%',
			},
			{
				key: 'number',
				title: '',
				dataIndex: 'number',
				width: '3%',
			},
			{
				key: 'id',
				title: <b>Клиент данных</b>,
				dataIndex: 'name',
				width: '40%',
			},
			{
				key: 'balance',
				title: <span className='text-table-gray'>Баланс</span>,
				dataIndex: 'balance',
				width: '20%',
			},
			{
				key: 'point',
				title: <span className='text-table-gray'>Балл</span>,
				dataIndex: 'point',
				width: '20%',
			},
			{
				key: 'deps',
				title: <span className='text-table-gray'>Имя ассистентa</span>,
				dataIndex: 'deps',
				width: '20%',
			},
			{
				key: 'status',
				title: <span className='text-table-gray'>Статус</span>,
				dataIndex: 'status',
				width: '20%',
			},
		]
	}

	React.useEffect(() => {
		const clientsContainer = document.getElementById('containerDiv')

		clientsContainer.addEventListener('scroll', event => {
			if (clientsContainer.scrollTop > 300) {
				setScrollTop(true)
			} else {
				setScrollTop(false)
			}
		})
	}, [])
	return (
		<div className='w-full' id='top'>
			{productsModalIsOpen ? (
				<Modal
					className='z-50'
					size='large'
					onClose={() => setProductsModalIsOpen(false)}
					title={selectedBasketItem?.user?.name}
					content={() => (
						<div>
							{selectedBasketItem?.orders?.length !== 0 ? (
								<table className='table w-full'>
									<thead>
										<tr className='bg-background-color'>
											<td className='py-2 bg-grey-100 px-2 font-medium'>
												Название товара
											</td>
											<td className='py-2 bg-grey-100 px-2 font-medium'>
												Цена
											</td>
											<td className='py-2 bg-grey-100 px-2 font-medium'>
												Количество
											</td>
										</tr>
									</thead>
									<tbody>
										{selectedBasketItem?.orders?.map(item => (
											<tr key={item?.id}>
												<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
													{item?.product_name}
												</td>
												<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
													{item?.price?.toLocaleString()}
												</td>
												<td className='color-gray-txt-color py-4 border-b border-light-gray text-xs font-semibold px-2'>
													{item?.count}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								<center className='text-gray-txt-color'>
									Информация не найдена.
								</center>
							)}
						</div>
					)}
					footer={() => <></>}
				/>
			) : null}

			{!productsModalIsOpen && modalIsOpen && (
				<Modal
					size='large'
					onClose={() => setModalIsOpen(false)}
					fullScreen={modalType === 'baskets'}
					title={
						modalType === 'add'
							? 'Добавить клиент'
							: modalType === 'gift'
							? 'Дарить подарок'
							: modalType === 'coupon'
							? 'Купоны клиентов'
							: modalType === 'end_year'
							? 'Заканчивается год'
							: modalType === 'baskets'
							? 'Корзинка клиента - ' + selectedCategory?.name
							: modalType === 'add_point'
							? 'Добавить балл'
							: modalType === 'minus_point'
							? 'Уменьшить балл'
							: modalType === 'comment'
							? 'Коментарии'
							: selectedCategory?.onlyName || selectedCategory?.name
					}
					content={() => content()}
					footer={() => footer()}
				/>
			)}
			{showReplaceWindow && (
				<Modal
					content={() => (
						<div className='flex flex-col w-full'>
							<label className='form-label'>
								<span className='form-label__text'>Выберите филиал</span>
								<TreeSelect
									value={newClient?.branch_id}
									defaultValue={newClient?.branch_id}
									dropdownStyle={{
										maxHeight: 400,
										overflow: 'auto',
									}}
									placeholder='Выберите филиал'
									className='form-control__multiselect  sm:w-full'
									onChange={e => {
										setNewClient({ ...newClient, branch_id: e })
									}}
								>
									{branchesOptions.map(branch => {
										if (branch.childrens) {
											return (
												<TreeNode
													value={branch?.value}
													title={branch.label}
													key={branch.value}
													dispatch
												>
													{branch?.childrens?.map(child => (
														<TreeNode
															value={child.value}
															title={child.label}
															key={child.value}
														/>
													))}
												</TreeNode>
											)
										}
										return (
											<TreeNode
												value={branch.value}
												title={branch.label}
												key={branch.value}
											/>
										)
									})}
								</TreeSelect>
							</label>
							<label className='form-label flex flex-col w-full'>
								<span className='form-label__text'>Выберите помощника</span>
								<Select
									className='form-control__multiselect sm:w-full'
									onChange={value => {
										setDataReplaceAssistant({
											...dataForReplaceAssistant,
											assistant_id: value,
										})
									}}
									placeholder={'Выбрать Ассистента'}
									defaultValue={dataForReplaceAssistant?.assistant_id}
								>
									{[...windowEmployees]?.map((supervisor, index) => (
										<Select.Option value={supervisor?.id} key={index}>
											{supervisor?.name}
										</Select.Option>
									))}
								</Select>
							</label>
						</div>
					)}
					footer={() => {
						return (
							<div className='flex gap-x-2'>
								<Button
									text={'Отмена'}
									onClick={() => {
										setShowReplaceWindow(false)
									}}
								/>
								<Button
									text={'Сохранять'}
									onClick={() => {
										handleSendToReplaceSupervisor()
									}}
								/>
							</div>
						)
					}}
					onClose={() => setShowReplaceWindow(false)}
				/>
			)}
			<Heading title={'Клиенты'} />
			{/*  SECTION  */}
			<div
				className={
					'scroll-smooth px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto '
				}
				style={{ minHeight: '80vh', maxHeight: '100vh' }}
				id='containerDiv'
			>
				<HeadingTop
					TreeSelect={TreeSelect}
					branchesOptions={branchesOptions}
					setSupervisor_id={setSupervisor_id}
					supervisor_id={supervisor_id}
					supervisors={supervisors}
					setAssistant_id={setAssistant_id}
					assistant_id={assistant_id}
					assistants={assistants}
					search={search}
					setSearch={setSearch}
					setSearchStr={setSearchStr}
					setFilter={setFilter}
					filter={filter}
					statuses={statuses}
					setPoint_type={setPoint_type}
					pointTypes={pointTypes}
					point_type={point_type}
					max_point1={max_point1}
					setMax_point1={setMax_point1}
					branch_id={branch_id}
					setBranch_id={setBranch_id}
					setMax_point={setMax_point}
					min_point1={min_point1}
					setMin_point1={setMin_point1}
					setMin_point={setMin_point}
					Button={Button}
					setModalIsOpen={setModalIsOpen}
					setModalType={setModalType}
					selectedUsers={selectedUsers}
					setShowReplaceWindow={setShowReplaceWindow}
					handleDeleteClient={handleDeleteClient}
					deleting={deleting}
				/>

				{/*  SECTION BODY  */}
				<div className={'mt-6 w-full'}>
					<div style={{ minWidth: '1000px' }}>
						{!loading ? (
							<div>
								<br />
								<center>
									{last_page !== 1 ? (
										<div className='flex gap-x-4 justify-center'>
											<Pagination
												pageSize={per_page ? per_page : 40}
												total={last_page * per_page}
												current={currentPage}
												onChange={(page, x) => {
													setCurrentPage(page)
													setPerPage(x)
												}}
											/>

											<InputNumber
												onChange={e => onChangeFunc(e)}
												value={per_page}
											/>
										</div>
									) : null}
								</center>
								<br />
								<br />
								<Table
									rowKey={record => record.id}
									dataSource={dataSource}
									columns={columns}
									size={'small'}
									pagination={false}
									rowClassName='cursor-pointer'
									onRow={(record, index) => {
										return {
											onClick: e => {
												console.info(e.target.classList[0])
												if (
													e.target.classList[0] === 'text-xs' ||
													e.target.classList[0] === 'text-md' ||
													e.target.classList[0] === 'ant-table-cell'
												) {
													setSelectedCategory(record)
													setModalType('statistics')
													setModalIsOpen(true)
												} else {
													console.info(e.target.classList)
												}
											},
										}
									}}
								/>
							</div>
						) : (
							<div className={'w-full flex items-center justify-center'}>
								<h3 className='text-gray-txt-color'>
									Ожидайте загрузки данных...
								</h3>
							</div>
						)}
					</div>
				</div>
				{scrollTop && (
					<span
						onClick={() => {
							document.getElementById('containerDiv').scrollTop = 0
						}}
						className='fixed bottom-10 right-10  bg-primary-color text-white text-2xl rounded-full flex items-center justify-center cursor-pointer'
						style={{ width: '40px', height: '40px', zIndex: '9999999' }}
					>
						<span className='text-white' style={{ zIndex: '9999999999999' }}>
							^
						</span>
					</span>
				)}
			</div>
		</div>
	)
}

export default Clients
