import { useEffect } from 'react'
import { useState } from 'react'
import API, { baseURL } from '../../../assets/api/api'
import Heading from '../../../UI/Heading'
import { Badge, Button, Pagination, Popconfirm, Table, message } from 'antd'
import moment from 'moment'
import { Link, NavLink } from 'react-router-dom'

const CustomPrices = () => {
	const [prices, setPrices] = useState([])
	const [loading, setLoading] = useState(false)
	const [per_page, setPerPage] = useState(15)
	const [last_page, setLastPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1)
	function fetchAllPrices() {
		setLoading(true)
		API.get(`${baseURL}/api/customPrices/?page=${currentPage}`).then(res => {
			setPrices(res.data.payload.data)
			setLoading(false)
			setPerPage(res.data.payload.per_page)
			setLastPage(res.data.payload.last_page)
		})
	}

	useEffect(() => {
		fetchAllPrices()
	}, [currentPage])

	const dataSource = []

	prices?.forEach(item => {
		dataSource.push({
			id: item.id,
			name: (
				<b>
					<Link className='font-medium' to={`details/${item.id}`}>
						{item.name}
					</Link>
				</b>
			),
			date: item.created_at
				.split('T')
				.join(' ')
				.split('.')
				.slice(0, 1)
				.join(' '),
			active: (
				<span
					className={`w-[20px] h-[20px] flex shadow-md rounded-md ${
						item?.active ? 'bg-green-600' : 'bg-red-600'
					}`}
				></span>
			),
			actions: (
				<div className='flex gap-x-2'>
					<Popconfirm
						title='Вы хотите удалить данные?'
						okText='Да'
						cancelText='Нет'
						onConfirm={() => deletePrice(item?.id)}
					>
						<Button
							type='primary'
							danger
							icon={<ion-icon name='trash-outline'></ion-icon>}
						></Button>
					</Popconfirm>
				</div>
			),
		})
	})

	const columns = [
		{
			key: 'name',
			dataIndex: 'name',
			title: <b className='text-table-gray'>Название</b>,
		},
		{
			key: 'date',
			dataIndex: 'date',
			title: <b className='text-table-gray'>Дата</b>,
		},
		{
			key: 'active',
			dataIndex: 'active',
			title: <b className='text-table-gray'>Активность</b>,
		},
		{
			key: 'actions',
			dataIndex: 'actions',
			title: <b className='text-table-gray'>Движение</b>,
		},
	]

	function deletePrice(id) {
		API.delete(`${baseURL}/api/customPrices/${id}`).then(data => {
			fetchAllPrices()
			message.success('Данные успешно удален!')
		})
	}

	return (
		<div className='w-full'>
			<Heading title={'Текущие цены'} />

			{/*  SECTION  */}
			<div className={'px-4 py-9 bg-white rounded-10 mt-6 '}>
				<Table
					loading={loading}
					columns={columns}
					dataSource={dataSource}
					pagination={false}
				/>
				<center className='pt-8'>
					<Pagination
						pageSize={per_page ? per_page : 40}
						total={last_page * per_page}
						current={currentPage}
						onChange={(page, x) => {
							setCurrentPage(page)
						}}
					/>
				</center>
			</div>
		</div>
	)
}
export default CustomPrices
