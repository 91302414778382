import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API, { baseURL } from "../../assets/api/api";
import {
  addBranch,
  deleteBranch,
  fetchedBranches,
  fetchingBranches,
  fetchingErrorBranches,
} from "../../redux/branchesSlice";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import SearchInput from "../../UI/SearchInput";

import { Loading } from "react-loading-ui";
import { message, Popconfirm, Skeleton, Spin, Table } from "antd";
import { logout } from "../../redux/loginSlice";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal";
import axios from "axios";
import Dropdown from "../../UI/Dropdown";
import Content from "./Content";

const Branches = () => {
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();
  const { branches, loading } = useSelector((state) => state.branches);
  const [searchStr, setSearchStr] = React.useState("");
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [selectedBranch, setSelectedBranch] = React.useState({});
  const [newCategory, setNewCategory] = React.useState({
    parent_id: "",
    name: "",
    warehouse: "",
  });
  const [uploading, setUploading] = React.useState(false);
  const [branchesData, setBranchesData] = React.useState(branches);
  const [firstExpandedId, setFirstExpandedId] = useState("");

  const [firstExpandedData, setFirstExpandedData] = useState([]);

  const [isWarehouse, setIsWarehouse] = useState("");
  const [refresh, setRefresh] = React.useState(false);

  const [isParent, setIsParent] = useState(false);
  useEffect(() => {
    setNewCategory({
      parent_id:
        modalType === "add" ? branchesData[0]?.id : selectedBranch?.parent_id,
      name: modalType === "add" ? "" : selectedBranch.name,
      warehouse: modalType === "add" ? true : selectedBranch?.warehouse,
    });
    setIsWarehouse(modalType === "add" ? "" : selectedBranch?.warehouse);
  }, [selectedBranch, modalType]);

  const expandedRowRender = () => {
    function subExpandedRender() {
      // const columns = [
      //   {
      //     key: "id",
      //     title: <b>Название филиал</b>,
      //     dataIndex: "name",
      //     width: "80%",
      //   },
      //   {
      //     key: "id",
      //     title: <b>Склад</b>,
      //     dataIndex: "warehouse",
      //     width: "30%",
      //   },
      //   {
      //     key: "actions",
      //     title: <span className="text-table-gray">Движение</span>,
      //     dataIndex: "actions",
      //     width: "20%",
      //   },
      // ];
      // const data = [];
      // secondExpandedData?.map((item, index) => {
      //   data.push({
      //     key: item?.id?.toString(),
      //     name: item?.name,
      //     warehouse: <span>{item?.warehouse ? "Есть" : "Нет"}</span>,
      //     actions: (
      //       <div className="flex gap-x-2">
      //         <Button
      //           className={"bg-yellow-500 text-white btn-sm"}
      //           onClick={() => {
      //             setModalIsOpen(true);
      //             setModalType("edit");
      //             setSelectedBranch(item);
      //             setIsParent(false);
      //           }}
      //           mode="icon"
      //           text={<ion-icon name="create-outline"></ion-icon>}
      //         />
      //       </div>
      //     ),
      //   });
      // });
      // return (
      //   <Table
      //     columns={columns}
      //     size="small"
      //     dataSource={data}
      //     pagination={false}
      //   />
      // );
    }

    const columns = [
      {
        key: "id",
        title: <b>Название филиал</b>,
        dataIndex: "name",
        width: "80%",
      },
      {
        key: "id",
        title: <b>Склад</b>,
        dataIndex: "warehouse",
        width: "30%",
      },
      {
        key: "actions",
        title: <span className="text-table-gray">Движение</span>,
        dataIndex: "actions",
        width: "20%",
      },
    ];

    const data = [];

    firstExpandedData?.map((item, index) => {
      data.push({
        key: item?.id?.toString(),
        name: item?.name,
        warehouse: <span>{item?.warehouse ? "Есть" : "Нет"}</span>,
        actions: (
          <div className="flex gap-x-2">
            <Button
              className={"bg-yellow-500 text-white btn-sm"}
              onClick={() => {
                setModalIsOpen(true);
                setModalType("edit");
                setSelectedBranch(item);
                setIsParent(false);
              }}
              mode="icon"
              text={<ion-icon name="create-outline"></ion-icon>}
            />
          </div>
        ),
      });
    });

    return (
      <Table
        columns={columns}
        size="small"
        dataSource={data}
        pagination={false}
      />
    );
  };

  // warehouse options
  const warehouseOptions = [
    {
      label: "Есть",
      value: true,
    },
    {
      label: "Нет",
      value: false,
    },
  ];

  // SUBMIT
  const handleSubmit = () => {
    if (modalType === "add") {
      if (
        newCategory?.name !== "" &&
        newCategory?.name !== null &&
        newCategory?.parent_id !== "" &&
        newCategory?.parent_id !== null &&
        newCategory?.warehouse !== null
      ) {
        setUploading(true)
        dispatch(fetchingBranches());
        API.post("/api/branches", newCategory)
          .then((res) => {
            if (res.status === 200) {
              message.success("Филиал успешно создан!");
              setModalIsOpen(false);
              console.info(newCategory)
            }
          })
          .catch((err) => {
            message.error("Произошла ошибка. Пожалуйста, попробуйте еще раз.");
            dispatch(fetchingErrorBranches());
            if (err.response.status === 409) {
              message.error("Это имя уже существовало!")
            }
          })
          .finally(() => {
            setNewCategory({
              name: "",
              parent_id: branchesData[0]?.id,
              warehouse: true,
            });
            setModalIsOpen(false);
            setRefresh(!refresh);
            setUploading(false)
          });
      } else {
        message.warn("Пожалуйста, введите свои данные!");
      }
    } else {
      if (
        newCategory?.name !== "" &&
        newCategory?.parent_id !== "" &&
        newCategory?.warehouse !== ""
      ) {
        dispatch(fetchingBranches());
        API.patch(`/api/branches/${selectedBranch?.id}`, {
          name: newCategory.name,
          parent_id: newCategory?.parent_id,
          factory: newCategory?.warehouse,
        })
          .then((res) => {
            if (res.status === 200) {
              message.success("Филиал успешно изменен!");
              setModalIsOpen(false);
              setRefresh(!refresh);
            }
          })
          .catch((err) => {
            message.error("Произошла ошибка. Пожалуйста, попробуйте еще раз.");
            dispatch(fetchingErrorBranches());
          })
          .finally(() => {
            setNewCategory({
              name: "",
              parent_id: branchesData[0]?.id,
              warehouse: true,
            });
            setModalIsOpen(false);
            setUploading(false)
          });
      } else {
        message.warn("Пожалуйста, введите свои данные!");
      }
    }
  };

  // MODAL WINDOW CONTENT
  const content = () => (
    <Content
      setData={setNewCategory}
      data={newCategory}
      onSubmit={handleSubmit}
      branches={branchesData}
      warehouseOptions={warehouseOptions}
      isParent={isParent}
    />
  );

  // MODAL WINDOW FOOTER
  const footer = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          loading={uploading}
          onClick={() => {
            handleSubmit();
          }}
          text={"Cохранить"}
        />
        <Button
          onClick={() => {
            setModalIsOpen(false);
          }}
          text={"Отмена"}
        />
      </div>
    );
  };

  // FETCHING BRANCHES
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    dispatch(fetchingBranches());
    API.get(`/api/branches/${localStorage.getItem('vivian_role') !== 'ceo' ? 'children' : ''}`)
      .then((res) => {
        Loading();
        localStorage.getItem("vivian_role") !== "ceo" ? dispatch(fetchedBranches([res.data.payload])) : dispatch(fetchedBranches(res.data.payload))
        console.log(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchingErrorBranches());
        if (err.response.status === 401) {
          dispatch(logout());
          navigate("/login", { replace: true });
        }
      });
    setFirstExpandedId("");
  }, [refresh]);

  React.useEffect(() => {
    setBranchesData(branches);
  }, [branches]);

  // SEARCH
  React.useEffect(() => {
    if (searchStr.length > 0) {
      const newDataSource = branches.filter((branch) => {
        return branch?.name?.toLowerCase().includes(searchStr.toLowerCase());
      });
      setBranchesData(newDataSource);
    } else {
      setBranchesData(branches);
    }
  }, [searchStr]);

  const dataSource = [];

  branchesData?.forEach((branch) => {
    dataSource.push({
      id: branch?.id,
      name: branch?.name,
      warehouse: <span>{branch?.warehouse ? "Есть" : "Нет"}</span>,
      actions: (
        <div className="flex gap-x-2">
          <Button
            className={"bg-yellow-500 text-white"}
            onClick={() => {
              setModalIsOpen(true);
              setModalType("edit");
              setSelectedBranch(branch);
              setIsParent(true);
            }}
            mode="icon"
            text={<ion-icon name="create-outline"></ion-icon>}
          />
        </div>
      ),
    });
  });

  const columns = [
    {
      key: "id",
      title: <b>Название филиал</b>,
      dataIndex: "name",
      width: "80%",
    },
    {
      key: "id",
      title: <b>Склад</b>,
      dataIndex: "warehouse",
      width: "30%",
    },
    {
      key: "actions",
      title: <span className="text-table-gray">Движение</span>,
      dataIndex: "actions",
      width: "20%",
    },
  ];

  return (
    <div className="w-full">
      {modalIsOpen && (
        <Modal
          onClose={() => setModalIsOpen(false)}
          title={
            modalType === "add" ? "Добавить филиал" : "Редактировать филиал"
          }
          content={() => content()}
          footer={() => footer()}
        />
      )}
      <Heading title={"Филиали"} />
      {/*  SECTION  */}
      <div
        className={"px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto"}
        style={{ minHeight: "80vh", maxHeight: "100vh" }}
      >
        {/* SECTION HEADING */}
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between"
          }
        >
          <SearchInput
            placeholder={"Поиск по филиали"}
            width={"w-326 sm:w-full"}
            search={search}
            setSearch={setSearch}
            debounceTimeOut={800}
            prefix={<ion-icon name="search-outline"></ion-icon>}
            onChange={setSearchStr}
          />

          <Button
            text={"Добавить филиал"}
            startedIcon={"plus"}
            width={"w-320 sm:w-full"}
            onClick={() => {
              setModalIsOpen(true);
              setModalType("add");
              setIsParent(false);
            }}
          />
        </div>
        {/*  SECTION BODY  */}
        <div className={"mt-6 w-full"}>
          <Skeleton loading={loading} active>
            <Table
              rowKey={(record) => record.id}
              dataSource={dataSource}
              columns={columns}
              expandable={{
                expandedRowRender,
                onExpand(expand, exRecord) {
                  if (expand) {
                    setFirstExpandedId(exRecord);
                    setFirstExpandedData(
                      branchesData?.find((x) => x?.id === exRecord?.id)
                        ?.childrens
                    );
                  } else {
                    setFirstExpandedId("");
                  }
                },
                expandedRowKeys: [firstExpandedId.id],
              }}
            />
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default Branches;
