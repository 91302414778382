import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import API from "../../assets/api/api";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import SearchInput from "../../UI/SearchInput";
import { Modal, Form, message, Popconfirm, Skeleton, Spin, Table, Select } from "antd";
import { Loading } from "react-loading-ui";
import Dropdown from "../../UI/Dropdown";
import {
  fetchProducts,
  fetchedProducts
} from "../../redux/productsSlice";
import {
  fetchCategories,
  fetchingCategories,
} from "../../redux/categoriesSlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
// import Modal from "../../UI/Modal";
import axios from "axios";
import NumberFormat from "react-number-format";
import { MenuOutlined } from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../assets/imageCrop";

const Products = () => {
  // MUST BE INITIALIZED
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const { products, loading } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.categories);
  const [filterByCategory, setFilterByCategory] = React.useState("");
  const [searchStr, setSearchStr] = React.useState("");
  const [dataSource, setDataSource] = React.useState(products);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentProduct, setCurrentProduct] = React.useState({});
  const [modalType, setModalType] = React.useState("");
  const [photoUploaded, setPhotoUploaded] = React.useState("");
  const [photoFile, setPhotoFile] = React.useState("");
  const [newProductData, setNewProductData] = React.useState({
    category_id: null,
    name: "",
    image: "",
    price: null,
    brand: "Vivian Legend",
    mode_app: "",
    keeping: null,
    conditions: "",
    description: {
      uz: "",
      qr: "",
      ru: "",
    },
    point: null,
  });
  const [uploading, setUploading] = React.useState(false);
  const categoriesOptions = [
    {
      value: "",
      label: "Все категории",
    },
  ];
  const windowPage = React.useRef(null);
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={ {
        cursor: "grab",
        color: "#999",
        width: "30px",
      } }
      className="cursor-grabbing"
    />
  ));

  const [sortedProducts, setSortedProducts] = React.useState([]);
  const [uploadSortedProducts, setUploadSortedProducts] = React.useState(false);
  const [staticProducts, setStaticProducts] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false)
  const [imageCropWindow, setImageCropWindow] = useState(false)
  // FETCHING PRODUCTS
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    dispatch(fetchProducts()).then((res) => {
      Loading();
      setDataSource(res.payload.payload);
      setStaticProducts(res.payload.payload);
    });
    dispatch(fetchCategories());
  }, [refresh]);

  // FETCHING CATEGORIES
  categories.map((category) => {
    categoriesOptions.push({
      value: category.category_id,
      label: category.category_name,
    });
  });

  // FILTER BY CATEGORY
  React.useEffect(() => {
    if (filterByCategory !== "") {
      const filteredProducts = products.filter(
        (product) => product.category_id === filterByCategory
      );
      setDataSource(filteredProducts);
    } else {
      setDataSource(products);
    }
  }, [filterByCategory]);

  // FILTER BY SEARCH STRING
  React.useEffect(() => {
    if (searchStr !== "") {
      const filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes(searchStr.toLowerCase())
      );
      setDataSource(filteredProducts);
    } else {
      setDataSource(products);
    }
  }, [searchStr]);

  // SET CURRENT PRODUCT
  React.useEffect(() => {
    if (modalType === "update") {
      setNewProductData({
        category_id: currentProduct.category_id,
        name: currentProduct.name,
        image: currentProduct.image || "",
        price: currentProduct.price,
        brand: currentProduct.brand,
        mode_app: currentProduct.mode_app,
        keeping: currentProduct.keeping,
        conditions: currentProduct.conditions,
        description: {
          uz: currentProduct.description.uz,
          qr: currentProduct.description.qr,
          ru: currentProduct.description.ru,
        },
        point: currentProduct.point,
      });
    }

    if (modalType === "add") {
      setNewProductData({
        point: null,
        price: null,
        keeping: null || 0,
        category_id: null,
        name: "",
        image: "",
        brand: "Vivian Legend",
        mode_app: "",
        conditions: "",
        description: {
          uz: "",
          qr: "",
          ru: "",
        },
      });
    }

    setPhotoFile("")
    setPhotoUploaded("")
  }, [modalType, currentProduct, modalIsOpen]);

  // DELETE PRODUCT
  const deleteProductFunction = (id) => {
    dispatch(fetchingCategories());
    API.delete(`/api/products/${id}`).then((res) => {
      setDataSource(dataSource.filter((product) => product.id !== id));
      message.success("Товар успешно удален!");
    });
  };

  // UPDATE PRODUCT FUNCTION -------------->
  const updateProductFunction = () => {
    setUploading(true);
    API.patch(`/api/products/${currentProduct.id}`, newProductData)
      .then((res) => {
        setModalIsOpen(false);
        setRefresh(!refresh)
        message.success("Товар успешно обновлен!");
        setUploading(false);
      })
      .catch((err) => {
        setUploading(false);
        message.error("Ошибка обновления!");
      });
  };

  // ADD PRODUCT FUNCTION ---------------->
  const addProductFunction = () => {
    setUploading(true);
    API.post("/api/products", newProductData)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          message.success("Товар успешно добавлен");
          setRefresh(!refresh)
          setNewProductData({
            category_id: null,
            name: "",
            image: "",
            price: null,
            brand: "Vivian Legend",
            keeping: null || 0,
            conditions: "",
            description: {
              uz: "",
              qr: "",
              ru: "",
            },
            point: null,
          });
          setModalIsOpen(false);
        } else {
          message.error("Ошибка добавления товара");
          dispatch(fetchedProducts());
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          message.error("Товар с таким именем уже существует!");
        }

        if (err.response.status === 422) {
          message.error('Данные введены неверно! Пожалуйста проверьте и попробуйте снова!')
        }
      })
      .finally(() => setUploading(false));
  };

  // TABLE DATA RENDER
  const tableData = [];


  dataSource &&
    dataSource?.map((product) => {
      tableData.push({
        id: product?.id,
        position: product?.position,
        name: (
          <div className="flex gap-x-6 items-center">
            { window.innerWidth < 500 ? null : (
              <Zoom>
                <img
                  src={
                    product?.image !== null
                      ? product?.image
                      : "https://news.ykt.ru/images/no-image.jpg"
                  }
                  width="50"
                  height={ 50 }
                  className="rounded-5 shadow "
                  style={ { minWidth: '50px', maxHeight: '50px', objectFit: 'contain' } }
                  alt=""
                />
              </Zoom>
            ) }
            <div className="flex flex-col">
              <div className="font-medium text-sm text-gray-txt-color capitalize">
                { product?.name }
              </div>
              <div className="text-xs text-gray-txt-color font-light capitalize">
                { product?.brand }
              </div>
            </div>
          </div>
        ),
        price:
          product?.price !== null
            ? product?.price?.toLocaleString() + " UZS"
            : "",
        point: product?.point,
        actions: (
          <div className="flex gap-x-2">
            <Button
              type="primary"
              onClick={ () => {
                setCurrentProduct(product);
                setModalType("update");
                setModalIsOpen(true);
              } }
              text={ <ion-icon name="create-outline"></ion-icon> }
              mode="icon"
            ></Button>
            <Popconfirm
              title="Вы уверены, что хотите удалить этот товар?"
              onConfirm={ () => deleteProductFunction(product?.id) }
              okText="Да"
              cancelText="Нет"
            >
              <Button
                type="danger"
                text={ <ion-icon name="trash-outline"></ion-icon> }
                mode="icon"
              ></Button>
            </Popconfirm>
          </div>
        ),
      });
    });

  // TABLE HEADERS
  let columns = [
    {
      key: "id",
      title: <b>Сорт</b>,
      dataIndex: "sort",
      width: "5%",
      render: () => <DragHandle />,
    },
    {
      key: "id",
      title: <b>Все товары</b>,
      dataIndex: "name",
      width: "60%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Цена</span>,
      dataIndex: "price",
      width: "20%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Балл</span>,
      dataIndex: "point",
      width: "10%",
    },
    {
      key: "id",
      title: <span className="text-table-gray">Действия</span>,
      dataIndex: "actions",
      width: "20%",
    },
  ];

  // RESPOSIVE TABLE HEADERS
  if (window.innerWidth < 768) {
    columns = [
      {
        ...columns[1],
        width: "99%",
      },
      {
        ...columns[4],
        width: "30px",
      },
    ];
  }

  // MODAL WINDOW SUBMIT
  const handleSubmit = () => {
    if (
      newProductData.category_id !== null &&
      newProductData.name !== "" &&
      newProductData.price !== null &&
      newProductData.point !== null &&
      newProductData.image !== "" &&
      newProductData.image !== null &&
      newProductData.brand !== "" &&
      newProductData.keeping !== null || 0 &&
      newProductData.conditions !== ""
    ) {
      if (
        newProductData.description.uz !== "" ||
        newProductData.description.ru !== "" ||
        newProductData.description.qr !== ""
      ) {
        if (modalType === "update") {
          updateProductFunction();
        } else if (modalType === "add") {
          const index = dataSource.findIndex(
            (product) => product.name === newProductData.name
          );
          if (index === -1) {
            addProductFunction(newProductData);
          } else {
            message.error("Товар с таким именем уже существует!");
          }
        }
      } else {
        message.error("Пожалуйста, заполните хотя бы одно описание!");
      }
    } else {
      message.error("Заполните все поля");

    }
  };

  // SELECT CATEGORY
  const selectCategory = (value) => {
    setNewProductData({
      ...newProductData,
      category_id: value,
    });
  };

  // UPLOAD PHOTO TO CLOUDINARY
  const photoUploader = (e) => {
    setPhotoUploaded("loading");

    const formData = new FormData();
    formData.append("file", e);
    formData.append("upload_preset", "vivian");

    axios
      .post(
        "https://api.cloudinary.com/v1_1/http-electro-life-texnopos-site/image/upload",
        formData
      )
      .then((res) => {
        setNewProductData({ ...newProductData, image: res.data.secure_url });
        setPhotoFile(e);
        setPhotoUploaded("ok");
      });
  };

  const rePhotoUploader = (e) => {
    setPhotoUploaded("loading");

    const formData = new FormData();
    formData.append("file", e);
    formData.append("upload_preset", "vivian");

    axios
      .post(
        "https://api.cloudinary.com/v1_1/http-electro-life-texnopos-site/image/upload",
        formData
      )
      .then((res) => {
        setNewProductData({ ...newProductData, image: res.data.secure_url });
        setPhotoFile(e);
        setPhotoUploaded("uploaded");
      });
  };



  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [cropPixels, setCropPixels] = useState({})

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropPixels(croppedAreaPixels)
  }, [])


  const onCrop = async () => {
    getCroppedImg(newProductData?.image, cropPixels)
      .then((result) => {
        rePhotoUploader(result)
      })
  }

  const content = () => {
    return (
      imageCropWindow ? (
        <>
          {
            photoUploaded === "" ? (
              <>
                <Spin spinning={ photoUploaded === "loading" }>
                  <label className="form-label">
                    <span className="form-label__text">Изображение продукта</span>
                    <input
                      required
                      className="form-control"
                      type="file"
                      placeholder="Изображение продукта"
                      value={ photoFile }
                      accept="image/*"
                      onChange={ (e) => {
                        console.info(e.target.files[0])
                        photoUploader(e.target.files[0]);
                      } }
                    />
                  </label>
                </Spin>
              </>
            ) : photoUploaded === "ok" ? (
              <div className={ "w-full h-full bg-dark-txt fixed flex items-center justify-center z-40 top-0 left-0 flex-col" }>

                <div className={ "h-36" }>
                  <Cropper
                    image={ newProductData?.image }
                    crop={ crop }
                    zoom={ zoom }
                    aspect={ 4 / 4 }
                    onCropChange={ setCrop }
                    onCropComplete={ onCropComplete }
                    onZoomChange={ setZoom }

                  />

                </div>
                <div className={ " fixed bottom-4 left-auto right-auto w-full flex justify-center px-2 py-4 bg-background-color bottom-0 flex-col" }>
                  <input
                    value={ zoom }
                    step={ 0.1 }
                    min={ 1 }
                    max={ 10 }
                    type="range"
                    onChange={ (e) => setZoom(e.target.value) }
                    className={ "w-1/2 ml-auto mr-auto" }
                  />
                  <div className={ "flex gap-x-2 ml-auto mr-auto mt-2" }>
                    <button className={ "px-4 py-1 bg-primary-color rounded-lg cursor-pointer text-white " } onClick={ () => {
                      setImageCropWindow(false)
                    } }>Отмена</button>
                    <button className={ "px-4 py-1 bg-primary-color rounded-lg cursor-pointer text-white " } onClick={ onCrop }>Сахранит</button>
                  </div>
                </div>
              </div>
            ) : photoUploaded === "uploaded" ? (
              <>
                <div className="flex px-2 py-2 bg-background-color rounded-md gap-x-2">
                  <span>Фото введено |</span>  <span className="text-primary-color cursor-pointer" onClick={ () => {
                    setNewProductData({ ...newProductData, image: "" });
                    setPhotoFile("");
                    setPhotoUploaded("");
                  } }> Выбрать снова</span>
                </div>
                <br />
                <img src={ newProductData?.image } alt="Product image" />
                <br />
              </>
            ) : null
          }


          <Button text={ "Готово" } onClick={ () => setImageCropWindow(false) } />
        </>
      ) : (
        <div className="relative max-h-96 overflow-auto scroll-hidden">
          <form
            id="form1"
            className="relative"
            onSubmit={ (e) => {
              handleSubmit();
              e.preventDefault();
            } }
          >
            {/* CHOOSE CATEGORY */ }
            <label className="form-label">
              <span className="form-label__text">Выберите категорию <span className="text-red">*</span></span>
              <Select
                placeholder="Выберите категорию"
                width="w-full"
                search={ true }
                status={ !newProductData?.category_id ? "warning" : "" }
                value={ newProductData.category_id }
                onChange={ e => {
                  setNewProductData({ ...newProductData, category_id: e })
                } }
              >
                {
                  categoriesOptions.filter(
                    (category) => category.value !== ""
                  )?.map((item) => (
                    <Select.Option value={ item?.value }>
                      { item?.label }
                    </Select.Option>
                  ))
                }
              </Select>
            </label>

            {/* PRODUCT NAME */ }
            <label className="form-label">
              <span className="form-label__text">Название товара <span className="text-red">*</span></span>
              <input
                required
                className="form-control"
                type="text"
                placeholder="Название категории"
                value={ newProductData.name }
                onChange={ (e) =>
                  setNewProductData({ ...newProductData, name: e.target.value })
                }
              />
            </label>

            {/* PRODUCT PRICE */ }
            <div className="form-label">
              <span className="form-label__text">Цена <span className="text-red">*</span></span>
              <NumberFormat
                required
                className="form-control"
                placeholder="Цена"
                value={ newProductData.price }
                thousandSeparator={ true }
                prefix={ "UZS " }
                onValueChange={ (values) => {
                  const { formattedValue, value } = values;
                  setNewProductData({ ...newProductData, price: value });
                } }
              />
            </div>

            {/* BRAND */ }
            <label className="form-label">
              <span className="form-label__text">Бренд <span className="text-red">*</span></span>
              <input
                required
                className="form-control"
                type="text"
                placeholder="Бренд"
                value={ newProductData.brand }
                onChange={ (e) =>
                  setNewProductData({ ...newProductData, brand: e.target.value })
                }
              />
            </label>

            {/* MODE APP */ }
            <label className="form-label">
              <span className="form-label__text">Приложение режима <span className="text-red">*</span></span>
              <input
                required
                className="form-control"
                type="text"
                placeholder="Приложение режима"
                value={ newProductData.mode_app }
                onChange={ (e) =>
                  setNewProductData({
                    ...newProductData,
                    mode_app: e.target.value,
                  })
                }
              />
            </label>

            {/* POINT */ }
            <label className="form-label">
              <span className="form-label__text">Балл <span className="text-red">*</span></span>
              <input
                className="form-control"
                type="number"
                placeholder="Балл"
                value={ newProductData.point }
                onChange={ (e) =>
                  setNewProductData({ ...newProductData, point: e.target.value })
                }
              />
            </label>

            {/* DESCRIPTION (ru) */ }
            <label className="form-label">
              <span className="form-label__text">Описание (ru)</span>
              <textarea
                className="form-control"
                type="text"
                placeholder="Описание (ru)"
                value={ newProductData.description.ru }
                onChange={ (e) =>
                  setNewProductData((prev) => ({
                    ...newProductData,
                    description: { ...prev.description, ru: e.target.value },
                  }))
                }
              />
            </label>

            {/* DESCRIPTION (uz) */ }
            <label className="form-label">
              <span className="form-label__text">Описание (uz)</span>
              <textarea
                className="form-control"
                type="text"
                placeholder="Описание (uz)"
                value={ newProductData.description.uz }
                onChange={ (e) =>
                  setNewProductData((prev) => ({
                    ...newProductData,
                    description: { ...prev.description, uz: e.target.value },
                  }))
                }
              />
            </label>

            {/* DESCRIPTION (qq) */ }
            <label className="form-label">
              <span className="form-label__text">Описание (qr) </span>
              <textarea
                className="form-control"
                type="text"
                placeholder="Описание (qr)"
                value={ newProductData.description.qr }
                onChange={ (e) =>
                  setNewProductData((prev) => ({
                    ...newProductData,
                    description: { ...prev.description, qr: e.target.value },
                  }))
                }
              />
            </label>

            {/* CONDITIONS */ }
            <label className="form-label">
              <span className="form-label__text">Условия <span className="text-red">*</span></span>
              <textarea
                required
                className="form-control"
                type="text"
                placeholder="Условия"
                value={ newProductData.conditions }
                onChange={ (e) =>
                  setNewProductData({
                    ...newProductData,
                    conditions: e.target.value,
                  })
                }
              />
            </label>

            {/* KEEPING */ }
            <label className="form-label">
              <span className="form-label__text">Хранение (месяц) <span className="text-red">*</span></span>
              <input
                required
                className="form-control"
                type="number"
                placeholder="Хранение"
                value={ newProductData.keeping }
                onChange={ (e) =>
                  setNewProductData({
                    ...newProductData,
                    keeping: e.target.value,
                  })
                }
              />
            </label>

            {/* Изображение продукта */ }
            <label className="form-label">
              <span className="form-label__text">Изображение продукта <span className="text-red">*</span></span>
              <Spin spinning={ photoUploaded === "loading" }>
                {
                  newProductData?.image === "" ? (
                    <input
                      required
                      className="form-control"
                      type="file"
                      placeholder="Изображение продукта"
                      value={ photoFile }
                      accept="image/*"
                      onChange={ (e) => {
                        photoUploader(e.target.files[0]);
                      } }
                    />
                  ) : newProductData?.image !== "" && (
                    <div className="flex px-2 py-2 bg-background-color rounded-md gap-x-2">
                      <span>Фото введено |</span>  <span className="text-primary-color cursor-pointer" onClick={ () => {
                        setNewProductData({ ...newProductData, image: "" });
                        setPhotoFile("");
                        setPhotoUploaded("");
                      } }> Выбрать снова</span>
                    </div>
                  )
                }
              </Spin>
            </label>
            {/* <span
              className={ "w-full px-4 py-2 bg-background-color cursor-pointer block rounded-5" }
              onClick={ () => setImageCropWindow(true) }
            >Добавить изображение</span> */}
            <br />
            <div className="">
              <div className="flex gap-x-2 w-full justify-end">
                <Button
                  type={ "outlined" }
                  onClick={ () => {
                    setModalIsOpen(false);
                  } }
                  text={ "Отмена" }
                />
                <Spin spinning={ uploading }>
                  <Button
                    type="submit"
                    onClick={ () => {
                      // handleSubmit();
                    } }
                    text={ "Cохранить" }
                  />
                </Spin>
              </div>
            </div>
          </form>
        </div>
      )
    );
  };

  // MODAL WINDOW FOOTER
  const footer = () => {
    return (
      null
    );
  };

  // SAVE SORTED PRODUCT
  const saveSortedProduct = () => {
    setUploadSortedProducts(true);
    API.post("/api/products/change/position", sortedProducts)
      .then((res) => {
        setUploadSortedProducts(false);
        setSortedProducts([]);
        message.success("Успешно сохранено!");
        setRefresh(!refresh)
      })
      .catch((res) => {
        message.error("Произошла ошибка! Попробуйте еще раз.");
      });
  };

  const SortableItem = SortableElement((props) => (
    <tr className="items-center" { ...props } />
  ));
  const SortableBody = SortableContainer((props) => (
    <tbody className="w-full " { ...props } />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      let newPosition = [];
      setDataSource(newData);
      newData?.map((item, index) => {
        newPosition?.push({
          product_id: item?.id,
          position: index + 1,
        });
      });
      setSortedProducts(newPosition);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={ onSortEnd }
      { ...props }
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.position === restProps["data-row-key"]
    );
    return <SortableItem index={ index } { ...restProps } />;
  };

  // MAIN RENDER
  return (
    <div className="w-full">
      <Heading title="Все товары" />
      {/* MODAL WINDOW */ }
      { modalIsOpen && (
        <Modal
          visible={ modalIsOpen }
          onCancel={ () => setModalIsOpen(false) }
          title={
            modalType === "update" ? "Редактировать товар" : "Добавить товар"
          }
          footer={ () => footer() }

        >
          { content() }

        </Modal>
      ) }
      {/*  SECTION  */ }
      <div
        ref={ windowPage }
        className={ "px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto" }
        style={ { minHeight: "80vh", maxHeight: "100vh" } }
      >
        {/* SECTION HEADING */ }
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between"
          }
        >
          <div className="flex flex-wrap gap-4">
            <Dropdown
              placeholder="Выберите категорию"
              data={ categoriesOptions }
              selected={ filterByCategory }
              setSelected={ setFilterByCategory }
              width="w-326"
              search={ true }
            />
            <SearchInput
              placeholder={ "Поиск по названию" }
              width={ "w-326 sm:w-full" }
              search={ search }
              onChange={ setSearchStr }
              setSearch={ setSearch }
              debounceTimeOut={ 800 }
              prefix={ <ion-icon name="search-outline"></ion-icon> }
            />
          </div>
          <Button
            text={ "Добавить продукты" }
            startedIcon={ "plus" }
            width={ "w-320 sm:w-full" }
            onClick={ () => {
              setModalIsOpen(true);
              setModalType("add");
            } }
          />
        </div>
        { sortedProducts?.length ? (
          <div className={ "gap-4 fixed flex bottom-12 right-12 z-50 bg-white" }>
            <Button
              text={ "Поддерживать порядок" }
              width={ "w-320 sm:w-full " }
              onClick={ saveSortedProduct }
              loading={ uploadSortedProducts }
            />
            <Button
              text={ "Отмена" }
              onClick={ () => {
                setSortedProducts([]);
                setDataSource(staticProducts);
              } }
            ></Button>
          </div>
        ) : null }
        {/*  SECTION BODY  */ }
        <div className={ "mt-6" }>
          <Skeleton loading={ loading } active>
            <Table
              rowKey={ "position" }
              columns={ columns }
              dataSource={ tableData }
              pagination={ false }
              loading={ loading }
              rowClassName={ (record, index) => {
                return index % 2 === 0
                  ? "bg-gray-200 flex justify-between"
                  : "bg-white";
              } }
              components={ {
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              } }
            />
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default Products;
