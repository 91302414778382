import React, { useState, useEffect } from "react"
import { Line } from '@ant-design/plots';
import Dropdown from "../../UI/Dropdown";
import {SmallHeading} from "../../UI/SmallHeading";
import { dropdownData } from "../../assets/Data/AnyDatas";
import API from "../../assets/api/api";

const Chart = () => {

    const [data, setData] = useState([]);
    const [selected, setSelected] = useState('')
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        asyncFetch();
    }, [selected]);
    const asyncFetch = () => {
        API.get(`/api/statistics/year?day=${selected}`)
            .then((res) => {
                let newArr = []
                res?.data?.payload?.map((item, index) => {
                    newArr.push({
                        Date: item?.date,
                        scales: item?.card + item?.cash
                    })
                })
                setData(newArr)
            })
            .catch((error) => {
                console.log('fetch data failed', error);
            })
            .finally(() => setLoading(false))
    };
    const config = {
        data,
        padding: 'auto',
        xField: 'Date',
        yField: 'scales',
        xAxis: {
            // type: 'timeCat',
            tickCount: 5,
        },
        smooth: true,
    };





    return (
        <div className={'sm:h-96 h-80 relative'}>
                <div className={'flex sm:flex-col mb-4 justify-between items-center'}>
                    <div className={'sm:mb-2 '}>
                    <SmallHeading title={'Рабочий капитал'} />
                    </div>
                    <div className={'flex gap-9'}>
                        <span className={'flex items-center gap-1.5'}>
                            <div className={'w-2 h-2 rounded-full bg-green-clr'}></div>
                            <span className={'text-sm text-dark-txt'}>Доход</span>
                        </span>
                        <span className={'flex items-center gap-1.5'}>
                            <div className={'w-2 h-2 rounded-full bg-yellow-clr'}></div>
                            <span className={'text-sm text-dark-txt'}>Затраты</span>
                        </span>
                        <Dropdown data={dropdownData} selected={selected} setSelected={setSelected} />
                    </div>
                </div>
                <div className={" h-64 pt-2"}>
                    <Line loading={loading} {...config}  />
                </div>
        </div>
    )
}


export default Chart