import React from "react";
import API from "../../assets/api/api";
import { Dropdown, Input, Select, TreeSelect } from "antd";
import InputMask from "react-input-mask";

const { TreeNode } = TreeSelect;

const Content = ({ data, setData, onSubmit, branches }) => {
  // BRANCHES OPTIONS
  const branchesOptions = branches.map((branch) => {
    if (branch.childrens) {
      return {
        value: branch.id,
        label: branch.name,
        childrens: branch.childrens.map((child) => {
          if (child.childrens) {
            return {
              value: child.id,
              label: child.name,
              childrens: child.childrens.map((grandChild) => {
                return {
                  value: grandChild.id,
                  label: grandChild.name,
                };
              }),
            };
          }

          return {
            value: child.id,
            label: child.name,
          };
        }),
      };
    }
    return {
      value: branch.id,
      label: branch.name,
    };
  });

  // MAIN RENDER
  return (
    <div>
      <form
        onSubmit={ (e) => {
          onSubmit();
          e.preventDefault();
        } }
      >
        {/* BRANCHES */ }
        <div className="form-label">
          <span className="form-label__text">Филиал</span>

          <TreeSelect
            value={ data.branch_id }
            defaultValue={ data.branch_id }
            dropdownStyle={ {
              maxHeight: 400,
              overflow: "auto",
            } }
            placeholder="Выберите филиал"
            className="form-control__multiselect"
            onChange={ (e) => {
              setData({ ...data, branch_id: e });
            } }
          >
            { [
              {
                value: "all",
                label: "Выберите филиал",
              },
              ...branchesOptions,
            ].map((branch) => {
              if (branch.childrens) {
                return (
                  <TreeNode
                    value={ branch.value }
                    title={ branch.label }
                    disabled
                    key={ branch.value }
                  >
                    { branch.childrens.map((child) => (
                      <TreeNode
                        value={ child.value }
                        title={ child.label }
                        key={ child.value }
                      />
                    )) }
                  </TreeNode>
                );
              }
              return (
                <TreeNode
                  value={ branch.value }
                  title={ branch.label }
                  key={ branch.value }
                  disabled={ branch.value === "all" }
                />
              );
            }) }
          </TreeSelect>
        </div>

        {/* SUPERVISOR */ }
        <label className="form-label">
          <span className="form-label__text">Имя Менеджер склада</span>
          <input
            required
            className="form-control"
            type="text"
            placeholder="Имя Менеджер склада"
            value={ data.name }
            onChange={ (e) => setData({ ...data, name: e.target.value }) }
          />
        </label>

        {/* PHONE */ }
        <label className="form-label">
          <span className="form-label__text">Телефон</span>
          <InputMask
            mask='99 999 99 99'
            value={ data.phone?.slice(4, data.phone.length) }
            onChange={ e => setData({ ...data, phone: `+998${e.target.value?.split(" ").join("")}` }) }
            maskChar={ null }
          >
            { props => (
              <Input
                { ...props }
                required
                addonBefore='+998'
              />
            ) }
          </InputMask>
        </label>

        {/* PASSWORD */ }
        <label className="form-label">
          <span className="form-label__text">Пароль</span>
          <input
            required
            className="form-control"
            placeholder="Пароль"
            value={ data.password }
            type={ "password" }
            onChange={ (e) => setData({ ...data, password: e.target.value }) }
          />
        </label>
      </form>
    </div>
  );
};

export default Content;
