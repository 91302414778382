const RoleRequirment = ({
	allowedRoles = [],
	notAllowedRoles = [],
	children,
}) => {
	const currentRole = localStorage.getItem('vivian_role')
	if (allowedRoles.length === 0 && notAllowedRoles.length === 0) return children
	if (allowedRoles.includes(currentRole?.toLowerCase())) return children
	return null
}
export default RoleRequirment
