import { combineReducers } from "redux";
import branchesReducer from "../redux/branchesSlice";
import categoriesReducer from "../redux/categoriesSlice";
import clientsReducer from "../redux/clientsSlice";
import loginReducer from "../redux/loginSlice";
import productsReducer from "../redux/productsSlice";

const rootReducer = combineReducers({
  categories: categoriesReducer,
  products: productsReducer,
  login: loginReducer,
  clients: clientsReducer,
  branches: branchesReducer,
});

export default rootReducer;
