import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import API from "../../assets/api/api";
import Heading from "../../UI/Heading";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragDrop from "./DragDrop";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCategories } from "../../redux/categoriesSlice";
import Dropdown from "../../UI/Dropdown";
import {
  clearAllData,
  fetchedProducts,
  setComboPageType,
} from "../../redux/productsSlice";
import Button from "../../UI/Button";
import Modal from "../../UI/Modal";
import { message } from "antd";

const Combo = ({ type }) => {
  const { categories } = useSelector((state) => state.categories);
  const { staticProducts, selectedData, comboPageType, allCombo_id } =
    useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [filterByCategory, setFilterByCategory] = useState("");
  const [totalSum, setTotalSum] = useState(0);
  const [totalBall, setTotalBall] = useState(0);
  const [buttonDis, setButtonDis] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [newData, setNewData] = useState({
    name: "",
    price: null,
    point: null,
  });
  const [uploading, setUploading] = useState(false);
  const [allTotalSum, setAllTotalSum] = useState(0)
  const [allTotalBall, setAllTotalBall] = useState(0)
  const [combos, setCombos] = useState([])

  // FETCHING PRODUCTS
  React.useEffect(() => {
    API.get(`/api/combos`)
      .then((res) => {
        setCombos(res.data.payload.data);
      })
  }, []);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    if (comboPageType === "update") {
      setNewData({
        name: selectedData?.name,
        price: selectedData?.price,
        point: selectedData?.point,
      });
    }
  }, [comboPageType]);

  const categoriesOptions = [
    {
      value: "",
      label: "Все категории",
    },
  ];

  // FETCHING CATEGORIES
  categories.map((category) => {
    categoriesOptions.push({
      value: category.category_id,
      label: category.category_name,
    });
  });

  // FILTER BY CATEGORY
  React.useEffect(() => {
    if (filterByCategory !== "") {
      const filteredProducts = staticProducts.filter(
        (product) => product.category_id === filterByCategory
      );
      dispatch(fetchedProducts(filteredProducts));
    } else {
      dispatch(fetchedProducts(staticProducts));
    }
  }, [filterByCategory]);

  // submit for craete/update combo
  const handleSubmit = () => {
    if (newData.name && newData.point && newData.price) {
      if (comboPageType === "create") {
        if (!combos.find(item => item.name === newData.name)) {
          setUploading(true);
          API.post("/api/combos", { ...newData, items: allCombo_id })
            .then(() => {
              message.success("Комбинация успешно создана!");
              setRefreshData(!refreshData);
              setNewData({
                name: "",
                price: null,
                point: null,
              });
              dispatch(clearAllData());
            })
            .catch((res) => {
              message.error(
                "Произошла ошибка!, или эта комбинация уже существовала."
              );
            })
            .finally(() => {
              setModalIsOpen(false);
              dispatch(setComboPageType("view"));
              setUploading(false);
            });
        } else {
          message.error("Kомбинация уже существовала.")
        }
      }


      if (comboPageType === "update") {
        API.post("/api/combos/add/items", {
          id: selectedData?.id,
          ...newData,
          items: allCombo_id,
        })
          .then((res) => {
            message.success("Операция завершена успешно!");
            setRefreshData(!refreshData);
            dispatch(clearAllData());
          })
          .catch(() => {
            message.error("Произошла ошибка. Пожалуйста, попробуйте еще раз");
          })
          .finally(() => {
            setModalIsOpen(false);
            dispatch(setComboPageType("view"));
            setUploading(false);
          });
      }
    }
  };

  // modal content
  const content = () => {
    return (
      <div className="flex flex-col">
        <div className="px-2 py-4 mb-2 bg-background-color rounded-md shadow flex flex-col">
          <span>Общая сумма: { allTotalSum?.toLocaleString() } <b>UZS</b></span>
          <span>Общая балл: { allTotalBall?.toLocaleString() }</span>
        </div>
        <form>
          {/* Combo name */ }
          <label className="form-label">
            <span className="form-label__text">Имя комбинации</span>
            <input
              required
              className="form-control"
              type="text"
              placeholder="Имя комбинации"
              value={ newData.name }
              onChange={ (e) => setNewData({ ...newData, name: e.target.value }) }
            />
          </label>
          {/* Combo price */ }
          <label className="form-label">
            <span className="form-label__text">Цена комбинации</span>
            <input
              required
              className="form-control"
              type="number"
              placeholder="Цена комбинации"
              value={ newData.price }
              onChange={ (e) => setNewData({ ...newData, price: e.target.value }) }
            />
          </label>
          {/* Combo point */ }
          <label className="form-label">
            <span className="form-label__text">Балл для комбинации</span>
            <input
              required
              className="form-control"
              type="number"
              placeholder="Балл для комбинации"
              value={ newData.point }
              onChange={ (e) => setNewData({ ...newData, point: e.target.value }) }
            />
          </label>
        </form>
      </div>
    );
  };

  // footer modal
  const footer = () => (
    <div>
      <Button
        text={ "Сохранять" }
        onClick={ () => handleSubmit() }
        loading={ uploading }
      />
    </div>
  );

  return (
    <div className="w-full">
      { modalIsOpen ? (
        <Modal
          content={ () => content() }
          footer={ () => footer() }
          onClose={ () => setModalIsOpen(false) }
        />
      ) : null }
      <Heading
        title={
          comboPageType === "update"
            ? "Изменить комбинацию"
            : "Создать комбинацию"
        }
      />
      <div
        className={ "px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto" }
        style={ { minHeight: "80vh", maxHeight: "100vh" } }
      >
        <div className="flex gap-x-4 mb-8 justify-between sm:flex-col sm:gap-y-4">
          <Dropdown
            placeholder="Выберите категорию"
            data={ categoriesOptions }
            selected={ filterByCategory }
            setSelected={ setFilterByCategory }
            width="w-326 sm:w-full"
            search={ true }
          />
          <div className="flex gap-x-4 sm:flex-col">
            {/* <div className="px-4 py-1 rounded bg-background-color sm:w-full">
              <b>Общая сумма: </b>
              { totalSum?.toLocaleString() } <b>UZS</b>
            </div>
            <div className="px-4 py-1 rounded bg-background-color sm:w-full">
              <b>Общий балл: </b>
              { totalBall?.toLocaleString() }
            </div> */}
            <Button
              text={ "Сохранять" }
              disabled={ buttonDis }
              onClick={ () => setModalIsOpen(true) }
              width="sm:w-full"
            />
            <Button
              text={ "Отмена" }
              onClick={ () => dispatch(setComboPageType("view")) }
            />
          </div>
        </div>
        <DndProvider backend={ HTML5Backend }>
          <DragDrop
            setTotalSum={ setTotalSum }
            setTotalBall={ setTotalBall }
            totalBall={ totalBall }
            totalSum={ totalSum }
            setButtonDis={ setButtonDis }
            refreshData={ refreshData }
            setRefreshData={ setRefreshData }
            setAllTotalSum={ setAllTotalSum }
            allTotalSum={ allTotalSum }
            setAllTotalBall={ setAllTotalBall }
            allTotalBall={ allTotalBall }
          />
        </DndProvider>
      </div>
    </div>
  );
};

export default Combo;
