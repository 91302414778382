import Card from "./Card";
import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd/dist/hooks";
import API from "../../assets/api/api";
import { Loading } from "react-loading-ui";
import { fetchCategories } from "../../redux/categoriesSlice";
import {
  addCombo,
  breakComboItems,
  changeBreakLimit,
  clearCombo,
  deleteBreak,
  deleteCombo,
  fetchProducts,
  filterForCombo,
  setWorked,
} from "../../redux/productsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Form, InputNumber, message, Modal, Spin } from "antd";
import Button from "../../UI/Button";

const DragDrop = ({
  setTotalSum,
  setButtonDis,
  refreshData,
  setTotalBall,
  totalBall,
  totalSum,
  allTotalSum,
  setAllTotalSum,
  allTotalBall,
  setAllTotalBall
}) => {
  const [dropProducts, setDropProducts] = React.useState([]);
  const [droppedItems, setDroppedItems] = React.useState([]);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "image",
    drop: (item) => addProductToCombo(item),
  }));
  const {
    products,
    comboPageType,
    selectedData,
    combo_id,
    staticSelectedData,
    allCombo_id,
  } = useSelector((state) => state.products);
  const [dataSource, setDataSource] = useState([]);
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [allDropProducts, setAllDropProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [limit, setLimit] = useState(null)
  const [limitWindowIsOpen, setLimitWindowIsOpen] = useState(false)
  const [changeLimitItemId, setChangeLimitItemId] = useState(null)
  const [changeLimitInputShowing, setChangeLimitInputShowing] = useState(false)

  // REFRESH DATA
  React.useEffect(() => {
    setDropProducts([]);
    dispatch(clearCombo());
  }, []);

  // FETCHING PRODUCTS
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    dispatch(fetchProducts()).then((res) => {
      Loading();
      setDataSource(res.payload.payload);
    });
    dispatch(fetchCategories());
  }, []);

  // get total sum
  React.useEffect(() => {
    setTotalSum(dropProducts[dropProducts?.length - 1]?.price || 0);
    setTotalBall(dropProducts[dropProducts?.length - 1]?.point || 0);

    if (allDropProducts?.length === 0) {
      setButtonDis(true);
    } else {
      setButtonDis(false);
    }
  }, [dropProducts, allDropProducts]);


  // add product to combo
  const addProductToCombo = (item) => {
    dispatch(addCombo(item.id));
    setDropProducts((prev) => [item, ...prev]);
    dispatch(filterForCombo(item));
  };
  // BREAK FOR COMBO ITEMS
  const breakCombo = () => {
    const id = Date.now()
    dispatch(breakComboItems({ limit, id: id }));
    let allDropPrds = allDropProducts;
    allDropPrds.push({ products: dropProducts, limit, id: id });
    setAllDropProducts(allDropPrds);
    setDropProducts([]);
    setLimit(null)
    setLimitWindowIsOpen(false)
  };

  // get break sum and ball
  useEffect(() => {
    const newArr = allDropProducts
    newArr?.map((item) => {
      let allSum = []
      let allBall = []
      item.products.map((product) => {
        allSum.push(Number(product.price))
        allBall.push(Number(product.point))
      })
      item.price = (Math.max(...allSum) * item.limit)
      item.ball = (Math.max(...allBall) * item.limit)
    })
    setAllDropProducts(newArr)
  }, [dropProducts])


  // get all total sum & total ball
  useEffect(() => {
    if (allDropProducts.length !== 0) {
      const allSum = []
      const allBall = []
      allDropProducts.map((item) => {
        allSum.push(item.price)
        allBall.push(item.ball)
      })
      let totalSum = allSum.reduce((prev, next) => prev + next, 0)
      let totalBall = allBall.reduce((prev, next) => prev + next, 0)

      setAllTotalBall(totalBall)
      setAllTotalSum(totalSum)
    }
  }, [allDropProducts, dropProducts])



  // delete product from combo
  const deleteProductFromCombo = (item) => {
    let newArr = dropProducts.filter((x) => x.id !== item.id);
    setDropProducts(newArr);
    dispatch(deleteCombo(item));
    if (comboPageType === "update") {
      const oldIndex = staticSelectedData?.products?.findIndex(
        (x) => x.id === item.id
      );
      if (comboPageType === "update" && oldIndex !== -1) {
        setDeleting(true);
        API.delete(
          `/api/combos/${selectedData?.id}/${staticSelectedData?.products[oldIndex]?.item_id}`
        )
          .then(() => {
            message.success("Операция завершена успешно!");
          })
          .catch(() => { })
          .finally(() => {
            setDeleting(false);
          });
      } else {
      }
    }
  };
  const allSelectedProducts = () => {
    let selectedItems = [];
    selectedData?.items?.map((item) => {
      item?.products?.map((x) => {
        selectedItems.push(x);
      });
    });
    setSelectedProducts(selectedItems);
  };

  useEffect(() => {
    if (comboPageType === "update") {
      allSelectedProducts();
    }
  }, [selectedData, comboPageType]);

  const changeBreakLimitFunc = (id) => {
    dispatch(changeBreakLimit({ id: id, limit }))
    const newArr = allDropProducts
    const index = newArr.findIndex(item => item.id === id)
    newArr[index].limit = limit
    setAllDropProducts(newArr)
    setChangeLimitInputShowing(false)
    setLimit(null)
    setChangeLimitItemId(null)
  }

  // MAIN RETURN
  return (
    <div className="flex items-start gap-x-4  ">
      <Modal
        visible={ limitWindowIsOpen }
        onCancel={ () => setLimitWindowIsOpen(false) }
        title={ "Установить лимит" }
        footer={ () => null }
      >
        <form onSubmit={ e => {
          e.preventDefault()
          breakCombo()
        } } className="flex flex-col gap-y-2">
          <InputNumber
            required
            onChange={ (e) => setLimit(e) }
            value={ limit }
            placeholder="Установить лимит"
            className="w-full"
            style={ { width: "100%" } }
            min={ 1 }
          />
          <Button
            text={ "Сохранять" }
            type="submit"
          />
        </form>
      </Modal>

      <Modal
        visible={ modalIsOpen }
        onCancel={ () => setModalIsOpen(false) }
        title={ "Комбинации" }
        footer={ () => null }
      >
        <div style={ { maxHeight: "400px", overflow: "auto" } }>
          {
            changeLimitInputShowing ? (
              <form
                onSubmit={ e => e.preventDefault() }
                className="flex w-full justify-strach mb-4" style={ { width: '100%' } }>
                <InputNumber
                  style={ { width: "80%" } }
                  required
                  onChange={ (e) => setLimit(e) }
                  value={ limit }
                  min={ 1 }
                />
                <button
                  onClick={ () => changeBreakLimitFunc(changeLimitItemId) }
                  disabled={ !limit || limit === 0 }
                  style={ { width: "20%" } } className="bg-primary-color text-white disabled:opacity-70">Сохранять</button>
              </form>
            ) : null
          }
          {/* RENDER SELECTED PRODUCTS */ }
          { allDropProducts?.length === 0 && <center className="text-gray-txt-color">Товары не найдены</center> }
          { allDropProducts?.map((arr) => (
            <div className={ `
              
              ${changeLimitItemId ? (changeLimitItemId === arr.id ? "border-2 border-primary-color" : "border-2 border-white") : "border-2 border-white"}
              bg-background-color
              px-2 py-2 rounded-md mb-4 shadow-md`}>
              <div className="flex w-full justify-between mb-2">
                <span className="flex gap-x-2 items-center text-gray-txt-color">
                  <span
                    title="Нажмите, чтобы изменить"
                    onClick={ () => {
                      setChangeLimitInputShowing(true)
                      setChangeLimitItemId(arr?.id)
                      setLimit(arr?.limit)
                    } }
                    className="text-md mt-1 cursor-pointer  gap-x-1  flex items-center justify-center rounded-md bg-background-color left-8 text-gray-txt-color">
                    <b>Лимит: </b> <span>{ " " }{ arr?.limit } шт</span>
                  </span>
                  <span className="mt-1">
                    <b>Стоимость: </b> <span>{ " " }{ arr?.price?.toLocaleString() } uzs</span>
                  </span>
                  <span className="mt-1">
                    <b>Балл: </b> <span>{ " " }{ arr?.ball?.toLocaleString() }</span>
                  </span>
                </span>
                <span
                  className="text-md mt-1 cursor-pointer   rounded-full text-white bg-primary-color flex items-center justify-center"
                  style={ {
                    width: "20px",
                    height: "20px",
                    minHeight: "20px",
                    minWidth: "20px",
                  } }

                  onClick={ () => {
                    dispatch(
                      deleteBreak(
                        {
                          newItems: allCombo_id?.filter((x) => {
                            if (x?.product_ids?.findIndex((y) => y === arr?.products[0].id) === -1) {
                              return x;
                            }
                          }),
                          oldItems: allCombo_id?.filter((x) => {
                            if (x?.product_ids?.findIndex((y) => y === arr?.products[0].id) !== -1) {
                              return x;
                            }
                          })
                        }
                      )
                    );
                    setAllDropProducts(
                      allDropProducts?.filter((x) => {
                        let index = x?.products?.findIndex((y) => y.id === arr?.products[0].id);
                        if (index === -1) {
                          return x;
                        }
                      })
                    );
                  } }
                >
                  <ion-icon name="close-outline"></ion-icon>
                </span>

              </div>
              { arr?.products?.map((item) => {
                return (
                  <Spin spinning={ deleting }>
                    <div className="px-2 py-2 rounded-lg shadow-sm flex gap-x-4 items-center cursor-pointer bg-white mb-2 justify-between ">
                      <div className="flex items-center gap-x-4">
                        <img
                          src={
                            item?.image !== null
                              ? item?.image
                              : "https://www.yiwubazaar.com/resources/assets/images/default-product.jpg"
                          }
                          width={ 40 }
                          height={ 40 }
                          className="rounded object-contain"
                          style={ {
                            maxWidth: "50px",
                            maxHeight: "50px",
                          } }
                        />
                        { item?.name }
                      </div>
                      <div className="flex gap-4 items-center">
                      </div>
                    </div>
                  </Spin>
                );
              }) }
            </div>
          )) }
        </div>
      </Modal>
      {/* PRODUCTS */ }
      <div className="w-50% h-50">
        <>
          { products
            ?.filter((x) => {
              let index = selectedProducts.findIndex((y) => y.id === x.id);
              if (index === -1) {
                return x;
              }
            })
            ?.map((item, index) => (
              <Card item={ item } />
            )) }
        </>
      </div>
      {/* PRODUCTS FOR COMBO */ }
      <div
        ref={ drop }
        className="bg-background-color rounded shadow sticky top-0 p-4 w-50%   overflow-auto  right-0 flex flex-col"
        style={ { height: "500px" } }
      >
        <div className="w-full flex justify-between">
          <Button
            onClick={ () => setLimitWindowIsOpen(true) }
            text={ "Сложить в комбинации" }
            disabled={ dropProducts?.length === 0 }
          />
          <Button
            onClick={ () => {
              setModalIsOpen(true);
            } }
            text={ "Покозать все" }
            disabled={ allDropProducts?.length === 0 }
          />
        </div>
        <br />
        { dropProducts.length !== 0 ? (
          dropProducts?.map((item, index) => (
            <>
              <Spin spinning={ deleting }>
                <div className="px-2 py-2 rounded-lg shadow-sm flex gap-x-4 items-center cursor-pointer bg-white mb-2 justify-between ">
                  <div className="flex items-center gap-x-4">
                    <img
                      src={
                        item?.image !== null
                          ? item?.image
                          : "https://www.yiwubazaar.com/resources/assets/images/default-product.jpg"
                      }
                      width={ 40 }
                      height={ 40 }
                      className="rounded object-contain"
                      style={ {
                        maxWidth: "50px",
                        maxHeight: "50px",
                      } }
                    />
                    { item?.name }
                  </div>
                  <div className="flex gap-4 items-center">
                    <span
                      className="text-md mt-1  rounded-full text-white bg-primary-color flex items-center justify-center"
                      style={ {
                        width: "20px",
                        height: "20px",
                        minHeight: "20px",
                        minWidth: "20px",
                      } }
                      onClick={ () => {
                        deleteProductFromCombo(item);
                      } }
                    >
                      <ion-icon name="close-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </Spin>
            </>
          ))
        ) : (
          <div className="flex justify-center w-full flex-col text-center opacity-30">
            <center className="text-6xl mt-5 ">
              <ion-icon name="file-tray-outline"></ion-icon>
            </center>
            Принесите продукты сюда, чтобы создать комбинацию.
          </div>
        ) }
      </div>
    </div>
  );
};

export default DragDrop;
