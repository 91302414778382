import React from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../assets/api/api";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import SearchInput from "../../UI/SearchInput";

import { Loading } from "react-loading-ui";
import {
  Avatar,
  message,
  Popconfirm,
  Skeleton,
  Spin,
  Table,
  TreeSelect,
} from "antd";
import { logout } from "../../redux/loginSlice";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal";
import Dropdown from "../../UI/Dropdown";
import Content from "./Content";
import NumberFormat from "react-number-format";
import { useState } from "react";

const { TreeNode } = TreeSelect;

const SuperAdmin = () => {
  const [search, setSearch] = React.useState("");
  const [searchStr, setSearchStr] = React.useState("");
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState({});
  const [newCategory, setNewCategory] = React.useState({
    branch_id: "all",
    name: "",
    phone: "",
    password: "",
    role: "super-admin",
  });
  const [uploading, setUploading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [sourceData, setSourceData] = React.useState(data);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const dispatch = useDispatch();
  const [branches, setBranches] = React.useState([]);
  const [limit, setLimit] = useState();
  const [employeeLimit, setEmployeeLimit] = useState({});
  const [limiting, setLimiting] = useState(false);
  // BRANCHES OPTIONS
  const branchesOptions = branches.map((branch) => {
    if (branch.childrens) {
      return {
        value: branch.id,
        label: branch.name,
        childrens: branch.childrens.map((child) => {
          if (child.childrens) {
            return {
              value: child.id,
              label: child.name,
              childrens: child.childrens.map((grandChild) => {
                return {
                  value: grandChild.id,
                  label: grandChild.name,
                };
              }),
            };
          }

          return {
            value: child.id,
            label: child.name,
          };
        }),
      };
    }
    return {
      value: branch.id,
      label: branch.name,
    };
  });

  const [selectedBranch, setSelectedBranch] = React.useState(
    localStorage.getItem("myBranch") || ""
  );

  // FETCHING BRANCHES
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await API.get(`/api/branches/${localStorage.getItem("vivian_role") !== "ceo" ? "children" : ''}`);
      if (localStorage.getItem('vivian_role') === "ceo") {
        setBranches(response.data.payload);
      } else {
        setBranches([response.data.payload]);
      }
    };
    fetchData();
  }, []); 
  // SET INPUT VALUE
  React.useEffect(() => {
    // MODALTYPE === 'ADD'
    if (modalType === "add") {
      setNewCategory({
        branch_id: "all",
        name: "",
        phone: "",
        password: "",
        role: "super-admin",
      });
    }

    // MODALTYPE === EDIT
    if (modalType === "edit") {
      setNewCategory({
        name: selectedItem?.name,
        phone: selectedItem?.phone,
        password: selectedItem?.password,
        branch_id: selectedItem.branch_id,
        role: "super-admin",
      });
    }
  }, [modalType, selectedItem]);

  React.useEffect(() => {
    if (modalType === "limit") {
      setLimit(null);
    }
  }, [modalIsOpen, modalType]);

  // MODAL WINDOW CONTENT
  const content = () => {
    if (modalType === "limit") {
      return (
        <div>
          <Spin spinning={limiting}>
            <div className="flex flex-col gap-y-2">
              <div className="px-2 py-1 rounded-md bg-background-color">
                <b>Лимит:</b> {employeeLimit?.limit?.toLocaleString()} UZS
              </div>
              <div className="px-2 py-1 rounded-md bg-background-color mb-4">
                <b>Остаток:</b> {employeeLimit?.remaining?.toLocaleString()} UZS
              </div>
            </div>
          </Spin>
          <label className="form-label">
            <span className="form-label__text">Введите лимит</span>
            <NumberFormat
              placeholder="Введите лимит"
              className="form-control"
              thousandSeparator={true}
              onValueChange={(e) => {
                setLimit(e.floatValue);
              }}
              value={limit}
            />
          </label>
        </div>
      );
    } else {
      return (
        <Content
          data={newCategory}
          setData={setNewCategory}
          onSubmit={handleSubmit}
          branches={branches}
        />
      );
    }
  };

  // SUBMIT
  const handleSubmit = () => {
    if (modalType === "limit") {
       return null
    } else {
      // VALIDATE FORM
      if (
        newCategory.name?.length &&
        newCategory.phone?.length &&
        newCategory.branch_id !== "all"
      ) {
        setUploading(true);
        if (newCategory?.password != "") {
          if (modalType === "add") {
            API.post("/api/employees", newCategory)
              .then((res) => {
                setModalIsOpen(false);
                setRefresh(!refresh);
                setUploading(false);
                message.success("Супер админ добавлен");
                setNewCategory({
                  branch_id: "all",
                  name: "",
                  phone: "",
                  password: "",
                  role: "super-admin",
                });
              })
              .catch((err) => {
                console.log(err);
                setUploading(false);

                if (err?.response?.status === 401) {
                  message.error("Ошибка авторизации");
                  dispatch(logout());
                }

                if (err?.response?.status === 500) {
                  message.error("Ошибка сервера");
                }

                if (err?.response?.status === 422) {
                  message.error("Этот сотрудник ранее существовал");
                }

                if (err?.response?.status === 409) {
                  message.error("Этот сотрудник ранее существовал");
                }
              });
          }
        } else {
          message.error("Заполните все поля");
        }
        // UPDATE EMPLOYEE
        if (modalType === "edit") {
          console.log(newCategory);
          API.patch(`/api/employees/${selectedItem.id}`, newCategory)
            .then((res) => {
              setModalIsOpen(false);
              setRefresh(!refresh);
              setUploading(false);
              setNewCategory({
                branch_id: "all",
                name: "",
                phone: "",
                password: "",
                role: "super-admin",
              });
              setSelectedItem({});
              message.success("Супер админ изменен");
            })
            .catch((err) => {
              console.log(err);
              setUploading(false);
              message.error("Ошибка при изменении сотрудника");
            });
        }
      } else {
        message.error("Заполните все поля");
        setUploading(false);
      }
    }
  };

  // MODAL WINDOW FOOTER
  const footer = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          onClick={() => {
            setModalIsOpen(false);
          }}
          text={"Отмена"}
        />
        <Button
          loading={uploading}
          onClick={() => {
            handleSubmit();
          }}
          text={"Cохранить"}
        />
      </div>
    );
  };

  // FETCHING CATEGORIES
  React.useEffect(() => {
    setLoading(true);
    Loading({ topBar: true, topBarColor: "#377DFF" });
    API.get(`/api/employees?role=super-admin&branch_id=${selectedBranch}`)
      .then((res) => {
        setSourceData(res.data.payload);
        setData(res.data.payload);
        setLoading(false);
        Loading();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Loading();
      });
  }, [refresh, selectedBranch]);

  // DELETE CATEGORY
  const handleDelete = (id) => {
    API.delete(`/api/employees/${id}`)
      .then((res) => {
        message.success("Супер админ успешно удалена");
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 509) {
          message.error("Этот супер админ не может быть удален!");
        }
      });
  };

  // SEARCH
  React.useEffect(() => {
    if (searchStr.length > 0) {
      const newDataSource = data.filter((item) => {
        return item.name.toLowerCase().includes(searchStr.toLowerCase());
      });
      setSourceData(newDataSource);
    } else {
      setSourceData(data);
    }
  }, [searchStr]);

  // FILTER BY BRANCH
  React.useEffect(() => {
    if (selectedBranch !== "all") {
      const newDataSource = data.filter((item) => {
        return item.branch_id === selectedBranch;
      });
      setSourceData(newDataSource);
    } else {
      setSourceData(data);
    }
  }, [selectedBranch]);

  const dataSource = [];

  // TABLE DATA
  sourceData?.forEach((item) => {
    dataSource.push({
      id: item.id,
      name: (
        <>
          <div className="flex gap-x-4 items-center">
            <div>
              <Avatar
                size={"large"}
                style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                {item.image ? (
                  <img src={item.image} alt="avatar" />
                ) : (
                  <span className="uppercase">{item.name[0]}</span>
                )}
              </Avatar>
            </div>

            <div className="flex-1">
              <div className="text-lg font-bold text-gray-txt-color capitalize">
                {item.name}
              </div>
              <div className="text-sm text-gray-txt-color">{item.phone}</div>
            </div>
          </div>
        </>
      ),
      branch: (
        <span className="capitalize text-gray-txt-color">
          {item?.branch.name !== null ? item?.branch.name : "Нет данных"}
        </span>
      ),
      action: (
        <div className="flex gap-x-2"> 
          <Button
            className={"bg-yellow-500 text-white"}
            onClick={() => {
              setModalIsOpen(true);
              setModalType("edit");
              setSelectedItem(item);
            }}
            mode="icon"
            text={<ion-icon name="create-outline"></ion-icon>}
          />
          <Popconfirm
            title="Вы уверены, что хотите удалить?"
            onConfirm={() => {
              handleDelete(item?.id);
            }}
            okText="Да"
            cancelText="Нет"
          >
            <Button
              className={"bg-yellow-500 text-white"}
              mode="icon"
              text={<ion-icon name="trash-outline"></ion-icon>}
            />
          </Popconfirm>
        </div>
      ),
    });
  });

  // TABLE HEADER
  const columns = [
    {
      key: "id",
      title: <b>Имя Супер админa</b>,
      dataIndex: "name",
      width: "80%",
    },
    {
      key: "branch",
      title: <span className="text-table-gray">Филиал</span>,
      dataIndex: "branch",
      width: "20%",
    },
    {
      key: "action",
      title: <span className="text-table-gray">Движение</span>,
      dataIndex: "action",
      width: "30%",
    },
  ];

  // MAIN RENDER
  return (
    <div className="w-full">
      {modalIsOpen && (
        <Modal
          onClose={() => setModalIsOpen(false)}
          title={
            modalType === "add"
              ? "Добавить Супер админ"
              : modalType === "limit"
              ? selectedItem.name
              : "Редактировать Супер админ"
          }
          content={() => content()}
          footer={() => footer()}
        />
      )}
      <Heading title={"Супер админ"} />
      {/*  SECTION  */}
      <div
        className={"px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto"}
        style={{ minHeight: "80vh", maxHeight: "100vh" }}
      >
        {/* SECTION HEADING */}
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between"
          }
        >
          <div className="flex gap-x-4 sm:flex-col sm:w-full sm:gap-y-4">
            {/* FILTER BY BRANCHES */}
            <TreeSelect
              value={selectedBranch}
              defaultValue={selectedBranch}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              placeholder="Все филиал"
              className="form-control__multiselect w-326 sm:w-full"
              onChange={(e) => {
                setSelectedBranch(e);
              }}
            >
              {[...branchesOptions].map((branch) => {
                if (branch.childrens) {
                  return (
                    <TreeNode
                      value={branch.value}
                      title={branch.label}
                      key={branch.value}
                    >
                      {branch.childrens.map((child) => (
                        <TreeNode
                          value={child.value}
                          title={child.label}
                          key={child.value}
                        />
                      ))}
                    </TreeNode>
                  );
                }
                return (
                  <TreeNode
                    value={branch.value}
                    title={branch.label}
                    key={branch.value}
                  />
                );
              })}
            </TreeSelect>

            <SearchInput
              placeholder={"Имя Супер админa"}
              width={"w-326 sm:w-full"}
              search={search}
              setSearch={setSearch}
              debounceTimeOut={800}
              prefix={<ion-icon name="search-outline"></ion-icon>}
              onChange={setSearchStr}
            />
          </div>

          <Button
            text={"Добавить Супер админ"}
            startedIcon={"plus"}
            width={"w-320 sm:w-full"}
            onClick={() => {
              setModalIsOpen(true);
              setModalType("add");
            }}
          />
        </div>
        {/*  SECTION BODY  */}
        <div className={"mt-6 w-full"}>
          <Skeleton loading={loading} active>
            <Table
              rowKey={(record) => record.id}
              dataSource={dataSource}
              columns={columns}
              size="small"
            />
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
