import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const branchesSlice = createSlice({
  name: "branches",
  initialState: {
    branches: [],
    loading: false,
  },
  reducers: {
    fetchingBranches: (state) => {
      state.loading = true;
    },
    fetchedBranches: (state, action) => {
      state.branches = action.payload;
      state.loading = false;
    },
    fetchingErrorBranches: (state, action) => {
      state.loading = false;
    },
    deleteBranch: (state, action) => {
      state.branches = state.branches.filter(
        (category) => category.id !== action.payload
      );
      state.loading = false;
    },
    addBranch: (state, action) => {
      state.branches.unshift(action.payload);
      state.loading = false;
    },
  },
});

export const {
  fetchingBranches,
  fetchedBranches,
  fetchingErrorBranches,
  deleteBranch,
  addBranch,
} = branchesSlice.actions;
const branchesReducer = branchesSlice.reducer;
export default branchesReducer;
