import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API, { baseURL } from "../../assets/api/api";
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  fetchedCategories,
  fetchingCategories,
  updateCategory,
} from "../../redux/categoriesSlice";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import SearchInput from "../../UI/SearchInput";

import { Loading } from "react-loading-ui";
import { message, Pagination, Popconfirm, Skeleton, Spin, Table, TreeSelect, InputNumber, Select, Checkbox } from "antd";
import { logout } from "../../redux/loginSlice";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal";
import { useState } from "react";
import moment from "moment";
import _debounce from "lodash/debounce";
import { downloadXLS } from "./downloadXSLDebt";

const { TreeNode } = TreeSelect;
const Debt = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.categories);
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [branches, setBranches] = React.useState([]);
  const [selectedBranch, setSelectedBranch] = useState(
    localStorage.getItem("myBranch") || ""
  );
  const [debts, setDebts] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemHistory, setSelectedItemHistory] = useState([])
  const [ldng, setLoading] = useState(false)
  const [per_page, setPerPage] = useState(40)
  const [last_page, setLastPage] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  const [assistants, setAssistants] = useState([])
  const [assistant_id, setAssistant_id] = useState('')

  const [search, setSearch] = useState("")
  const [searchStr, setSearchStr] = useState("")

  const [from, setFrom] = useState(moment(new Date().setMonth(new Date().getMonth() - 1)).format("YYYY-MM-DD"))
  const [to, setTo] = useState(moment(Date.now()).format("YYYY-MM-DD"))

  const [allDatas, setAllDatas] = useState({})
  const [merge, setMerge] = useState(false)
  const [downloading, setDownloading] = useState()

  // fetching branches
  useEffect(() => {
    API.get(`/api/branches/${localStorage.getItem("vivian_role") !== "ceo" ? "children" : ''}`).then((res) => {
      if (localStorage.getItem('vivian_role') === "ceo") {
        setBranches(res.data.payload);
      } else {
        setBranches([res.data.payload]);
      }
    });
    API.get(`/api/employees/?role=assistant&branch_id=${selectedBranch}`)
      .then((res) => {
        setAssistants(res.data.payload)
      })
  }, [selectedBranch]);

  useEffect(() => {
    setLoading(true)
    if (modalIsOpen && modalType === "history") {
      API.get(`/api/payment/histories/${selectedCategory?.id}`)
        .then((res) => {
          setSelectedItemHistory(res.data.payload)
        })
        .catch(() => {
          setModalIsOpen(false)
          message.error("Произлошла ошибка!")
        })
        .finally(() => setLoading(false))
    }
  }, [selectedCategory, modalIsOpen, modalType])

  // BRANCHES OPTIONS
  const branchesOptions = branches?.map((branch) => {
    if (branch?.childrens) {
      return {
        value: branch?.id,
        label: branch?.name,
        childrens: branch?.childrens?.map((child) => {
          if (child?.childrens) {
            return {
              value: child?.id,
              label: child?.name,
              childrens: child?.childrens?.map((grandChild) => {
                return {
                  value: grandChild?.id,
                  label: grandChild?.name,
                };
              }),
            };
          }

          return {
            value: child?.id,
            label: child?.name,
          };
        }),
      };
    }
    return {
      value: branch?.id,
      label: branch?.name,
    };
  });

  // FETCHING CATEGORIES
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    API
      .get(
        `${baseURL}/api/orders/debt?branch_id=${selectedBranch}&page=${currentPage}&per_page=${per_page}&assistant_id=${assistant_id}&from=${from}&to=${to}&search=${search}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setDebts(res.data.payload.data.baskets)
        setAllDatas(res.data.payload.data)
        setPerPage(res.data.payload.per_page)
        setLastPage(res.data.payload.last_page)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => Loading())
  }, [selectedBranch, currentPage, per_page, assistant_id, from, to, search]);

  const dataSource = [];

  debts?.forEach((category) => {
    dataSource.push({
      id: category.id,
      index: category?.number_item,
      name: (
        <div className="flex items-start flex-col">
          <span className="font-medium">{ category?.user?.name }</span>
          <span>{ category?.branch?.name }</span>
          <span>{ category?.user?.phone?.toLocaleString() }</span>
        </div>
      ),
      supervisor: category?.supervisor?.name,
      assistant: category?.assistant?.name,
      money: (
        <div className="flex flex-col">
          <span>
            Общий: <b>{ category?.total_sum?.toLocaleString() }</b> UZS
          </span>
          <span>
            Наличные: <b>{ category?.cash?.toLocaleString() }</b> UZS
          </span>
          <span>
            Карта: <b>{ category?.card?.toLocaleString() }</b> UZS
          </span>
        </div>
      ),
      debt: (
        <div className="flex flex-col">
          <span>
            Общий: <b>{ category?.debt?.debt?.toLocaleString() }</b> UZS
          </span>
          <span>
            Наличные: <b>{ category?.debt?.paid_debt_cash?.toLocaleString() }</b>{ " " }
            UZS
          </span>
          <span>
            Карта: <b>{ category?.debt?.paid_debt_card?.toLocaleString() }</b> UZS
          </span>
          <span>
            Оплаченный: <b>{ category?.debt?.paid_debt?.toLocaleString() }</b> UZS
          </span>
          <span>
            Остаток: <b>{ (category?.debt?.debt - category?.debt?.paid_debt)?.toLocaleString() }</b> UZS
          </span>
        </div>
      ),
      actions: (
        <div className="flex gap-x-2">
          <Button
            text={ <ion-icon name="list-outline"></ion-icon> }
            mode={ "icon" }
            onClick={ () => {
              setModalType("list")
              setSelectedItem(category)
              setModalIsOpen(true)
            } }
          />
          <Button
            text={ <ion-icon name="cash-outline"></ion-icon> }
            mode={ "icon" }
            onClick={ () => {
              setSelectedCategory(category)
              setSelectedItem(category)
              setModalType("history")
              setModalIsOpen(true)
            } }
          />
        </div>
      ),
    });
  });


  const columns = [
    {
      key: "index",
      title: <b>№</b>,
      dataIndex: "index",
    },
    {
      key: "id",
      title: <b>Клиент</b>,
      dataIndex: "name",
    },
    {
      key: "actions",
      title: <span className="text-table-gray">Супервайзер</span>,
      dataIndex: "supervisor",
    },
    {
      key: "assistant",
      title: <span className="text-table-gray">Ассистент</span>,
      dataIndex: "assistant",
    },
    {
      key: "money",
      title: <span className="text-table-gray">Сумма</span>,
      dataIndex: "money",
    },
    {
      key: "debt",
      title: <span className="text-table-gray">Долг</span>,
      dataIndex: "debt",
    },
    {
      key: "actions",
      title: <span className="text-table-gray">Товары</span>,
      dataIndex: "actions",
    },
  ];

  const content = () => {
    if (modalType !== "history") {
      if (modalType === "info") {
        return (
          <div className="flex w-full gap-x-4 ">
            <div className="flex flex-col">
              <span className="px-4 py-2 rounded-md shadow-md bg-background-color ">Общая задолженность</span>
              <span className="px-4 py-2 font-medium">{ (allDatas?.debt?.debt | 0)?.toLocaleString() } UZS</span>
            </div>
            <div className="flex flex-col">
              <span className="px-4 py-2 rounded-md shadow-md bg-background-color ">Из них оплачено</span>
              <span className="px-4 py-2 font-medium">{ (allDatas?.debt?.paid_debt | 0)?.toLocaleString() } UZS</span>
            </div>
            <div className="flex flex-col">
              <span className="px-4 py-2 rounded-md shadow-md bg-background-color ">Оплачено наличным</span>
              <span className="px-4 py-2 font-medium">{ (allDatas?.debt?.paid_debt_cash | 0)?.toLocaleString() } UZS</span>
            </div>
            <div className="flex flex-col">
              <span className="px-4 py-2 rounded-md shadow-md bg-background-color ">Оплачено карта</span>
              <span className="px-4 py-2 font-medium">{ (allDatas?.debt?.paid_debt_card | 0)?.toLocaleString() } UZS</span>
            </div>
            <div className="flex flex-col">
              <span className="px-4 py-2 rounded-md shadow-md bg-background-color ">Из них не оплачено</span>
              <span className="px-4 py-2 font-medium">{ ((
                allDatas?.debt?.debt - allDatas?.debt?.paid_debt
              ) | 0)?.toLocaleString() } UZS</span>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <table className="w-full">
              <tr>
                <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Название товара</b></td>
                <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Количество</b></td>
                <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Балл</b></td>
                <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Сумма</b></td>
              </tr>
              { selectedItem?.orders?.map((item, index) => (
                <tr>
                  <td className="px-2 py-2 bg-background-color border-b border-table-border">
                    <span className="text-xs ">{ item?.product_name }</span>
                  </td>
                  <td className=" text-xs   px-2 py-2 bg-background-color border-b border-table-border">
                    { item?.count } <span>шт</span>
                  </td>
                  <td className=" text-xs   px-2 py-2 bg-background-color border-b border-table-border">
                    { item?.point } <span>балл</span>
                  </td>
                  <td className=" text-xs   px-2 py-2 bg-background-color border-b border-table-border">
                    { item?.price?.toLocaleString() } <span>UZS</span>
                  </td>
                </tr>
              )) }
            </table>
          </div>
        );
      }
    } else {
      return (
        <Spin spinning={ ldng }>

          <div>
            {
              selectedItemHistory?.length ? (

                <table className="w-full">
                  <tr>
                    <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Получатель</b></td>
                    <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Наличные</b></td>
                    <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Пластик</b></td>
                    <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Ваучер</b></td>
                    <td className="px-2 py-2 bg-background-color border-b border-table-border"><b>Время</b></td>
                  </tr>
                  { selectedItemHistory?.map((item, index) => (
                    <tr>
                      <td className="px-2 py-1  border-b border-table-border">
                        <span className="text-xs flex flex-col">
                          <b> { item?.employee?.name }</b>
                          <span className="text-gray-txt-color">{ item?.employee?.role === "supervisor"
                            ? "Доставщик"
                            : item?.employee?.role === "assistant"
                              ? "Ассистент"
                              : item?.employee?.role }</span>
                        </span>
                      </td>
                      <td className=" text-xs   px-2 py-1 border-b border-table-border">
                        { item?.cash?.toLocaleString() } <span> UZS</span>
                      </td>
                      <td className=" text-xs   px-2 py-1  border-b border-table-border">
                        { item?.card?.toLocaleString() } <span> UZS</span>
                      </td>

                      <td className=" text-xs   px-2 py-1 border-b border-table-border">
                        { item?.voucher } <span>балл</span>
                      </td>
                      <td className=" text-xs   px-2 py-1 border-b border-table-border">
                        { moment(item?.created_at).format("DD/MM/YYYY HH:MM:SS") }
                      </td>
                    </tr>
                  )) }
                </table>
              ) : (
                <center>
                  <h4 className="text-gray-txt-color">История не найдены</h4>
                </center>
              )
            }
          </div>
        </Spin>
      );
    }
  };

  const deboundeFnc = useCallback(
    _debounce((value) => {
      setPerPage(value);
    }, 500),
    []
  );

  const onChangeFunc = (e) => {
    deboundeFnc(e);
  };

  return (
    <div className="w-full">
      <Heading title={ "Долги" } />
      { modalIsOpen ? (
        <Modal
          size="large"
          title={ selectedItem?.user?.name }
          content={ content }
          footer={ false }
          onClose={ () => setModalIsOpen(false) }
        />
      ) : null }
      {/*  SECTION  */ }
      <div
        className={ "px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto" }
        style={ { minHeight: "80vh", maxHeight: "100vh" } }
      >
        {/* SECTION HEADING */ }
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full"
          }
        >
          <TreeSelect
            value={ selectedBranch }
            defaultValue={ selectedBranch }
            dropdownStyle={ {
              maxHeight: 400,
              overflow: "auto",
            } }
            placeholder="Выберите Область"
            className="form-control__multiselect w-326 sm:w-full"
            onChange={ (e) => {
              setSelectedBranch(e);
              setAssistant_id("")
            } }
          >
            { [
              {
                value: "all",
                label: "Выберите Область",
              },
              ...branchesOptions,
            ]?.map((branch) => {
              if (branch?.childrens) {
                return (
                  <TreeNode
                    value={ branch.value }
                    title={ branch.label }
                    key={ branch.value }
                  >
                    { branch.childrens.map((child) => (
                      <TreeNode
                        value={ child.value }
                        title={ child.label }
                        key={ child.value }
                      />
                    )) }
                  </TreeNode>
                );
              }
              return (
                <TreeNode
                  value={ branch.value }
                  title={ branch.label }
                  key={ branch.value }
                  disabled={ branch.value === "all" }
                />
              );
            }) }
          </TreeSelect>

          <Select
            className="form-control__multiselect w-326 sm:w-full"
            onChange={ e => {
              setAssistant_id(e)
            } }
            value={ assistant_id }
          >
            <Select.Option value="">
              Все ассистенты
            </Select.Option>
            {
              assistants?.map((item) => (
                <>
                  <Select.Option value={ item?.id }>
                    { item?.name }
                  </Select.Option>
                </>
              ))
            }
          </Select>


          <input
            type={ "date" }
            className="bg-background-color rounded-md px-2 py-1 sm:w-full"
            onChange={ e => setFrom(e.target.value) }
            value={ from }
          />
          <input
            type={ "date" }
            className="bg-background-color rounded-md px-2 py-1 sm:w-full"
            onChange={ e => setTo(e.target.value) }
            value={ to }
          />


          <SearchInput
            placeholder={ "Введите имя клиента..." }
            search={ searchStr }
            setSearch={ setSearchStr }
            onChange={ e => {
              setSearch(e)
            } }
          />

          <label className="p-2 rounded-5 bg-background-color">
            <Checkbox
              onChange={ (e) =>
                setMerge(e.target.checked)
              }
              checked={ merge }
            >
              Объедините одинаковые корзины.
            </Checkbox>
          </label>

          <Button
            onClick={ e => {
              setModalType("info")
              setModalIsOpen(true)
            } }
            text={ "Общая информация" }></Button>

          <Button
            loading={ downloading }
            onClick={ e => {
              downloadXLS({
                branch_id: selectedBranch,
                assistant_id: assistant_id,
                merge: merge,
                setDownloading: setDownloading,
                from: from,
                to: to
              })
            } }
            text={ "Экспорт в Excel" }></Button>
        </div>

        {/*  SECTION BODY  */ }
        <div
          className={ "mt-6 w-full" }
        >
          <Skeleton loading={ loading } active>
            <br />
            <center>
              { last_page !== 1 ? (
                <div className="flex gap-x-4 justify-center">
                  <Pagination
                    pageSize={ per_page ? per_page : 40 }
                    total={ last_page * per_page }
                    current={ currentPage }
                    onChange={ (page, x) => {
                      setCurrentPage(page)
                      setPerPage(x)
                    } }
                  />
                  <InputNumber
                    onChange={ e => onChangeFunc(e) }
                    value={ per_page }
                  />
                </div>
              ) : null }
            </center>
            <br /><br />
            <Table
              rowKey={ (record) => record.id }
              dataSource={ dataSource }
              columns={ columns }
              pagination={ false }
              size="small"
            />
            <center>
              { last_page !== 1 ? (
                <div className="flex gap-x-4 justify-center">
                  <Pagination
                    pageSize={ per_page ? per_page : 40 }
                    total={ last_page * per_page }
                    current={ currentPage }
                    onChange={ (page, x) => {
                      setCurrentPage(page)
                      setPerPage(x)
                    } }
                  />
                  <InputNumber
                    onChange={ e => onChangeFunc(e) }
                    value={ per_page }
                  />
                </div>
              ) : null }
            </center>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default Debt;
