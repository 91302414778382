import React from 'react'
import Dashboard from './components/Dashboard/Dashboard'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useNavigate,
} from 'react-router-dom'
import Login from './pages/Login/Login'

import Home from './pages/Home/Home'
import TodaysOrders from './pages/TodaysOrders/TodaysOrders'
import Categories from './pages/Categories/Categories'
import Products from './pages/Products/Products'
import { useDispatch, useSelector } from 'react-redux'
import API from './assets/api/api'
import { logout } from './redux/loginSlice'
import Clients from './pages/Clients/Clients'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Supervisors from './pages/Supervisors/Supervisors'
import Assistants from './pages/Assistants/Assistants'
import Branches from './pages/Branches/Branches'
import Warehouse from './pages/Warehouse/Warehouse'
import WarehouseHistory from './pages/WarehouseHistory/WarehouseHistory'
import OrderHistory from './pages/OrderHistory/OrderHistory'
import Statistics from './pages/Statistics/Statistics'
import WarehouseManager from './pages/WarehouseManager/WarehouseManager'
import SuperWarehouseManager from './pages/SuperWarehouseManager/SuperWarehouseManager'
import Debt from './pages/Debt/Debt'
import Combo from './pages/Combo/Combo'
import Combinations from './pages/Combo/Combinations'
import Gift from './pages/Gift/Gift'
import Coupons from './pages/Coupon/Coupon'
import GiftRequest from './pages/GiftRequest/GiftReq'
import RouteRequirement from './RoutingRequirements/RouteRequirement'
import SuperAdmin from './pages/Super-admin/Super-admin'
import Admin from './pages/Admin/Admin'
import CouponsHistory from './pages/Coupon/CouponHistory'
import CustomPrices from './pages/CustomPrices/ShowCustomPrices/CustomPrices'
import CreateCustomPrices from './pages/CustomPrices/CreateCustomPrices/CreateCustomPrices'
import CustomPricesDetails from './pages/CustomPrices/CustomPriceDetails/CustomPriceDetails'
import Profile from "./pages/Settings/Profile/Profile";

function App() {
	const dispatch = useDispatch()
	const { isLoggedIn, userData } = useSelector(state => state.login)
	const navigate = useNavigate()
	const token = localStorage.getItem('token')

	// validate token
	React.useEffect(() => {
		API.get('/api/categories')
			.then(res => {
				if (res.data.message === 'Unauthenticated') {
					dispatch(logout())
					navigate('/login')
				}
			})
			.catch(err => {
				if (err.response.status === 401 || err.response.status === 403) {
					navigate('/login', { replace: true })
					dispatch(logout())
				}
			})
	}, [])

	//  validate of user is logged in
	React.useEffect(() => {
		if (!token) {
			navigate('/login', { replace: true })
			dispatch(logout())
		}
	}, [token])

	return (
		<div className='App'>
			<Routes>
				<Route path='/login' element={<Login />} />
				<Route path='/' element={<Dashboard />}>
					<Route path={''} element={<Home />} />
					<Route path='custom-prices' element={<CustomPrices />} />
					<Route path='custom-prices/create' element={<CreateCustomPrices />} />
					<Route
						path='custom-prices/details/:id'
						element={<CustomPricesDetails />}
					/>
					{/* CEO */}
					<Route element={<RouteRequirement allowedRoles={['ceo']} />}>
						<Route path={'today__orders'} element={<TodaysOrders />} />
						<Route path={'categories'} element={<Categories />} />
						<Route path={'products'} element={<Products />} />
						<Route path='combo' element={<Combinations />} />
						<Route path='filials' element={<Branches />} />
						<Route path='gift' element={<Gift />} />
						<Route path='warehouse-manager' element={<WarehouseManager />} />
						<Route
							path='super-warehouse-manager'
							element={<SuperWarehouseManager />}
						/>
						<Route path='giftReq' element={<GiftRequest />} />
						<Route path={'assistants'} element={<Assistants />} />
						<Route path={'super-admins'} element={<SuperAdmin />} />
						<Route path={'admins'} element={<Admin />} />
						<Route path={'supervisors'} element={<Supervisors />} />
						<Route path='debt' element={<Debt />} />
						<Route path={'clients'} element={<Clients />} />
						<Route path='coupons' element={<Coupons />} />
						<Route path='warehouse' element={<Warehouse />} />
						<Route path='warehouse/history' element={<WarehouseHistory />} />
						<Route path='/orders__history' element={<OrderHistory />} />
						<Route path='statistics' element={<Statistics />} />
						<Route path="/profile" element={<Profile />} />
					</Route>

					<Route element={<RouteRequirement allowedRoles={['super-admin']} />}>
						<Route path={'assistants'} element={<Assistants />} />
						<Route path='gift' element={<Gift />} />
						<Route path='giftReq' element={<GiftRequest />} />
						<Route path={'supervisors'} element={<Supervisors />} />
						<Route path='filials' element={<Branches />} />
						<Route path='combo' element={<Combinations />} />
					</Route>

					{/* admin, super-admin */}
					<Route
						element={
							<RouteRequirement allowedRoles={['admin', 'super-admin']} />
						}
					>
						<Route path={'clients'} element={<Clients />} />
						<Route path='statistics' element={<Statistics />} />
						<Route path='/orders__history' element={<OrderHistory />} />
						<Route path='debt' element={<Debt />} />
						<Route path='warehouse' element={<Warehouse />} />
						<Route path='warehouse/history' element={<WarehouseHistory />} />
					</Route>

					{/* ceo, admin, super-admin */}
					<Route
						element={
							<RouteRequirement
								allowedRoles={['ceo', 'admin', 'super-admin']}
							/>
						}
					>
						<Route path='coupons' element={<Coupons />} />
						<Route path='coupons/actions' element={<CouponsHistory />} />
					</Route>
				</Route>
				<Route path='*' element={<PageNotFound />} />
			</Routes>
		</div>
	)
}

export default App
