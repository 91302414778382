import API from "../../assets/api/api";

export const downloadXLS = ({ branch_id, assistant_id, searchStr, status_id, point_type, min_point, max_point, setDownloading }) => {
  setDownloading(true)
  API.get(
    `/api/users/export?assistant_id=${assistant_id}&search=${searchStr}&color=${status_id}&point_type=${point_type}&min_point=${min_point}&max_point=${max_point}`, {
    headers: {
      "Content-Type": "blob"
    },
    responseType: "arraybuffer"
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute(
        'download',
        `${Date.now()}.xlsx`,
      );

      document.body.appendChild(link);
      link.click();

      link.remove();
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => setDownloading(false))
}