import React from "react";
import Button from "./Button";

const Modal = ({ title, content, footer, onClose, size = "small", fullScreen, className }) => {
  return (
    //   MODAL WINDOW
    <div className="modal-overlay">
      {/* MODAL */ }
      <div
        className={ `modal ${fullScreen ? "fullScreen" : size === "large" ? "large" : "small"} ${className}` }
        width={ size === "small" ? "450" : "750" }
      >
        {/* MODAL HEADING */ }
        <h2 className="modal__heading font-semibold text-gray-txt-color">
          { title }
        </h2>

        {/* CLOSE BTN */ }
        <div className="absolute top-4 right-4 close-btn">
          <ion-icon name="close-outline" onClick={ () => onClose() }></ion-icon>
        </div>

        {/* MODAL CONTENT */ }
        <div className="modal__content">{ content() }</div>

        {/* MODAL FOOTER */ }
        <div className="modal__footer">
          { footer !== false ? footer() : null }
        </div>
      </div>
    </div>
  );
};

export default Modal;
