import React from 'react'

const DropdownItem = ({ onClick = () => { }, icon, text }) => {
  return (
    <div
      onClick={ () => onClick() }
      className="text-gray-txt-color font-semibold flex items-center bg-white cursor-pointer rounded-md gap-x-2 p-2 px-3 hover:bg-light-gray">
      { icon() }
      <span>{ text }</span>
    </div>
  )
}

export default DropdownItem