import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../assets/api/api";
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  fetchedCategories,
  fetchingCategories,
  updateCategory,
} from "../../redux/categoriesSlice";
import Button from "../../UI/Button";
import Heading from "../../UI/Heading";
import SearchInput from "../../UI/SearchInput";

import { Loading } from "react-loading-ui";
import { InputNumber, message, Pagination, Popconfirm, Select, Skeleton, Table } from "antd";
import { logout } from "../../redux/loginSlice";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal";
import { useState } from "react";

import _debounce from "lodash/debounce";
import moment from "moment";
const CouponsHistory = () => {
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [categoriesData, setCategoriesData] = React.useState(categories);
  const [per_page, setPerPage] = useState(0);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [from, setFrom] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("YYYY-MM-DD"))
  const [to, setTo] = useState(moment(new Date()).format("YYYY-MM-DD"))



  // FETCHING CATEGORIES
  React.useEffect(() => {
    Loading({ topBar: true, topBarColor: "#377DFF" });
    setLoading(true);
    API.get(`/api/coupons/artificial-actions?from=${from}&to=${to}`)
      .then((res) => {
        setCategoriesData(res.data.payload.data);
        setCategories(res.data.payload.data);
        setPerPage(res.data.payload.per_page);
        setLastPage(res.data.payload.last_page);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(logout());
          navigate("/login", { replace: true });
        }
      })
      .finally(() => {
        Loading();
        setLoading(false);
      });
  }, [from, to]);



  React.useEffect(() => {
    setCategoriesData(categories);
  }, [categories]);


  const dataSource = [];

  categoriesData?.forEach((category) => {
    dataSource.push({
      id: category.id,
      coupon: category?.coupon,
      name: (
        <div className="flex flex-col text-gray-txt-color">
          <p className="font-medium -mb-1">{ category?.user?.name }</p>
          <span>{ category?.user?.phone?.toLocaleString() }</span>
        </div>
      ),
      employee: (
        <div className="flex flex-col text-gray-txt-color">
          <p className="font-medium -mb-1">{ category?.employee?.name }</p>
          <span>{ category?.employee?.phone?.toLocaleString() }</span>
        </div>
      ),
      date: category?.created_at,
      type: category?.type === "added" ? (
        <span className="font-semibold text-green-clr">Добавлен</span>
      ) : (
        <span className="font-semibold text-red-clr">Удален</span>
      ),
    });
  });

  const columns = [
    {
      key: "id",
      title: <b>Купон</b>,
      dataIndex: "coupon",
      width: "20%",
    },
    {
      key: "employee",
      title: <b>Клиент</b>,
      dataIndex: "name",
      width: "20%",
    },
    {
      key: "name",
      title: <span className="text-table-gray">Продавец</span>,
      dataIndex: "employee",
      width: "20%",
    },
    {
      key: "date",
      title: <span className="text-table-gray">Дата и время</span>,
      dataIndex: "date",
      width: "20%",
    },
    {
      key: "date",
      title: <span className="text-table-gray">Тип действия</span>,
      dataIndex: "type",
      width: "20%",
    },
  ];

  const deboundeFnc = useCallback(
    _debounce((value) => {
      setPerPage(value);
    }, 500),
    []
  );

  const onChangeFunc = (e) => {
    deboundeFnc(e);
  };



  return (
    <div className="w-full">
      <Heading title={ "Действия с купонами" } />
      {/*  SECTION  */ }
      <div
        className={ "px-4 py-9 bg-white rounded-10 mt-6 overflow-y-auto" }
        style={ { minHeight: "80vh", maxHeight: "100vh" } }
      >
        {/* SECTION HEADING */ }
        <div
          className={
            "gap-4 inline-flex items-center flex-wrap mb-8 w-full justify-between"
          }
        >
          <div className="flex sm:flex-col gap-2">
            <input value={ from } onChange={ e => setFrom(e.target.value) } type="date" className="px-4 py-2 sm:w-full bg-background-color rounded-md" />
            <input value={ to } onChange={ e => setTo(e.target.value) } type="date" className="px-4 py-2 sm:w-full bg-background-color rounded-md" />
          </div>
        </div>
        {/*  SECTION BODY  */ }
        <div className={ "mt-6 w-full" }>
          <Skeleton loading={ loading } active>
            <center>
              { last_page !== 1 ? (
                <div className="flex gap-x-4 justify-center">
                  <Pagination
                    pageSize={ per_page ? per_page : 40 }
                    total={ last_page * per_page }
                    current={ current_page }
                    onChange={ (page, x) => {
                      setCurrentPage(page)
                      setPerPage(x)
                    } }
                  />
                  <InputNumber
                    onChange={ e => onChangeFunc(e) }
                    value={ per_page }
                  />
                </div>
              ) : null }
            </center>
            <br /><br />
            <Table
              rowKey={ (record) => record.id }
              dataSource={ dataSource }
              columns={ columns }
              pagination={ false }
            />
            <br />
            <br />
            <center>
              { last_page !== 1 ? (
                <div className="flex gap-x-4 justify-center">
                  <Pagination
                    pageSize={ per_page ? per_page : 40 }
                    total={ last_page * per_page }
                    current={ current_page }
                    onChange={ (page, x) => {
                      setCurrentPage(page)
                      setPerPage(x)
                    } }
                  />
                  <InputNumber
                    onChange={ e => onChangeFunc(e) }
                    value={ per_page }
                  />
                </div>
              ) : null }
            </center>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default CouponsHistory;
